import React, { useEffect, useState } from "react";
import Select from "react-select";

const CustomSelect = ({
  options,
  name,
  initValue,
  label,
  placeholder,
  disabled,
  onChange,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    onChange(name, option);
  };

  useEffect(() => {
    if (initValue && initValue.value) {
      setSelectedOption(initValue);
    }
  }, [initValue]);

  return (
    <React.Fragment>
      <style>{`
        .custom-select-wrapper {
          width: 100%;
        }
        .custom-select-label {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          margin-bottom: 5px;
          margin-top: 10px;
          display: block;
        }
        `}</style>
      <div className="custom-select-wrapper">
        <label className="custom-select-label">{label}</label>
        <Select
          options={options}
          value={selectedOption}
          isDisabled={disabled}
          placeholder={placeholder}
          onChange={handleSelectChange}
        />
      </div>
    </React.Fragment>
  );
};

export default CustomSelect;
