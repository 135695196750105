import { useState, useCallback } from "react";
import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";

export const useBrowseFile = () => {
  const [isFileDownloading, setIsFileDownloading] = useState(false);

  const handleBrowseFile = useCallback(async (id, fileType) => {
    setIsFileDownloading(true);
    try {
      const { data } = await axios.get(
        `${config.archeoBaseUrl}/api/private/v3/file/download/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
          responseType: "arraybuffer",
        }
      );
      let mimeType;
      if (fileType === "pdf") {
        mimeType = "application/pdf";
      } else if (fileType === "jpg") {
        mimeType = "image/jpeg";
      } else if (fileType === "png") {
        mimeType = "image/png";
      } else {
        throw new Error("Unsupported file type");
      }
      const blob = new Blob([data], { type: mimeType });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      // link.download = `download.${fileType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    } catch (error) {
      console.error("Error fetching file.", error);
      toast.error("Error fetching file.");
    } finally {
      setIsFileDownloading(false);
    }
  }, []);

  return { isFileDownloading, handleBrowseFile };
};
