export const movimentazioneReferenti = [
  {
    label: "Destination contact",
    value: "destinationContact",
  },
  {
    label: "Contact number",
    value: "contractNumber",
  },
];
