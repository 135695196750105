import React, { useEffect, useMemo, useState, useCallback } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Header.scss";
import ProfileCard from "./ProfileCard/ProfileCard";
import { ManualIcon, EmailIcons, PhoneIcons } from "../../assets/icons";
import { getMe } from "../../api";
import { useAppContext } from "../Context/AppContext";
import logoFull from "../../assets/images/ArcheoSibari.png";
import logoMobile from "../../assets/images/ArcheoSibariMobile.png";

function Header() {
  const { setUserProfile, userProfile } = useAppContext();
  const [linkClicked, setLinkClicked] = useState(false);
  const currentURL = useLocation();
  const navigate = useNavigate();

  const unProtectedRoutes = useMemo(
    () => ({
      "/signin": 1,
      "/": 1,
      "/resetpassword": 1,
    }),
    []
  );

  const fetchUserProfile = useCallback(
    async (data) => {
      try {
        const user = await getMe();
        setUserProfile(user);
      } catch (error) {
        if (unProtectedRoutes[currentURL.pathname]) {
          // do nothing
        } else {
          navigate("/signin");
        }
      }
    },
    [currentURL, navigate, setUserProfile, unProtectedRoutes]
  );

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  function handleNavLinkClick(e) {
    const navOptionName = e.target.name;
    localStorage.removeItem("cbcValue");
    localStorage.removeItem("AREA_SCAVO");
    setLinkClicked(navOptionName);
  }

  return (
    <header className="header">
      {unProtectedRoutes[currentURL.pathname] ? (
        <>
          <div className="logo-container">
            <img
              className="mobile-view-logo"
              src={logoMobile}
              alt="Logo Archeo"
            />
            <img
              className="desktop-view-logo"
              src={logoFull}
              alt="Logo Archeo"
            />

            {/*
            <LogoIcon className="desktop-view-logo" />
            <LogoIcon className="mobile-view-logo" mobileView={true} />
            <div className="logo-text">
              <h1 className="logo-title">SISTEMA INFORMATIVO SIBARI</h1>
              <h2 className="logo-subtitle">
                ARCHEO: Gestione Dati Archeologici
              </h2>
            </div>
            */}
          </div>
          <div className="contact-info">
            <div className="email-container">
              <EmailIcons />
              <span className="email">archeo@parcosibari.it</span>
            </div>
            <div className="phone-container">
              <PhoneIcon />
              <span className="phone">+39 0981* ****8</span>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="logo-container">
            <Link to="/home" name="homepage">
              <img
                className="mobile-view-logo"
                src={logoMobile}
                alt="Logo Archeo"
              />
              <img
                className="desktop-view-logo"
                src={logoFull}
                alt="Logo Archeo"
              />
            </Link>

            {/*}
            <LogoIcon className="desktop-view-logo" />
            <div className="logo-text">
              <h1 className="logo-title">SISTEMA INFORMATIVO SIBARI</h1>
              <h2 className="logo-subtitle">
                ARCHEO: Gestione Dati Archeologici
              </h2>
            </div>
            */}
          </div>
          <nav className="navigation">
            {/*<LogoIcon className="mobile-view-logo" mobileView /> */}
            <Link
              onClick={handleNavLinkClick}
              name="homepage"
              to="/Mapview"
              className={`nav-link ${
                (linkClicked === "homepage" ||
                  currentURL.pathname === "/Mapview") &&
                "active"
              }`}
            >
              Web GIS
            </Link>
            <Link
              onClick={(e) => {
                handleNavLinkClick(e);
              }}
              name="dati"
              to="/Reperti"
              className={`nav-link ${
                (linkClicked === "dati" ||
                  currentURL.pathname === "/Reperti") &&
                "active"
              }`}
            >
              Archivio Dati
            </Link>
            {/* <Link
              onClick={(e) => {
                handleNavLinkClick(e); 
                
              }}
              name="manuale"
              to="/manualeutente"
              className={`nav-link ${
                (linkClicked === "manuale" || currentURL.pathname === "/manualeutente") &&
                "active"
              }`}
              
            >
              Guida Utente
            </Link> */}
            <ProfileCard user={userProfile} />
          </nav>
        </>
      )}
      {currentURL.pathname !== "/manualeutente" && (
        <div className="floating-menu">
          <Link
            onClick={(e) => {
              handleNavLinkClick(e);
            }}
            name="manuale"
            to="/manualeutente"
            className={`nav-link ${
              (linkClicked === "manuale" ||
                currentURL.pathname === "/manualeutente") &&
              "active"
            }`}
          >
            <ManualIcon height="40px" width="40x" />
            {/*<p className="floating-label"> Guida Utente </p> */}
          </Link>
        </div>
      )}
    </header>
  );
}

const PhoneIcon = () => (
  <div className="icon-container">
    <PhoneIcons />
  </div>
);

export default Header;
