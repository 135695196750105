import React, { useState, useMemo, useCallback } from "react";
import { ExclamationTriangleIcon } from "../../../assets/icons";
import FormField from "../../FormField/FormField";
import PianiSection from "../PianiSection/PianiSection";
import CustomSelect from "../../Common/CustomSelect/CustomSelect";

const DepositoDetailForm = ({
  showIssues,
  issues,
  disabled = false,
  formData,
  comuniData = [],
  datastructure,
  handleUpdatePiano,
  handleAddPiano,
  handleAddScaffle,
  handleRemoveScaffale,
  handleRemovePiano,
  handleUpdateScaffaleNome,
  handleAllegatoChange,
  handleScaffaleAllegatoChange,
  handleFormDataChange,
  handleSelectChange,
}) => {
  const comuniOptions = useMemo(() => {
    return comuniData.map((comune) => {
      return {
        value: comune.id,
        label: comune.nome,
      };
    });
  }, [comuniData]);

  return (
    <div className="content-wrapper">
      <div className="issue-container">
        {showIssues &&
          issues.map((issue, index) => {
            return (
              <div key={index} className="issue">
                <p className="issue-icon">
                  <ExclamationTriangleIcon />
                </p>
                {issue}
              </div>
            );
          })}
      </div>
      <div className="input-fields-column">
        <div className="left-column" style={{ width: "100%" }}>
          <div className="form-block">
            <p className="section-title">GENERALITÀ</p>
            <FormField
              fieldStructure={datastructure["id"]}
              value={formData?.id}
              disabled={disabled}
              onChange={(val) => handleFormDataChange("id", val)}
            />
            <FormField
              fieldStructure={datastructure["indirizzo"]}
              value={formData?.indirizzo}
              disabled={disabled}
              onChange={(val) => handleFormDataChange("indirizzo", val)}
            />
            <CustomSelect
              options={comuniOptions}
              initValue={formData?.comune}
              name={"comune"}
              label="Comune"
              placeholder="Selezione Comune"
              disabled={disabled}
              onChange={handleSelectChange}
            />
          </div>
        </div>
        <div className="right-column" style={{ width: "100%" }}>
          <div className="form-block">
            <PianiSection
              piani={formData?.piani || []}
              disabled={disabled}
              onChange={handleUpdatePiano}
              handleAllegatoChange={handleAllegatoChange}
              handleAddPiano={handleAddPiano}
              handleAddScaffle={handleAddScaffle}
              handleRemoveScaffale={handleRemoveScaffale}
              handleUpdateScaffaleNome={handleUpdateScaffaleNome}
              handleRemovePiano={handleRemovePiano}
              handleScaffaleAllegatoChange={handleScaffaleAllegatoChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositoDetailForm;
