import React, { useState, useCallback, useMemo } from "react";
import DataTableSubHeader from "../../Components/DataTableSubHeader/DataTableSubHeader.jsx";
import Spinner from "../../Components/ProgressBar/Spinner.js";
import ItemsTable from "../../Components/ItemsTable/ItemsTable.jsx";
import { SubHeaderMobileOption } from "../../Components/SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import DataDropdown from "../../Components/DataDropdown/DataDropdown.jsx";
import ReactModal from "../../Components/ReactModal/ReactModal.jsx";
import FilterModal from "../../Components/FilterModal/FilterModal.jsx";
import {
  AddIcon,
  FilterIcon,
  DownloadIcon,
  EyeIcon,
  DeleteIcon,
} from "../../assets/icons";
import userHasPermission from "../../helpers/formTools/userHasPermission/index.js";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Components/Context/AppContext.jsx";
import useFetchData from "../../hooks/useFetchData.js";
import jsonToXlsx from "../../helpers/formTools/transformations/jsonToXlsx/jsonToXlsx.js";
import datastructure from "../../constants/dataStructure/deposito.json";
import { getDepositi, deleteDeposito } from "../../api";
import { toast } from "react-toastify";
import "./Deposito.scss";

const headers = [
  { key: "actions", label: "", width: "100px", sortable: false },
  { key: "id", label: "Identificativo", width: "180px", sortable: false },
  { key: "indirizzo", label: "Indirizzo", width: "150px", sortable: true },
];

const filterValues = [
  { label: "id", key: "id" },
  { label: "indirizzo", key: "indirizzo" },
];

const Deposito = () => {
  const navigate = useNavigate();
  const { depositoFilter, setDepositoId, setDepositoFilter, userProfile } =
    useAppContext();
  const [isMenuActive, setMenuActive] = useState(false);
  const [recordFilterModalOpen, setRecordFilterModalOpen] = useState(false);
  const {
    data,
    isLoading,
    currentPage,
    recordsPerPage,
    totalRecords,
    totalPages,
    sortDirection,
    setCurrentPage,
    setRecordsPerPage,
    setSortDirection,
  } = useFetchData(getDepositi, depositoFilter);

  const userCanCreate = userHasPermission({
    userProfile,
    resource: "DEPOSITO",
    permission: "CREATE",
  });

  const downloadCSVData = useCallback(
    (e) => {
      const jsonArr = data.map(({ actions, ...rest }) => rest);
      jsonToXlsx(jsonArr, "deposito.xlsx");
    },
    [data]
  );

  const handleFilterIconClick = useCallback((e) => {
    setRecordFilterModalOpen(true);
  }, []);

  const handlePageChange = useCallback(
    (newPage) => {
      setCurrentPage(newPage);
    },
    [setCurrentPage]
  );

  const handleRecordsPerPageChange = useCallback(
    (newRecordsPerPage) => {
      setRecordsPerPage(newRecordsPerPage);
      setCurrentPage(1);
    },
    [setCurrentPage, setRecordsPerPage]
  );

  const handleCallBackSort = useCallback(
    (key) => {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
      setDepositoFilter([{ key: "sort", value: `${key},${sortDirection}` }]);
    },
    [setDepositoFilter, sortDirection, setSortDirection]
  );

  const removeDeposito = useCallback(async (id) => {
    try {
      await deleteDeposito(id);
      toast.success("Data deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 20);
    } catch (error) {
      toast.error("Error deleting data");
    }
  }, []);

  const closeRecordFilterModal = useCallback(() => {
    setRecordFilterModalOpen(false);
  }, []);

  const handleSetDepositoFilter = useCallback((filter) => {
    console.log("==| handleSetDepositoFilter", filter);
  }, []);

  const handleCallBackFilter = useCallback(
    (data) => {
      const filter = data.map((item) => {
        return { key: item.label, value: item.value };
      });
      setDepositoFilter(filter);
      return true;
    },
    [setDepositoFilter]
  );

  const handleReset = (resetStatus) => {
    // setIsReset(resetStatus);
    // if (resetStatus) {
    //   // Perform additional actions if needed when reset is true
    //   filterRef.current = false;
    // }
  };

  const convertedData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    let mappedData = []; // Initialize the array to store the converted data
    data.forEach((result) => {
      let jsonObject = { id: { value: result.id }, actions: { value: [] } }; // Initialize the row object

      // Convert row attributes in the right format base on a key and a value to be displayed
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
          let value;
          if (datastructure[key] && datastructure[key].type === "object") {
            value = result[key][datastructure[key].objectName];
          } else {
            value =
              result[key] !== undefined && result[key] !== null
                ? result[key].toString()
                : "";
          }
          jsonObject[key] = {
            value,
          };
        }
      }

      // Identifies the ID of the record
      const Id = jsonObject.id.value.replace(/^"|"$/g, "");

      // Add the actions to the row
      jsonObject.actions = {
        value: [],
        component: (
          <>
            <div
              onClick={() => {
                navigate("/deposito/view");
                setDepositoId(Id);
              }}
              className="row-icon"
              style={{ cursor: "pointer" }}
            >
              <EyeIcon />
            </div>
            {userHasPermission({
              userProfile,
              resource: "DEPOSITO",
              resourceGroups: result.groups,
              permission: "DELETE",
            }) && (
              <div
                onClick={() => removeDeposito(Id)}
                style={{ cursor: "pointer" }}
              >
                <DeleteIcon />
              </div>
            )}
          </>
        ),
      };
      mappedData.push(jsonObject);
    });
    return mappedData;
  }, [data, navigate, setDepositoId, userProfile, removeDeposito]);

  // NAVIGATION
  const breadcrumbsData = [
    { id: 1, label: "Dati Archeologici", iconUrl: true },
    { id: 2, label: "Depositi" },
  ];

  const subHeaderRightNavOptions = {
    icons: [
      userCanCreate && {
        icon: <AddIcon />,
        label: "Add",
        onClick: () => {
          navigate("/deposito/add");
          return null;
        },
      },
      { icon: <FilterIcon />, onClick: handleFilterIconClick, label: "Filtro" },
      {
        icon: <DownloadIcon />,
        label: "Download",
        onClick: () => {
          downloadCSVData();
        },
      },
    ],
    components: [<DataDropdown defaultSelected="DEPOSITO" />],
  };

  return (
    <div className="container">
      <main className="record-main-content">
        <DataTableSubHeader
          title={"DEPOSITO"}
          breadcrumbsData={breadcrumbsData}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
        <ReactModal
          isOpen={recordFilterModalOpen}
          onClose={closeRecordFilterModal}
        >
          <FilterModal
            closeModal={closeRecordFilterModal}
            filterValues={filterValues}
            setFilterValue={handleSetDepositoFilter}
            callBackFilter={handleCallBackFilter}
            onReset={handleReset}
          />
        </ReactModal>
        {isLoading ? (
          <Spinner />
        ) : (
          <ItemsTable
            data={convertedData}
            headers={headers}
            filterValues={filterValues}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
            totalRecords={totalRecords}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
            callBackSort={handleCallBackSort}
          />
        )}
        {isMenuActive && (
          <SubHeaderMobileOption
            components={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </main>
    </div>
  );
};

export default Deposito;
