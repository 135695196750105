import { CopyIcon } from "../../../../../assets/icons";
import ImageGallery from "../../../../ImageGallery/ImageGallery";
import GroupsFormField from "../../../../GroupsFormField";
import FormField from "../../../../FormField/FormField";
import Repertori from "../../../../Repertori";
import repertoStructure from "../../../../../constants/dataStructure/reperto.json";
import Provenienze from "../../../../Provenienze";
import BibliografiaSection from "../../../BibliografiaSection/BibliografiaSection";
import MovimentazioneSection from "../../../MovimentazioneSection/MovimentazioneSection";
import CollocazioneSection from "../../CollocazioneSection/CollocazioneSection";
import { copyToClipboard } from "../../../../../helpers/utils/utils";
import { useAppContext } from "../../../../Context/AppContext.jsx";
import userHasPermission from "../../../../../helpers/formTools/userHasPermission/index"

export default function RepertiRecordDetails({
  data,
  immagini,
  setData,
  onRaccoltaChange,
  disabled,
  handleImageChange,
}) {

  const { userProfile } = useAppContext();
  const userCanReadMovimentazione = userHasPermission({userProfile, resource: "movimentazione", permission: "read" });
  const userCanWriteMovimentazione = userHasPermission({userProfile, resource: "movimentazione", permission: "write" });
  const userCanDeleteMovimentazione = userHasPermission({userProfile, resource: "movimentazione", permission: "delete" });


  const handleFieldChange = (key, newValue) => {
    setData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const handleAnnotazioniChange = (key, newValue) => {
    const newAnnotazioni = data.annotazioni.map((a) => {
      if (a.chiave === key) return { ...a, valore: newValue };
      return a;
    });
    setData((prevData) => ({
      ...prevData,
      annotazioni: newAnnotazioni,
    }));
  };

  if (data && Object.keys(data).length > 0) {
    return (
      <div className="blocks-container">
        <div className="form-block" id="generalità" style={{ order: 1 }}>
          <p className="section-title"> GENERALITÀ</p>
          <div className="reperto-id-header">
            <p className="reperto-id-text"> Codice Identificativo</p>
            <p
              className="copy-text"
              onClick={() => copyToClipboard(data["id"])}
            >
              {data["id"]}
              <CopyIcon />
            </p>
          </div>
          <GroupsFormField
            groups={data["groups"]}
            onChange={(newValue) => handleFieldChange("groups", newValue)}
            edit={!disabled}
          />
          <Repertori
            repertori={data["codiciInventario"]}
            onChange={(newValue) =>
              handleFieldChange("codiciInventario", newValue)
            }
            edit={!disabled}
          />
        </div>
        <div className="form-block" id="galleria" style={{ order: 2 }}>
          <p className="section-title"> GALLERIA IMMAGINI</p>
          <ImageGallery
            disabled={disabled}
            immagini={immagini}
            handleImageChange={handleImageChange}
          />
        </div>
        <div className="form-block" id="bibliografia" style={{ order: 3 }}>
          <BibliografiaSection
            edit={!disabled}
            repertoId={data.id}
            repertiNote={data.note}
          />
        </div>
        <div className="form-block" id="provenienza" style={{ order: 5 }}>
          <p className="section-title"> PROVENIENZA</p>
          <FormField
            fieldStructure={repertoStructure["anno"]}
            value={data["anno"]}
            // value={data["anno"] ? data["anno"].value : ""}
            onChange={(newValue) => handleFieldChange("anno", newValue)}
            disabled={disabled}
          />
          <Provenienze
            provenienze={data["provenienze"]}
            onChange={(newValue) => handleFieldChange("provenienze", newValue)}
            edit={!disabled}
          />
        </div>
        <div className="form-block" id="caratteristiche" style={{ order: 6 }}>
          <div className="blockHeader">
            <p className="section-title"> CARATTERISTICHE</p>
          </div>
          <FormField
            fieldStructure={repertoStructure["materia"]}
            value={data["materia"]}
            onChange={(newValue) => handleFieldChange("materia", newValue)}
            disabled={disabled}
          />
          <FormField
            fieldStructure={repertoStructure["classe"]}
            value={data["classe"]}
            onChange={(newValue) => handleFieldChange("classe", newValue)}
            disabled={true}
          />
          <FormField
            fieldStructure={repertoStructure["forma"]}
            value={data["forma"]}
            onChange={(newValue) => handleFieldChange("forma", newValue)}
            disabled={disabled}
          />
          <FormField
            fieldStructure={repertoStructure["tipo"]}
            value={data["tipo"]}
            onChange={(newValue) => handleFieldChange("tipo", newValue)}
            disabled={disabled}
          />
          <FormField
            fieldStructure={repertoStructure["cronologia"]}
            value={data["cronologia"]}
            onChange={(newValue) => handleFieldChange("cronologia", newValue)}
            disabled={disabled}
          />
        </div>
        { userCanReadMovimentazione && <div className="form-block" id="movimentazione" style={{ order: 7 }}>
          <MovimentazioneSection
            repertoData={data}
            edit={!disabled}
            repertiId={data.id}
            userCanWriteMovimentazione={userCanWriteMovimentazione}
            userCanDeleteMovimentazione={userCanDeleteMovimentazione}
          />
        </div>
        }
        <div className="form-block" id="collocazione" style={{ order: 8 }}>
          <CollocazioneSection
            repertoData={data}
            disabled={disabled}
            onRaccoltaChange={onRaccoltaChange}
            handleFieldChange={handleFieldChange}
          />
        </div>
        <div className="form-block" id="annotazioni" style={{ order: 9 }}>
          <p className="section-title"> ANNOTAZIONI</p>
          {data?.annotazioni?.length ? (
            data?.annotazioni?.map((annotation, index) => (
              <div key={index} className="annotation">
                <FormField
                  fieldStructure={{
                    label: annotation?.chiave,
                    type: "text",
                    name: "index",
                  }}
                  value={annotation?.valore}
                  onChange={(newValue) => {
                    handleAnnotazioniChange(annotation?.chiave, newValue);
                  }}
                  disabled={disabled}
                />
              </div>
            ))
          ) : (
            <p>Non ci sono annotazioni.</p>
          )}
        </div>
      </div>
    );
  }
}
