import { useState, useEffect, useCallback } from "react";
import CustomModal from "../../../Common/CustomModal";
import BibliografiaModalTable from "./BibliografiaModalTable/BibliografiaModalTable";
import {
  getBibliografia,
  updateBibliografie,
} from "../../../../api";
import { debounce } from "../../../../helpers/utils/utils";
import "./AddBibliografiaModal.scss";

const AddBibliografiaModal = ({
  open,
  repertoId,
  repertiNote,
  selectedData,
  handleOpenNew,
  handleUpdateBiliografia,
  handleClose,
}) => {
  const [bibliografiaData, setBibliografiaData] = useState([]);
  const [abbreviazioneFilter, setAbbreviazioneFilter] = useState("");
  const [results, setResults] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const fetchBibliografiaData = useCallback(
    async (page, pageSize) => {
      setIsLoading(true);
      try {
        const data = await getBibliografia({
          page,
          pageSize,
          abbreviazione: abbreviazioneFilter,
        });
        const formattedData = data?.results.map((result) => ({
          id: result.id,
          titolo: result.titolo,
          label: result.titolo,
          value: result.titolo,
          abbreviazione: result.abbreviazione,
          note: result.note,
        }));
        setResults(data?.results);
        setBibliografiaData(formattedData || []);
        setTotalRows(data?.totalElements || 0);
        setPerPage(data?.pageSize || 10);
      } catch (error) {
        console.error("Error fetching bibliografia data", error);
      } finally {
        setIsLoading(false);
      }
    },
    [abbreviazioneFilter]
  );

  const handleCallbackPerPageChange = useCallback((newPerPage, page) => {
    setPerPage(page, newPerPage);
  }, []);

  const handlePageChange = useCallback(
    (page) => {
      fetchBibliografiaData(page, perPage);
    },
    [fetchBibliografiaData, perPage]
  );

  const handleSort = useCallback((column, sortDirection) => {
    console.log("column", column);
    console.log("sortDirection", sortDirection);
  }, []);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleFilter = debounce((e) => {
    setAbbreviazioneFilter(e.target.value);
  }, 500);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let filteredResults = [];

    if (selectedData.length > 0) {
      filteredResults = results
        .filter((item) => selectedRows.some((row) => row.id === item.id))
        .filter((item) => selectedData.some((row) => row.id !== item.id));
    } else {
      filteredResults = results.filter((item) =>
        selectedRows.some((row) => row.id === item.id)
      );
    }

    const submitData = {
      id: repertoId,
      bibliografie: filteredResults.map((item) => ({
        bibliografiaId: item.id,
        note: item.note || "",
      })),
    };


    try {
      await updateBibliografie(submitData);
    } catch (err) {
      console.log("update-error-->>", err);
    } finally {
      handleClose();
      handleUpdateBiliografia();
    }
  };

  useEffect(() => {
    fetchBibliografiaData(1, perPage);
  }, [fetchBibliografiaData, perPage]);

  return (
    <CustomModal
      title="Aggiungi Bibliografia"
      isVisible={open}
      onClose={handleClose}
    >
      <div>
        <div className="add-reperti-bibliografia-modal-body">
          <div className="reperti-filter-section">
            <button onClick={handleOpenNew}>Aggiungi</button>
            <input type="text" name="abbreviazione" onChange={handleFilter} />
          </div>
          <BibliografiaModalTable
            data={bibliografiaData}
            totalRows={totalRows}
            isLoading={isLoading}
            selectedRows={selectedData}
            handlePageChange={handlePageChange}
            handlePerRowsChange={handleCallbackPerPageChange}
            handleSort={handleSort}
            handleRowSelected={handleRowSelected}
          />
        </div>
        <footer className="modal-footer">
          <button onClick={handleSubmit} className="modal-footer-button">
            Submit
          </button>
        </footer>
      </div>
    </CustomModal>
  );
};

export default AddBibliografiaModal;
