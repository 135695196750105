import { useState } from "react";
import { DeleteIcon } from "../../../../assets/icons";
import Select from "react-select";
import "./AnnotationSection.scss";

const AnnotationSection = ({
  annotation,
  headers,
  handleAnnotationChange,
  handleDeleteAnnotation,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const onKeyChange = (e) => {
    handleAnnotationChange("value", e.target.value, annotation?.id);
  };

  const onDeleteOrigin = () => {
    handleDeleteAnnotation(annotation?.id);
  };

  const handleSelectChange = (option) => {
    setSelectedOption(option);
    handleAnnotationChange("index", option.index, annotation?.id);
  };

  return (
    <div className="annotation-section-wrapper">
      <div className="annotation-delete-wrapper">
        <button onClick={onDeleteOrigin}>
          <DeleteIcon />
        </button>
      </div>
      <div className="annotation-inputs-wrapper">
        <div className="annotation-input-wrapper">
          <label className="chiave-label" htmlFor="chiave">
            Chiave
          </label>
          <Select
            options={headers}
            formatOptionLabel={(item) => <p>{`${item?.value}`}</p>}
            value={selectedOption}
            placeholder="Seleziona chiave"
            onChange={handleSelectChange}
          />
        </div>
        <div className="annotation-input-wrapper">
          <label className="chiave-label" htmlFor="chiave">
            Valore
          </label>
          <input
            id="valore"
            name="value"
            type="text"
            value={annotation?.value}
            onChange={onKeyChange}
          />
        </div>
      </div>
    </div>
  );
};

export default AnnotationSection;
