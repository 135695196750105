import React from "react";
import "./SubHeaderRightNavOptions.scss";

function SubHeaderRightNavOptions({ icons = [], components = [] }) {
  return (
    <div className="right-container">
      <div className="image-grid">
        {icons.map(({ icon = <></>, onClick = () => {}, label = "" }, index) => (
          <span
            key={index}
            className="grid-image"
            onClick={onClick}
          >
            {icon}
            <span className="show-preview-labels">{label}</span>
          </span>
        ))}
      </div>
      {icons.length > 0 && components.length > 0 ? <div className="divider" /> : null}
      {components.length > 0 ? components.map((component, index) => (
        <React.Fragment key={index}>{component}</React.Fragment>
      )) : null}
    </div>
  );
}

export default SubHeaderRightNavOptions;