import React, { useCallback, useState, useMemo, useEffect } from "react";
import { getMuseum, getMuseo, getMuseumDetails } from "../../../../api";
import { AsyncPaginate } from "react-select-async-paginate";
import Select from "react-select";
import NewLocationModal from "./NewLocationModal";

const EsposizioneCollocazione = ({
  repertoData,
  disabled,
  handleFieldChange,
}) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const [selectedMuseum, setSelectedMuseum] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);

  const isUpdate = useMemo(() => locationOptions.length > 0, [locationOptions])

  const loadMuseumOptions = useCallback(
    async (search, prevOptions, { page, pageSize }) => {
      try {
        const response = await getMuseum({
          page,
          pageSize,
          ordering: "denominazione",
          orderDir: "asc",
          search,
        });
        const newOptions = response.results.map((museum) => ({
          value: museum.id,
          label: museum.denominazione,
        }));
        return {
          options: newOptions,
          hasMore: response.next !== null,
          additional: {
            page: page + 1,
            pageSize,
          },
        };
      } catch (error) {
        console.error("Error loading museum options:", error);
        return { options: [], hasMore: false };
      }
    },
    []
  );

  const fetchLocations = useCallback(async () => {
    if (!selectedMuseum) return;
    try {
      const response = await getMuseo({
        id: selectedMuseum.value,
        page: 1,
        pageSize: 20,
      });
      setLocationOptions(
        response.results[0].locations?.map((location) => ({
          value: location.id,
          label: location.location,
        }))
      );
    } catch (err) {
      console.error("Error fetching locations", err);
    }
  }, [selectedMuseum]);

  const handleMuseumChange = (selectedOption) => {
    setSelectedMuseum(selectedOption);
    setSelectedLocation(null);
    setLocationOptions([]);
  };

  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
    handleFieldChange("esposizione", {
      id: repertoData.id,
      locationId: selectedOption.value,
    });
  };

  const toggleNewLocationModal = useCallback((show) => {
    setShowNewModal(show);
  }, []);

  const museumAdditional = useMemo(
    () => ({
      page: pagination.page,
      pageSize: pagination.pageSize,
    }),
    [pagination.page, pagination.pageSize]
  );

  useEffect(() => {
    fetchLocations();
  }, [selectedMuseum, fetchLocations]);

  useEffect(() => {
    if (repertoData.esposizione) {
      getMuseumDetails(repertoData.esposizione.museoId).then((response) => {
        setSelectedMuseum({
          value: repertoData.esposizione.museoId,
          label: response.denominazione,
        });
        setSelectedLocation({
          value: repertoData.esposizione.id,
          label: repertoData.esposizione.location,
        });
      });
    }
  }, []);

  return (
    <React.Fragment>
      <div className="esposizione-container">
        <div className="input-wrapper">
          <label className="input-label">Museum:</label>
          <AsyncPaginate
            value={selectedMuseum}
            loadOptions={loadMuseumOptions}
            onChange={handleMuseumChange}
            additional={museumAdditional}
            placeholder="Seleziona una opzione"
            isDisabled={disabled}
          />
        </div>

        {selectedMuseum && !disabled && (
          <div className="new-location-container">
            <button
              className="new-location-btn left-btn"
              onClick={() => toggleNewLocationModal(true)}
            >
              New Location
            </button>
          </div>
        )}
        <div className="input-wrapper">
          <label className="input-label">Location:</label>
          <Select
            options={locationOptions}
            required
            value={selectedLocation}
            placeholder="Seleziona una opzione"
            onChange={handleLocationChange}
            isDisabled={disabled || !selectedMuseum}
          ></Select>
        </div>
      </div>
      <NewLocationModal
        show={showNewModal}
        isUpdate={isUpdate}
        museumId={selectedMuseum?.value}
        handleClose={() => toggleNewLocationModal(false)}
        handleNewLocation={fetchLocations}
      />
    </React.Fragment>
  );
};

export default EsposizioneCollocazione;
