import { useState, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import "./MovimentazioneSection.scss";
import BlockHeader from "../../Common/BlockHeader/BlockHeader";
import AddMovimentazioneModal from "./AddMovimentazioneModal/AddMovimentazioneMoal";
import MovimentazioneSectionTable from "./MovimentazioneSectionTable/MovimentazioneSectionTable";
import { AddIcon } from "../../../assets/icons";
import CustomTooltip from "../../CustomTooltip";
import { getMovimentazione } from "../../../api";
import useFetchData from "../../../hooks/useFetchData";
import { useAppContext } from "../../Context/AppContext";

const MovimentazioneSection = ({ repertoData, edit, repertiId, userCanWriteMovimentazione, userCanDeleteMovimentazione }) => {
  const navigate = useNavigate();
  const [isAddModalShow, setIsAddModalShow] = useState(false);
  const filter = useMemo(
    () => [
      { key: "reperto.id", value: repertiId },
      { key: "sort", value: "inizio, desc" },
    ],
    [repertiId]
  );
  const { setMovimentazioneId } = useAppContext();
  const {
    data: movimentazioneData,
    isLoading,
    totalRecords,
    handleCallbackPerPageChange,
    handlePageChange,
    handleSort,
    fetchData,
  } = useFetchData(getMovimentazione, filter);

  const handleMovimentazioneView = useCallback(
    (id) => {
      setMovimentazioneId(id);
      navigate("/movimentazione/view");
    },
    [navigate, setMovimentazioneId]
  );

  const handleMovimantazioneDelete = useCallback((id) => {
    console.log("===| handleMovimantazioneDelete: ", id);
  }, []);

  return (
    <div>
      <BlockHeader title="MOVIMENTAZIONE">
        {edit && userCanWriteMovimentazione && (
          <CustomTooltip text={"Aggiungi Movimentazione"}>
            <button
              className="repertori-button"
              onClick={() => {
                setIsAddModalShow(true);
              }}
            >
              <AddIcon fillColor="#e15615" />
            </button>
          </CustomTooltip>
        )}
      </BlockHeader>
      <MovimentazioneSectionTable
        repertoData={repertoData}
        movimentazioneData={movimentazioneData}
        disabled={edit}
        totalRows={totalRecords}
        isLoading={isLoading}
        handlePerRowsChange={handleCallbackPerPageChange}
        handlePageChange={handlePageChange}
        handleSort={handleSort}
        handleMovimentazioneView={handleMovimentazioneView}
        handleMovimantazioneDelete={handleMovimantazioneDelete}
        userCanWriteMovimentazione={userCanWriteMovimentazione}
        userCanDeleteMovimentazione={userCanDeleteMovimentazione}
      />
      {isAddModalShow && (
        <AddMovimentazioneModal
          show={isAddModalShow}
          repertiId={repertiId}
          disabled={!edit}
          onSubmit={fetchData}
          handleClose={() => setIsAddModalShow(false)}
        />
      )}
    </div>
  );
};

export default MovimentazioneSection;
