import { useState, useRef, useMemo, useEffect } from "react";

import EditorHeader from "./EditorHeader";
import FormElement from "./FormElement";
import CustomWarningModal from "../../CustomWarningModal";
import {
  updateNestedData,
  exportJson,
  checkComplete,
  updateSelectedOption,
  updateInputValue,
  updateToggleStatus,
} from "./helper";

import "./style.css";

const CcidEditor = ({
  manifest = {},
  data,
  edit,
  visibility = 1,
  knownData,
  onSave,
  onClose,
}) => {
  const [formData, setFormData] = useState({ ...manifest });
  const [refData, setRefData] = useState({ ...manifest });
  const [isView, setIsView] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isRefErr, setIsRefErr] = useState(false);
  const fileInput = useRef(null);

  const { title = "", version = "", release = "", formFields = [] } = formData;

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  useEffect(() => {
    if (isRefErr) {
      setShowModal(true);
    }
  }, [isRefErr]);

  const formStatus = useMemo(
    () => checkComplete(formData.formFields),
    [formData]
  );

  const handleDataChange = (updatedData, type) => {
    let initData = JSON.parse(JSON.stringify(formData));
    let tempData = JSON.parse(JSON.stringify(updatedData));

    const updatedNestedData = updateNestedData(
      initData.formFields,
      tempData,
      type
    );
    setFormData({ ...initData, formFields: updatedNestedData });
  };

  const handleSelectChange = (option) => {
    let updatedData = updateSelectedOption(formData, option);
    setFormData(updatedData);
  };

  const handleInputChange = (value) => {
    let updatedData = updateInputValue(formData, value);
    setFormData(updatedData);
  };

  const handleToggleChange = (status) => {
    const updatedData = updateToggleStatus(formData, status);
    setFormData(updatedData);
  };

  const handleExport = () => {
    exportJson({
      refData: refData,
      formData: formData,
    });
  };

  const handleImport = () => {
    fileInput.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      const data = JSON.parse(content);
      const { formData = {} } = data;
      // setRefData(refData);
      setFormData(formData);
    };
    reader.readAsText(file);
  };

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    setIsView(true);
    onSave(formData);
  };

  const handleChangeView = () => {
    setIsView(false);
  };

  return (
    <div className="editor-container">
      <EditorHeader
        edit={edit}
        isView={isView}
        title={title}
        version={version}
        status={formStatus}
        handleExport={handleExport}
        handleImport={handleImport}
        handleClose={handleClose}
        handleSave={handleSave}
        handleChangeView={handleChangeView}
      />
      <input
        type="file"
        ref={fileInput}
        className="file-upload"
        onChange={handleFileChange}
      />
      <div className="editor-body">
        {formFields.map((field, index) => (
          <FormElement
            key={index}
            data={field}
            isView={isView}
            refData={refData}
            setIsRefErr={setIsRefErr}
            onDataChange={handleDataChange}
            onInputChange={handleInputChange}
            onSelectChange={handleSelectChange}
            onToggleChange={handleToggleChange}
          />
        ))}
      </div>
      <CustomWarningModal
        isOpen={showModal}
        text={"The form data attribute is invalid."}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default CcidEditor;
