import React, { useState, useCallback } from "react";
import CustomModal from "../Common/CustomModal";

const ForgotPwdModal = ({ isOpen, onSubmit, onClose }) => {
  const [formData, setFormData] = useState("");

  const handleOnChange = useCallback((e) => {
    setFormData(e.target.value);
  }, []);

  const handleSendEmail = useCallback((e) => {
    e.preventDefault();
    onSubmit(formData);
  }, [formData, onSubmit]);

  return (
    <CustomModal title="Recupera Password" isVisible={isOpen} onClose={onClose}>
      <form className="forgot-pwd-modal" onSubmit={handleSendEmail}>
        <div className="forgot-pwd-modal-content">
          <p>Inserisci la tua email per recuperare la password</p>
          <input
            type="text"
            placeholder="Username"
            required
            value={formData}
            onChange={handleOnChange}
          />
        </div>
        <footer className="modal-footer">
          <button type="submit" className="modal-footer-button">
            Submit
          </button>
        </footer>
      </form>
      <style jsx="true">
        {`
          .forgot-pwd-modal {
            .forgot-pwd-modal-content {
              padding: 1rem;
              & > p {
                margin-bottom: 1rem;
                font-weight: 500;
                color: #333;
              }
              & > input {
                width: 100%;
                padding: 0.5rem;
                margin-bottom: 1rem;
                border-radius: 5px;
                border: 1px solid #ccc;
                line-height: 24px;
                outline: none;
              }
            }
          }
        `}
      </style>
    </CustomModal>
  );
};

export default ForgotPwdModal;
