import "./SignInForm.scss";
import React, { useCallback, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { InputComponent } from "../../Components/InputComponent/InputComponent";
import { LeftArrowIcon } from "../../assets/icons/leftArrowIcon";
import config from "../../config";
import { useAppContext } from "../../Components/Context/AppContext";
import ForgotPwdModal from "../../Components/ForgotPwdModal/ForgotPwdModal";
import { toast } from "react-toastify";
import axios from "axios";

export function SignInForm() {
  const { setUserProfile } = useAppContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [forgotModal, setForgotModal] = useState(false);
  const navigate = useNavigate();
  localStorage.removeItem("ambientiLid");
  localStorage.removeItem("sitiLid");
  localStorage.removeItem("saggiLid");
  localStorage.removeItem("repertiLid");

  const handleOnChangeUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleOnChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleForgotPwdModalOpen = (e) => {
    e.preventDefault();
    console.log("forgot password");
    setForgotModal(true);
  };

  const handleForgotPwd = useCallback(async (email) => {
    console.log("forgot password", email);
    try {
      const response = await axios.post(
        config.archeoBaseUrl + "/api/public/v3/auth/password/reset",
        { username: email }
      );

      console.log("forgot-pwd-response-->>", response);
    } catch (error) {
      console.error("Error:", error);
      toast.error("Failed to send email!");
    } finally {
      setForgotModal(false);
    }
  }, []);

  localStorage.removeItem("cbcValue");
  localStorage.removeItem("AREA_SCAVO");
  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const response = await fetch(
        config.archeoBaseUrl + "/api/public/v3/auth/login",
        {
          mode: "cors",
          method: "POST",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: username,
            password: password,
          }),
        }
      );

      const text = await response.text();
      let data;
      try {
        data = text ? JSON.parse(text) : {};
      } catch (e) {
        throw new Error("Failed to parse response as JSON");
      }

      if (response.ok) {
        setUserProfile(data.user);
        const token = data.token;
        localStorage.setItem("authToken", token);
        navigate("/Home");
      } else {
        switch (response.status) {
          case 401:
            setError("Nome utente o password sono errati.");
            break;
          case 404:
            setError("Errore 404 - Pagina non trovata.");
            break;
          case 500:
            setError(
              "Errore interno di sistema. Si prega di riprovare più tardi."
            );
            break;
          default:
            setError(
              data.message ||
                "Errore sconosciuto. Si prega di riprovare più tardi."
            );
            break;
        }
      }
    } catch (error) {
      setError("Errore sconosciuto.");
      console.error("Error:", error);
    }
  };

  return (
    <React.Fragment>
      <form className="sign-in-form" onSubmit={handleLogin}>
        <div className="header-container">
          <h1 className="title">Accedi</h1>
          <p className="description">
            Inserisci il tuo nome utente e password.
          </p>
        </div>
        {error && <p className="error-message">{error}</p>}
        <InputComponent
          label={"Nome utente"}
          type={"username"}
          placeholder="Inserire nome utente"
          onChange={handleOnChangeUsername}
          labelClassName={"input-label"}
        />
        <div className="password-container">
          <div className="label-container">
            <label htmlFor="password" className="password-text">
              Password
            </label>
            <Link
              to="#"
              onClick={handleForgotPwdModalOpen}
              className="forgot-password"
            >
              Password dimenticata?
            </Link>
          </div>
          <InputComponent
            type="password"
            placeholder="Inserire password"
            htmlFor={"password"}
            labelClassName={"input-label"}
            onChange={handleOnChangePassword}
          />
        </div>
        <button type="submit" className="button-login-container">
          <span className="button-text">Accedi </span>
          <LeftArrowIcon />
        </button>
      </form>
      {forgotModal && (
        <ForgotPwdModal
          isOpen={forgotModal}
          onSubmit={handleForgotPwd}
          onClose={() => setForgotModal(false)}
        />
      )}
    </React.Fragment>
  );
}
