import { useEffect, useMemo, useState } from "react";

import Tooltip from "../../../ToolTip";
import ChevronRightIcon from "../../../../assets/icons/ChevronRightIcon";
import ChevronDownIcon from "../../../../assets/icons/ChevronDownIcon";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import MinusIcon from "../../../../assets/icons/MinusIcon";
import FormField from "../FormField";
import { useTooltip } from "../../../../hooks/useTooltip";
import { findRefData, compareRefData } from "./helper";
import { checkComplete } from "../helper";
import "./style.css";

const CollapsibleSection = ({ isCollapsed, isShow, handleCollapse }) => (
  <div
    className={`collapse-icon ${isShow ? "show" : ""}`}
    onClick={handleCollapse}
  >
    {isCollapsed ? (
      <ChevronRightIcon className="size-6" />
    ) : (
      <ChevronDownIcon className="size-6" />
    )}
  </div>
);

const FormElement = ({
  data = {},
  refData = {},
  isView,
  onDataChange,
  onInputChange,
  onSelectChange,
  onToggleChange,
  setIsRefErr,
}) => {
  const {
    acronym = "",
    definition = "",
    alt = "",
    repeat = false,
    use = "",
    fieldType = "",
    len = "",
    dictionary = {},
    children = [],
    placeholder = "",
    id = "",
    duplicated = false,
    active = true,
    selectedOption = null,
    value = "",
  } = data;

  const [isCollapsed, setIsCollapsed] = useState(true);
  const [duplicatedElements, setDuplicatedElements] = useState([]);
  const [isActive, setIsActive] = useState(active);

  const singleFormStatus = useMemo(() => checkComplete(children), [children]);

  useEffect(() => {
    setIsActive(active);
  }, [active]);

  useEffect(() => {
    const resultRefData = findRefData(refData.formFields, acronym);
    if (resultRefData) {
      const hasError = compareRefData(resultRefData, data);
      if (hasError) setIsRefErr(true);
    }
  }, [acronym, refData, data, setIsRefErr]);

  const {
    visible: tooltipVisible,
    show: showTooltip,
    hide: hideTooltip,
  } = useTooltip(alt);
  const {
    visible: tooltipUseVisible,
    show: showUseTooltip,
    hide: hideUseTooltip,
  } = useTooltip(use);

  const handleCollapse = () => {
    if (children.length === 0 || !isActive) return;
    setIsCollapsed(!isCollapsed);
  };

  const handleSelectChange = (selectedOption, id) => {
    if (onSelectChange) onSelectChange({ selectedOption, acronym, id });
  };

  const handleInputChange = (value, id) => {
    if (onInputChange) onInputChange({ value, acronym, id });
  };

  const handleToggle = (e, status) => {
    e.stopPropagation();
    setIsActive(status);
    if (onToggleChange) onToggleChange({ status, acronym });
    if (!status) setIsCollapsed(true);
  };

  const handleToggleChange = (status) => {
    if (onToggleChange) onToggleChange(status);
  };

  const handleRepeatAdd = (e) => {
    e.stopPropagation();
    const newElement = { ...data, id: Date.now(), duplicated: true };
    const updatedDuplicatedElements = [...duplicatedElements, newElement];
    setDuplicatedElements(updatedDuplicatedElements);
    if (onDataChange)
      onDataChange(
        { ...data, duplicatedElements: updatedDuplicatedElements },
        "add"
      );
  };

  const handleRepeatMinus = () => {
    const updatedDuplicatedElements = duplicatedElements.filter(
      (element) => element.id !== id
    );
    setDuplicatedElements(updatedDuplicatedElements);
    if (onDataChange) onDataChange({ ...data, id }, "remove");
  };

  let minLen = 0,
    maxLen = 0;
  if (len.split(",").length > 1) [minLen, maxLen] = len.split(",");
  else minLen = maxLen = len;

  const tooltipStyle = children.length
    ? { top: "100%" }
    : {
        top: "80%",
      };
  const tooltipUseStyle = children.length
    ? { bottom: "100%" }
    : {
        bottom: "80%",
      };
  const repeatAddStyle =
    repeat && !duplicated
      ? {
          left: acronym.length * 10 + 20,
        }
      : { display: "none" };
  const duplicatedStyle = duplicated
    ? { left: "3rem", top: "43%", backgroundColor: "#ff0000ad" }
    : { display: "none" };

  return (
    <div className="form-element-container">
      <div
        className={`parent-form-element ${
          isCollapsed ? "collapsed" : "collapsed-open"
        } ${children.length && "has-children"}`}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onClick={handleCollapse}
        style={isActive ? {} : { opacity: 0.5 }}
      >
        {(use === "required" || use === "recommended") && !duplicated ? (
          <div
            className="use-icon"
            onMouseEnter={showUseTooltip}
            onMouseLeave={hideUseTooltip}
          >
            <p
              style={use === "required" ? { color: "red" } : { color: "green" }}
            >
              *
            </p>
            {tooltipUseVisible && (
              <Tooltip
                text={use}
                visible={tooltipUseVisible}
                style={tooltipUseStyle}
              />
            )}
          </div>
        ) : (
          ""
        )}
        <div
          className="repeat-element-add"
          style={duplicatedStyle}
          onClick={handleRepeatMinus}
        >
          <MinusIcon className={"size-6"} strokeWidth={5} />
        </div>
        <div className="form-element-wrapper">
          <div
            className="form-element-header"
            style={duplicated ? { paddingLeft: 32 } : {}}
          >
            <div className="form-element-acronym">
              {acronym}
              <div
                className="repeat-element-add"
                style={repeatAddStyle}
                onClick={handleRepeatAdd}
              >
                <PlusIcon className={"size-6"} strokeWidth={5} />
              </div>
            </div>
            <p className="form-element-definition">{definition}</p>
          </div>
          <FormField
            id={id}
            isView={isView}
            fieldType={fieldType}
            dictionary={dictionary}
            use={use}
            status={singleFormStatus}
            duplicated={duplicated}
            placeholder={placeholder}
            minLen={minLen}
            maxLen={maxLen}
            storeValue={value}
            initStatus={isActive}
            storeSelectedOption={selectedOption}
            handleSelectChange={handleSelectChange}
            handleInputChange={handleInputChange}
            onToggle={handleToggle}
          />
        </div>
        <CollapsibleSection
          isCollapsed={isCollapsed}
          isShow={children.length > 0 && isActive}
          handleCollapse={handleCollapse}
        />
        {tooltipVisible && (
          <Tooltip text={alt} visible={tooltipVisible} style={tooltipStyle} />
        )}
      </div>

      <div className={`form-element-child ${isCollapsed ? "" : "show"}`}>
        {children.map((child, index) => (
          <FormElement
            key={index}
            data={child}
            refData={refData}
            isView={isView}
            onDataChange={onDataChange}
            setIsRefErr={setIsRefErr}
            onInputChange={handleInputChange}
            onSelectChange={handleSelectChange}
            onToggleChange={handleToggleChange}
          />
        ))}
      </div>
    </div>
  );
};

export default FormElement;
