import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext } from "../../../Components/Context/AppContext";
import {
  AddIcon,
  CrossIcon,
  RightArrowIcon,
  EditIcon,
} from "../../../assets/icons";
import { getDeposito, saveDeposito, fetchComuni } from "../../../api";
import DepositoDetailForm from "../../../Components/Deposito/DepositoDetailForm/DepositoDetailForm";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader";
import validateDeposito from "../../../helpers/formTools/validations/deposito/validateDeposito";
import userHasPermission from "../../../helpers/formTools/userHasPermission";
import datastructure from "../../../constants/dataStructure/deposito.json";
import { removeIdFromName } from "../../../helpers/utils/utils";
import { v4 as uuidv4 } from "uuid";
import useFetchData from "../../../hooks/useFetchData";

const depositoRecordDetailsBreadcrumbs = [
  {
    id: 1,
    label: "Dati Archeoligici",
    iconUrl: true,
  },
  {
    id: 2,
    label: "DEPOSITO",
    iconUrl: true,
  },
  { id: 3, label: "EDIT DEPOSITO Record Details", iconUrl: "" },
];

const EditDeposito = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { depositoId, userProfile } = useAppContext();
  const [formData, setFormData] = useState({});
  const [showIissues, setShowIssues] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);
  const disabled = useMemo(() => pathname !== "/deposito/edit", [pathname]);
  const issues = useMemo(() => validateDeposito(formData), [formData]);
  const { data: comuniData } = useFetchData(fetchComuni);

  const handleAddPiano = useCallback(() => {
    setFormData((prev) => {
      return {
        ...prev,
        piani: [
          ...prev.piani,
          {
            nome: "",
            id: uuidv4(),
            allegato: null,
            scaffali: [],
          },
        ],
      };
    });
  }, []);

  const handleRemovePiano = useCallback((id) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.filter((piano) => piano.id !== id),
    }));
  }, []);

  const handleUpdatePiano = useCallback((val, name, id) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === id) {
          return { ...piano, [name]: val };
        }
        return piano;
      }),
    }));
  }, []);

  const handleAllegatoChange = useCallback((val, id) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === id) {
          return { ...piano, allegato: val };
        }
        return piano;
      }),
    }));
  }, []);

  const handleUpdateScaffaleNome = useCallback((val, id, pianoId) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: piano.scaffali.map((scaffale) => {
              if (scaffale.id === id) {
                return { ...scaffale, nome: val };
              }
              return scaffale;
            }),
          };
        }
        return piano;
      }),
    }));
  }, []);

  const handleScaffaleAllegatoChange = useCallback((val, id, pianoId) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: piano.scaffali.map((scaffale) => {
              if (scaffale.id === id) {
                return { ...scaffale, allegato: val };
              }
              return scaffale;
            }),
          };
        }
        return piano;
      }),
    }));
  }, []);

  const handleAddScaffle = useCallback((pianoId) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: [
              ...piano.scaffali,
              { nome: "", id: uuidv4(), allegato: null },
            ],
          };
        }
        return piano;
      }),
    }));
  }, []);

  const handleRemoveScaffale = useCallback((id, pianoId) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: piano.scaffali.filter((scaffale) => scaffale.id !== id),
          };
        }
        return piano;
      }),
    }));
  }, []);

  const handleSave = useCallback(async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    navigate(-1);

    try {
      const submitData = {
        ...formData,
        piani: formData.piani.map((piano) => {
          return {
            ...piano,
            allegatoId: piano.allegato?.id,
            scaffali: piano.scaffali.map((scaffale) => {
              return {
                ...scaffale,
                allegatoId: scaffale.allegato?.id,
              };
            }),
          };
        }),
      };
      await saveDeposito(submitData);
      toast.success("Data updated successfully");
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while saving the data");
    }
  }, [formData, issues, navigate]);

  const fetchDepositoById = useCallback(async (id) => {
    try {
      const response = await getDeposito(id);
      const fetchedData = {
        ...response?.deposito,
        comuneId: response?.deposito?.comune?.id,
        comune: {
          value: response?.deposito?.comune?.id,
          label: response?.deposito?.comune?.nome,
        },
        piani: response?.deposito?.piani.map((piano) => {
          return {
            ...piano,
            allegato: piano.allegato
              ? {
                  id: piano.allegato.id,
                  name: removeIdFromName(
                    piano.allegato.name,
                    piano.allegato.id
                  ),
                }
              : null,
            scaffali: piano.scaffali.map((scaffale) => {
              return {
                ...scaffale,
                allegato: scaffale.allegato
                  ? {
                      id: scaffale.allegato.id,
                      name: removeIdFromName(
                        scaffale.allegato.name,
                        scaffale.allegato.id
                      ),
                    }
                  : null,
              };
            }),
          };
        }),
      };
      setFormData(fetchedData);
    } catch (error) {
      console.log("fetch-deposito-by-id-->>", error);
      toast.error("Data fetch failed!");
    }
  }, []);

  const handleFormDataChange = (key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const handleSelectChange = useCallback((name, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
        [`${name}Id`]: value?.value,
      };
    });
  }, []);

  const subHeaderRightNavOptions = {
    icons: disabled
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {
              navigate(-1);
            },
            label: "Back",
          },
          userHasPermission({
            userProfile,
            resource: "DEPOSITO",
            resourceGroups: formData?.groups,
            permission: "WRITE",
          }) && {
            icon: <EditIcon fillColor="#E15615" />,
            onClick: () => {
              navigate("/deposito/edit");
            },
            label: "Edita",
          },
        ]
      : [],
    components: disabled
      ? []
      : [
          <button onClick={() => navigate(-1)} className="cancel-button">
            <CrossIcon />
            <span className="login-text">Cancel</span>
          </button>,
          <button onClick={handleSave} className="save-button">
            <AddIcon fillColor="#fff" />
            <span className="login-text">Salva</span>
          </button>,
        ],
  };

  useEffect(() => {
    if (depositoId) {
      fetchDepositoById(depositoId);
    }
  }, [depositoId, fetchDepositoById]);

  return (
    <div className="container">
      <main className="record-detail-main-content">
        <DataTableSubHeader
          title={"DEPOSITO Record Details"}
          breadcrumbsData={depositoRecordDetailsBreadcrumbs}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
        <DepositoDetailForm
          showIssues={showIissues}
          issues={issues}
          formData={formData}
          comuniData={comuniData}
          disabled={disabled}
          datastructure={datastructure}
          handleFormDataChange={handleFormDataChange}
          handleUpdatePiano={handleUpdatePiano}
          handleAddPiano={handleAddPiano}
          handleAddScaffle={handleAddScaffle}
          handleRemoveScaffale={handleRemoveScaffale}
          handleRemovePiano={handleRemovePiano}
          handleUpdateScaffaleNome={handleUpdateScaffaleNome}
          handleAllegatoChange={handleAllegatoChange}
          handleScaffaleAllegatoChange={handleScaffaleAllegatoChange}
          handleSelectChange={handleSelectChange}
        />
      </main>
    </div>
  );
};

export default EditDeposito;
