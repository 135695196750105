import { useState, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";
import InnerSpinnerLoading from "../../../../Common/InnerSpinnerLoading/InnerSpinnerLoading";
import "./BibliografiaModalTable.scss";

const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "#f4f4f4",
    },
  },
};

const BibliografiaModalTable = ({
  data,
  totalRows,
  isLoading,
  selectedRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
  handleRowSelected,
}) => {
  const columns = [
    {
      name: "Titolo",
      selector: (row) => row.titolo,
      sortable: true,
    },
    {
      name: "Abbreviazione",
      selector: (row) => row.abbreviazione,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: true,
    },
  ];

  const rowSelectCritera = useCallback(
    (row) => {
      let idx = selectedRows?.findIndex((item) => item.id === row.id);

      return idx >= 0 ? true : false;
    },
    [selectedRows]
  );

  return (
    <div className="data-table-container">
      <DataTable
        columns={columns}
        data={data}
        progressPending={isLoading}
        progressComponent={<InnerSpinnerLoading isLoading={isLoading} />}
        customStyles={customStyles}
        selectableRows
        pagination
        paginationServer
        selectableRowSelected={rowSelectCritera}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
        onSelectedRowsChange={handleRowSelected}
      />
    </div>
  );
};

export default BibliografiaModalTable;
