import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext } from "../../../Components/Context/AppContext";
import {
  AddIcon,
  CrossIcon,
  RightArrowIcon,
  EditIcon,
} from "../../../assets/icons";
import {
  getMovimentazioneById,
  getMovimentazioneEvent,
  updateMovimentaznione,
  uploadFile,
} from "../../../api";
import MovimentazioneDetailForm from "../../../Components/MovimentazioneDetailForm/MovimentazioneDtailForm";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader";
import { validateUpdateMovimentazione } from "../../../helpers/formTools/validations/movimentazione";
import datastructure from "../../../constants/dataStructure/movimentazione.json";
import { removeIdFromName } from "../../../helpers/utils/utils";
import userHasPermission from "../../../helpers/formTools/userHasPermission";
import useFetchData from "../../../hooks/useFetchData";
import "./EditMovimentazione.scss";

const movimentazioneRecordDetailsBreadcrumbs = [
  {
    id: 1,
    label: "Dati Archeoligici",
    iconUrl: true,
  },
  {
    id: 2,
    label: "MOVIMENTAZIONE",
    iconUrl: true,
  },
  { id: 3, label: "Dettaglio movimentazione", iconUrl: "" },
];

const EditMovimentazione = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { movimentazioneId, userProfile } = useAppContext();
  const [formData, setFormData] = useState({});
  const [showIissues, setShowIssues] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);

  const disabled = useMemo(
    () => pathname !== "/movimentazione/edit",
    [pathname]
  );
  const issues = useMemo(
    () => validateUpdateMovimentazione(formData),
    [formData]
  );
  const eventFilter = useMemo(
    () => [{ key: "movimentazione.id", value: movimentazioneId }],
    [movimentazioneId]
  );
  const {
    data: movimentazioneEvents,
    isLoading,
    totalRecords,
    handleCallbackPerPageChange,
    handlePageChange,
    handleSort,
    fetchData,
  } = useFetchData(getMovimentazioneEvent, eventFilter);

  const handleSave = useCallback(async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    navigate(-1);

    try {
      const submitData = {
        ...formData,
        allegati: formData?.allegati?.map((item) => item.id),
        autorizzazioneId: formData?.autorizzazione?.id,
      };
      await updateMovimentaznione(submitData);
      toast.success("Data updated successfully");
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while saving the data");
    }
  }, [formData, issues, navigate]);

  const handleEventSubmit = useCallback(async (eventType) => {
    fetchData();
    try {
      const submitData = {
        ...formData,
        termineProvisionale: eventType === "uscita",
        allegati: formData?.allegati?.map((item) => item.id),
        autorizzazioneId: formData?.autorizzazione?.id,
      };
      await updateMovimentaznione(submitData);
    } catch(err) {
      console.log('event-submit-->>', err)
    }
  }, [fetchData, formData])

  const handleAuthUpload = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async function () {
      try {
        const uploadedFileId = await uploadFile(file);
        setFormData((prevData) => ({
          ...prevData,
          autorizzazione: { id: uploadedFileId, name: file.name },
        }));
      } catch (error) {
        console.error("Error uploading file.", error);
        toast.error("Error uploading file.");
      }
    };
  };

  const fetchMovimentazioneById = useCallback(async (id) => {
    try {
      const response = await getMovimentazioneById(id);
      const fetchedData = {
        ...response?.movimentazione,
        allegati: response?.movimentazione?.allegati?.map((item) => {
          return {
            id: item.id,
            name: removeIdFromName(item.name, item.id),
          };
        }),
        autorizzazione: {
          id: response?.movimentazione?.autorizzazione?.id,
          name: removeIdFromName(
            response?.movimentazione?.autorizzazione?.name,
            response?.movimentazione?.autorizzazione?.id
          ),
        },
      };
      setFormData(fetchedData);
      // console.log("fetch-movimentazione-by-id-->>", fetchedData);
    } catch (error) {
      console.log("fetch-movimentazione-err-by-id-->>", error);
      toast.error("Data fetch failed!");
    }
  }, []);

  const handleFormDataChange = (key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const handleReferentiChange = (key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      referenti: prevData.referenti.map((item) => {
        if (item.tipo === key) {
          return {
            ...item,
            valore: newValue,
          };
        }
        return item;
      }),
    }));
  };

  useEffect(() => {
    if (movimentazioneId) {
      fetchMovimentazioneById(movimentazioneId);
    }
  }, [movimentazioneId, fetchMovimentazioneById]);

  const subHeaderRightNavOptions = {
    icons: disabled
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {
              navigate(-1);
            },
            label: "Back",
          },
          userHasPermission({
            userProfile,
            resource: "MOVIMENTAZIONE",
            resourceGroups: formData?.groups,
            permission: "WRITE",
          }) && {
            icon: <EditIcon fillColor="#E15615" />,
            onClick: () => {
              navigate("/movimentazione/edit");
            },
            label: "Edita",
          },
        ]
      : [],
    components: disabled
      ? []
      : [
          <button onClick={() => navigate(-1)} className="cancel-button">
            <CrossIcon />
            <span className="login-text">Cancel</span>
          </button>,
          <button
            onClick={() => {
              // navigate(-1);
              handleSave();
            }}
            className="save-button"
          >
            <AddIcon fillColor="#fff" />
            <span className="login-text">Salva</span>
          </button>,
        ],
  };

  return (
    <div className="container">
      <main className="record-detail-main-content">
        <DataTableSubHeader
          title={"Dettagli movimentazione"}
          breadcrumbsData={movimentazioneRecordDetailsBreadcrumbs}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
        <MovimentazioneDetailForm
          showIssues={showIissues}
          issues={issues}
          disabled={disabled}
          formData={formData}
          totalRows={totalRecords}
          isLoading={isLoading}
          movimentazioneEvents={movimentazioneEvents}
          datastructure={datastructure}
          handleFormDataChange={handleFormDataChange}
          handleReferentiChange={handleReferentiChange}
          handleAuthUpload={handleAuthUpload}
          handleEventSubmit={handleEventSubmit}
          handlePerRowsChange={handleCallbackPerPageChange}
          handlePageChange={handlePageChange}
          handleSort={handleSort}
        />
      </main>
    </div>
  );
};

export default EditMovimentazione;
