import React, { useCallback, useMemo } from "react";
import { ExclamationTriangleIcon, AddIcon } from "../../../assets/icons";
import FormField from "../../FormField/FormField";
import GroupsFormField from "../../GroupsFormField/GroupsFormField";
import Posizione from "../Posizione/Posizione";
import cassettaStructure from "../../../constants/dataStructure/cassetta.json";
import Raccolta from "../Raccolta/Raccolta";
import Tabs from "../../Common/Tabs/Tabs";
import { v4 as uuidv4 } from "uuid";

function CassetteRecordDetailForm({
  data,
  handleMapView,
  handleResourceView,
  depositiData,
  raccolteData = [],
  numberOfReperti,
  setData,
  setRaccolteData,
  disabled,
  jumpToReperti,
  issues,
  showIssues,
}) {
  const handleFieldChange = (key, newValue) => {
    setData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const handleAnnotazioniChange = (key, newValue) => {
    const newAnnotazioni = data.annotazioni.map((a) => {
      if (a.chiave === key) return { ...a, valore: newValue };
      return a;
    });
    setData((prevData) => ({
      ...prevData,
      annotazioni: newAnnotazioni,
    }));
  };

  const handleRaccoltaChange = useCallback(
    (id, key, newValue) => {
      console.log("handleRaccoltaChange", id, key, newValue);
      const newRaccolteData = raccolteData.map((r, i) => {
        if (r.id === id) return { ...r, [key]: newValue };
        return r;
      });
      setRaccolteData(newRaccolteData);
    },
    [raccolteData, setRaccolteData]
  );

  const handleAddRaccolta = useCallback(() => {
    setRaccolteData((prevData) => [
      ...prevData,
      { id: uuidv4() },
    ]);
  }, [setRaccolteData]);

  const handleDeleteRaccolta = useCallback(
    (raccolta) => {
      let newRaccolteData = raccolteData.filter((r) => {
        return raccolta.id !== r.id;
      });
      setRaccolteData(newRaccolteData);
    },
    [raccolteData, setRaccolteData]
  );

  const tabs = useMemo(() => {
    return raccolteData.map((raccolta, index) => ({
      id: raccolta.id,
      label: raccolta.nome || `Tab${index + 1}`,
      content: (
        <Raccolta
          key={raccolta.id}
          raccolta={raccolta}
          handleFieldChange={(key, newValue) =>
            handleRaccoltaChange(raccolta.id, key, newValue)
          }
          handleDeleteRaccolta={() => handleDeleteRaccolta(raccolta)}
          edit={!disabled}
          numberOfReperti={numberOfReperti[raccolta.id]}
          jumpToReperti={() => jumpToReperti(raccolta.id)}
        />
      ),
    }));
  }, [
    raccolteData,
    numberOfReperti,
    disabled,
    jumpToReperti,
    handleDeleteRaccolta,
    handleRaccoltaChange,
  ]);

  if (data && Object.keys(data).length > 0) {
    return (
      <>
        <main className="cassette-main-container">
          <div className="content-wrapper">
            <div className="issue-container">
              {!disabled &&
                showIssues &&
                issues.map((issue, index) => {
                  return (
                    <div key={index} className="issue">
                      <p className="issue-icon">
                        <ExclamationTriangleIcon />
                      </p>
                      {issue}
                    </div>
                  );
                })}
            </div>
            <div className="blocks-container">
              <div className="form-block" id="generalità">
                <p className="section-title"> GENERALITÀ</p>
                <FormField
                  fieldStructure={cassettaStructure["id"]}
                  value={data["id"]}
                  onChange={(newValue) => handleFieldChange("id", newValue)}
                  disabled={true}
                />
                <GroupsFormField
                  groups={data["groups"]}
                  onChange={(newValue) => handleFieldChange("groups", newValue)}
                  edit={!disabled}
                />
                <FormField
                  fieldStructure={cassettaStructure["frontalino"]}
                  value={data["frontalino"]}
                  onChange={(newValue) =>
                    handleFieldChange("frontalino", newValue)
                  }
                  disabled={disabled}
                />
              </div>

              <div className="form-block" id="posizione">
                <Posizione
                  formData={data}
                  depositiData={depositiData}
                  disabled={disabled}
                  cassettaStructure={cassettaStructure}
                  handleFormDataChange={handleFieldChange}
                  handleResourceView={handleResourceView}
                  handleMapView={handleMapView}
                />
              </div>

              <div className="form-block" id="annotations">
                <p className="section-title"> ANNOTAZIONI</p>
                {data?.annotazioni?.length ? (
                  data?.annotazioni?.map((annotation, index) => (
                    <div key={index} className="annotation">
                      <FormField
                        fieldStructure={{
                          label: annotation?.chiave,
                          type: "text",
                          name: "index",
                        }}
                        value={annotation?.valore}
                        onChange={(newValue) => {
                          handleAnnotazioniChange(annotation?.chiave, newValue);
                        }}
                        disabled={disabled}
                      />
                    </div>
                  ))
                ) : (
                  <p>Non ci sono annotazioni.</p>
                )}
              </div>

              <div className="form-block" id="raccolte">
                <div className="form-block-header">
                  <p className="form-block-header-title"> SCOMPARTI</p>
                  {!disabled && <button
                    className="form-block-button"
                    onClick={handleAddRaccolta}
                  >
                    <AddIcon fillColor="white" />
                    <span>Aggiungi scomparto</span>
                  </button>}
                </div>
                <Tabs tabs={tabs} />
              </div>
            </div>
            <div className="description-column"></div>
          </div>
          {/* <CassetteRecordDetailImages disabled={disabled} /> */}
        </main>
      </>
    );
  }
}

export default CassetteRecordDetailForm;
