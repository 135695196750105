import { useState, useRef, useEffect } from "react";
import "./Accordion.scss";
import { DropdownIcon } from "../../assets/icons/dropdown";
import useOnOutsideClick from "../../hooks/useOutsideClick";
import { EyeIcon as EyeOpenIcon } from "../../assets/icons/eye";
import { EyeShutIcon } from "../../assets/icons/eyeShutIcon";
import { CheckedboxIcon } from "../../assets/icons";

export const Accordion = (props) => {
  const {
    className,
    title,
    mainTitle,
    isChecked,
    isVisible,
    children,
    closeOnOutsideClick = false,
    disabled = false,
    defaultOpen = false,
    childrenClassName = "",
    isOpenable = true,
    isCheckbox = false,
    hasEyes = false,
    handleCallBackVisibility,
    handleCallBackCheck = () => {},
    handleCallBackLayerTitle,
    handleCallBackTitle,
    showBackground,
    setShowBackground,
    layerTitle,
  } = props;
  const [isAccordionOpen, setAccordionOpen] = useState(defaultOpen);
  const [isEyeOpen, setEyeOpen] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const accordionRef = useRef(null);

  /*
  useEffect(() => {
    setEyesOpen(showBackground);
  }, [showBackground]);
*/


  const handleClick = (e) => {
    if (!disabled) setAccordionOpen(!isAccordionOpen);
    e.stopPropagation();
  };

  const handleOnEyeClick = (e) => {


    e.stopPropagation();
    handleCallBackVisibility({ title: title, isVisible: !isEyeOpen });
    setEyeOpen(!isEyeOpen);

    //eyeClickHandler(!isEyeOpen);
    //if (setShowBackground) {
      //setShowBackground(!isEyeOpen);
    //}
    // if (setShowBackground && layerTitle === title){setShowBackground(!isEyeOpen);}
  };


  const handleOnCheckClick = (e) => {
    // console.log("handleOnCheckClick", title, isEyeOpen, isChecked);
    e.stopPropagation();
    handleCallBackCheck({ title: title, isChecked: !isChecked });
  };


  handleCallBackLayerTitle({ layerTitle: layerTitle });

  const handleOnSelect = (e) => {
    const newIsSelected = !isSelected;
    handleCallBackTitle({ title: title, isSelected: newIsSelected });
    setIsSelected(newIsSelected);
  };
  useEffect(() => {
    setIsSelected(mainTitle === title);
  }, [mainTitle, title]);

  useOnOutsideClick(accordionRef, () =>
    closeOnOutsideClick ? setAccordionOpen(false) : {}
  );




  return (
    <div className={`accordion-main-container ${className}`} ref={accordionRef}>
      <div
        className={`accordion-header ${disabled && "disable-accordion"} ${
          isEyeOpen && "active-eye-icon"
        }`}
        onClick={handleClick}
      >
        <div className="accordion-title-and-dropdown-wrapper">
          <span
            className={`default-icon drop-down-icon ${
              isAccordionOpen && "arrow-icon-rotate"
            }`}
          >
            {isOpenable && children && <DropdownIcon />}
          </span>
          <div
            onClick={handleOnSelect}
            className={
              mainTitle === title && isSelected ? "highlight-title" : ""
            }
          >
            {title}
          </div>
        </div>
        { isCheckbox && (
          <span className="default-icon" onClick={handleOnCheckClick}>
            <CheckedboxIcon isChecked={isChecked} />
          </span>
        )}
        { hasEyes && (
          <span className="default-icon" onClick={handleOnEyeClick}>
            { isVisible === true ? (
              <EyeOpenIcon />
            ) : (
              <EyeShutIcon />
            ) }
          </span>
        )}
      </div>
      {isAccordionOpen && children && isOpenable && (
        <div
          className={`accordion-children-wrapper ${
            isEyeOpen && "active-eye-icon"
          } ${childrenClassName}`}
        >
          {children}
        </div>
      )}
    </div>
  );
};
