import CustomModal from "../CustomModal";
import { ExclamationTriangleIcon } from "../../assets/icons";
import "./style.css";

const CustomWarningModal = ({ isOpen, text, onClose }) => {
  return (
    <CustomModal isVisible={isOpen} onClose={onClose}>
      <h2 className="warning-modal-title">
        <p className="warning-modal-icon">
          <ExclamationTriangleIcon className="size-6" />
        </p>
        Warning
      </h2>
      <div>{text}</div>
      {/* <div className="warning-modal-footer">
        <button className="warning-modal-button" onClick={onClose}>
          OK
        </button>
      </div> */}
    </CustomModal>
  );
};

export default CustomWarningModal;
