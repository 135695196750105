import React, { useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext } from "../../../Components/Context/AppContext";
import { saveDeposito, fetchComuni } from "../../../api";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader";
import validateDeposito from "../../../helpers/formTools/validations/deposito/validateDeposito";
import { useCheckRole } from "../../../hooks/useCheckRole";
import datastructure from "../../../constants/dataStructure/deposito.json";
import DepositoDetailForm from "../../../Components/Deposito/DepositoDetailForm/DepositoDetailForm";
import useAddSubHeaderRightNavOptions from "../../../hooks/useAddSubHeaderRightNavOptions";
import { v4 as uuidv4 } from "uuid";
import useFetchData from "../../../hooks/useFetchData";

const archivioRecordDetailsBreadcrumbs = [
  {
    id: 1,
    label: "Dati Archeoligici",
    iconUrl: true,
  },
  {
    id: 2,
    label: "DEPOSITO",
    iconUrl: true,
  },
  { id: 3, label: "Aggiungi nuovo deposito", iconUrl: "" },
];

const AddDeposito = () => {
  const navigate = useNavigate();
  const { setDepositoFilter } = useAppContext();
  const [isMenuActive, setMenuActive] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    indirizzo: "",
    piani: [],
  });
  const [showIissues, setShowIssues] = useState(false);
  const { data: comuniData } = useFetchData(fetchComuni);

  const issues = useMemo(() => validateDeposito(formData), [formData]);

  useCheckRole("DEPOSITO_WRITE");

  const newDepositoRecord = useCallback(async (data) => {
    try {
      await saveDeposito(data);
      toast.success("Data updated successfully!");
    } catch (error) {
      toast.error("Something went wrong, please try again!");
    }
  }, []);

  const handleFormDataChange = useCallback(
    (key, value) => {
      setFormData((prev) => ({ ...prev, [key]: value }));
    },
    [setFormData]
  );

  const handleSelectChange = useCallback((name, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
        [`${name}Id`]: value?.value,
      };
    });
  }, []);

  const handleAddPiano = useCallback(() => {
    setFormData((prev) => {
      return {
        ...prev,
        piani: [
          ...prev.piani,
          {
            nome: "",
            id: uuidv4(),
            allegato: null,
            scaffali: [],
          },
        ],
      };
    });
  }, []);

  const handleRemovePiano = useCallback((id) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.filter((piano) => piano.id !== id),
    }));
  }, []);

  const handleUpdatePiano = useCallback((val, name, id) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === id) {
          return { ...piano, [name]: val };
        }
        return piano;
      }),
    }));
  }, []);

  const handleAllegatoChange = useCallback((val, id) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === id) {
          return { ...piano, allegato: val };
        }
        return piano;
      }),
    }));
  }, []);

  const handleUpdateScaffaleNome = useCallback((val, id, pianoId) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: piano.scaffali.map((scaffale) => {
              if (scaffale.id === id) {
                return { ...scaffale, nome: val };
              }
              return scaffale;
            }),
          };
        }
        return piano;
      }),
    }));
  }, []);

  const handleScaffaleAllegatoChange = useCallback((val, id, pianoId) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: piano.scaffali.map((scaffale) => {
              if (scaffale.id === id) {
                return { ...scaffale, allegato: val };
              }
              return scaffale;
            }),
          };
        }
        return piano;
      }),
    }));
  }, []);

  const handleAddScaffle = useCallback((pianoId) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: [
              ...piano.scaffali,
              { nome: "", id: uuidv4(), allegato: null },
            ],
          };
        }
        return piano;
      }),
    }));
  }, []);

  const handleRemoveScaffale = useCallback((id, pianoId) => {
    setFormData((prev) => ({
      ...prev,
      piani: prev.piani.map((piano) => {
        if (piano.id === pianoId) {
          return {
            ...piano,
            scaffali: piano.scaffali.filter((scaffale) => scaffale.id !== id),
          };
        }
        return piano;
      }),
    }));
  }, []);

  const handleSave = useCallback(async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    navigate(-1);
    const submitData = {
      ...formData,
      piani: formData.piani.map((piano) => {
        return {
          ...piano,
          allegatoId: piano.allegato?.id,
          scaffali: piano.scaffali.map((scaffale) => {
            return {
              ...scaffale,
              allegatoId: scaffale.allegato?.id,
            };
          }),
        };
      }),
    };
    await newDepositoRecord(submitData);
    setDepositoFilter([]);
  }, [formData, issues, setDepositoFilter, navigate, newDepositoRecord]);

  const { subHeaderRightNavOptions } =
    useAddSubHeaderRightNavOptions(handleSave);

  return (
    <div className="container">
      <main className="record-detail-main-content">
        <DataTableSubHeader
          title={"Dettagli deposito"}
          breadcrumbsData={archivioRecordDetailsBreadcrumbs}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
        <DepositoDetailForm
          showIssues={showIissues}
          issues={issues}
          formData={formData}
          comuniData={comuniData}
          datastructure={datastructure}
          handleFormDataChange={handleFormDataChange}
          handleUpdatePiano={handleUpdatePiano}
          handleAddPiano={handleAddPiano}
          handleAddScaffle={handleAddScaffle}
          handleRemoveScaffale={handleRemoveScaffale}
          handleRemovePiano={handleRemovePiano}
          handleUpdateScaffaleNome={handleUpdateScaffaleNome}
          handleAllegatoChange={handleAllegatoChange}
          handleScaffaleAllegatoChange={handleScaffaleAllegatoChange}
          handleSelectChange={handleSelectChange}
        />
      </main>
    </div>
  );
};

export default AddDeposito;
