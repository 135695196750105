import React, { useState, useMemo, useCallback } from "react";
import "./Pubblicazioni.scss";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../Components/Context/AppContext.jsx";
import { toast } from "react-toastify";
import DataTableSubHeader from "../../Components/DataTableSubHeader/DataTableSubHeader.jsx";
import ItemsTable from "../../Components/ItemsTable/ItemsTable.jsx";
import { SubHeaderMobileOption } from "../../Components/SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { EyeIcon, DeleteIcon } from "../../assets/icons/index.js";
import { subHeaderRightNavOptions } from "../../Components/Pubblicazioni/subHeaderOptions.js";
import datastructure from "../../constants/dataStructure/biblografia.json";
import { deleteBibliografia } from "../../api/bibliografia.js";
import Spinner from "../../Components/ProgressBar/Spinner.js";
import userHasPermission from "../../helpers/formTools/userHasPermission/index.js";
import ReactModal from "../../Components/ReactModal/ReactModal.jsx";
import FilterModal from "../../Components/FilterModal/FilterModal.jsx";
import useFetchData from "../../hooks/useFetchData.js";
import { getBibliografia } from "../../api/bibliografia.js";

// Breadcrumbs data for navigation
const breadcrumbsData = [
  { id: 1, label: "Dati Archeologici", iconUrl: true },
  { id: 2, label: "Pubblicazioni", iconUrl: true },
  { id: 3, label: "Elenco pubblicazioni", iconUrl: "" },
];

const headers = [
  { key: "actions", label: "", width: "100px", sortable: false },
  { key: "id", label: "Identificativo", width: "180px", sortable: false },
  { key: "titolo", label: "Titolo", width: "100px", sortable: false },
  { key: "fonte", label: "Fonte", width: "100px", sortable: false },
  {
    key: "annoPubblicazione",
    label: "Anno di pubblicazione",
    width: "300px",
    sortable: false,
  },
  {
    key: "abbreviazione",
    label: "Abbreviazione",
    width: "100px",
    sortable: false,
  },
];

const filterValues = [
  { label: "id", key: "id" },
  { label: "titolo", key: "titolo" },
  { label: "fonte", key: "fonte" },
  { label: "piano", key: "piano" },
  { label: "annoPubblicazione", key: "annoPubblicazione" },
  { label: "abbreviazione", key: "abbreviazione" },
];

function Pubblicazioni() {
  const navigate = useNavigate();
  const {
    setBibliografiaFilter,
    bibliografiaFilter,
    setBibliografiaId,
    userProfile,
  } = useAppContext();

  const [recordFilterModalOpen, setRecordFilterModalOpen] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);
  const [filterValue, setFilterValue] = useState({});
  const userCanCreate = userHasPermission({
    userProfile,
    resource: "BIBLIOGRAFIA",
    permission: "CREATE",
  });

  const customFilter = useMemo(
    () => [...bibliografiaFilter],
    [bibliografiaFilter]
  );

  const {
    data,
    isLoading,
    currentPage,
    recordsPerPage,
    totalRecords,
    totalPages,
    sortDirection,
    setCurrentPage,
    setRecordsPerPage,
    setSortDirection,
  } = useFetchData(getBibliografia, customFilter);

  // Toggle menu active state
  const toggleMenuActive = () => setMenuActive(!isMenuActive);

  const removeBibliografia = async (id) => {
    try {
      await deleteBibliografia(id);
      toast.success("Data deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 20);
    } catch (error) {
      console.log("remove-biblografia-->>", error);
      toast.error("Data deletion failed!");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRecordsPerPageChange = (newRecordsPerPage) => {
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1); // Reset to first page when records per page changes
  };

  async function handleCallBackSort(key) {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    setBibliografiaFilter([{ key: "sort", value: `${key},${sortDirection}` }]);
  }

  const closeRecordFilterModal = useCallback(() => {
    setRecordFilterModalOpen(false);
  }, []);

  const openRecordFilterModal = useCallback(() => {
    setRecordFilterModalOpen(true);
  }, []);

  const handleCallBackFilter = useCallback(
    (data) => {
      const filter = data.map((item) => {
        return { key: item.label, value: item.value };
      });
      console.log("==| handleCallBackFilter", filter);

      setBibliografiaFilter(filter);
      return true;
    },
    [setBibliografiaFilter]
  );

  const handleReset = (resetStatus) => {
    // setIsReset(resetStatus);
    // if (resetStatus) {
    //   // Perform additional actions if needed when reset is true
    //   filterRef.current = false;
    // }
  };

  const convertedData = useMemo(() => {
    if (!Array.isArray(data)) return [];

    let mappedData = []; // Initialize the array to store the converted data

    data.forEach((result) => {
      let jsonObject = { id: { value: result.id }, actions: { value: [] } }; // Initialize the row object

      // Convert row attributes in the right format base on a key and a value to be displayed
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
          let value;
          if (datastructure[key] && datastructure[key].type === "object") {
            value = result[key][datastructure[key].objectName];
          } else {
            value =
              result[key] !== undefined && result[key] !== null
                ? result[key].toString()
                : "";
          }
          jsonObject[key] = {
            value,
          };
        }
      }

      // Identifies the ID of the record
      const Id = jsonObject.id.value.replace(/^"|"$/g, "");

      // Add the actions to the row
      jsonObject.actions = {
        value: [],
        component: (
          <>
            <div
              onClick={() => {
                navigate("/pubblicazioni/view");
                setBibliografiaId(Id);
              }}
              className="row-icon"
              style={{ cursor: "pointer" }}
            >
              <EyeIcon />
            </div>
            {userHasPermission({
              userProfile,
              resource: "BIBLIOGRAFIA",
              resourceGroups: result.groups,
              permission: "DELETE",
            }) && (
              <div
                onClick={() => removeBibliografia(Id)}
                style={{ cursor: "pointer" }}
              >
                <DeleteIcon />
              </div>
            )}
          </>
        ),
      };
      mappedData.push(jsonObject);
    });

    return mappedData;
  }, [data, userProfile, setBibliografiaId, navigate]);

  return (
    <div className="container">
      <main className="record-main-content">
        <DataTableSubHeader
          title="PUBBLICAZIONI"
          breadcrumbsData={breadcrumbsData}
          subHeaderRightNavOptions={subHeaderRightNavOptions(
            userCanCreate,
            convertedData,
            navigate,
            openRecordFilterModal
          )}
          isMenuActive={isMenuActive}
          setMenuActive={toggleMenuActive}
        />
        <ReactModal
          isOpen={recordFilterModalOpen}
          onClose={closeRecordFilterModal}
        >
          <FilterModal
            closeModal={closeRecordFilterModal}
            filterValues={filterValues}
            setFilterValue={setFilterValue}
            callBackFilter={handleCallBackFilter}
            onReset={handleReset}
          />
        </ReactModal>
        {isLoading ? (
          <Spinner />
        ) : (
          <ItemsTable
            headers={headers}
            data={convertedData}
            filterValues={filterValues}
            totalRecords={totalRecords}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
            callBackSort={handleCallBackSort}
          />
        )}
      </main>
      {isMenuActive && (
        <SubHeaderMobileOption
          components={
            subHeaderRightNavOptions(
              userCanCreate,
              convertedData,
              navigate,
              openRecordFilterModal
            ).components
          }
          icons={
            subHeaderRightNavOptions(userCanCreate, convertedData, navigate)
              .icons
          }
          onCrossClick={() => setMenuActive(false)}
          title="Options"
        />
      )}
    </div>
  );
}

export default Pubblicazioni;
