import { DeleteIcon, EditIcon } from "../../../../assets/icons";
import CustomTooltip from "../../../CustomTooltip";

import "./style.css";

const ClassSummaryTable = ({
  data = [],
  edit = false,
  handleEditItem,
  handleDeleteItem
}) => {
  return (
    <div>
      <div className="class-summary-table-container">
        <div className="class-summary-table">
          <table>
            <thead>
              <tr>
                <th>Classe</th>
                <th>Quantità</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <tr key={index}>
                  <td>{row.class}</td>
                  <td>{row.quantity}</td>
                  <td className={`${edit ? "" : "hidden"} table-action`}>
                    <CustomTooltip text="Modifica">
                      <button className="class-button" onClick={() => handleEditItem(index)}>
                        <EditIcon fillColor="#e15615"  />
                      </button>
                    </CustomTooltip>
                    <CustomTooltip text="Elimina">
                       <button className="class-button" onClick={() => handleDeleteItem(index)}>
                        <DeleteIcon fillColor="#e15615"  />
                      </button>
                    </CustomTooltip>
                  </td>
                </tr>
              ))}
              <tr>
                <td>Totale</td>
                <td>
                  {data?.reduce((acc, item) => acc + Number(item.quantity), 0)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClassSummaryTable;
