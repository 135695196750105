import React, { useState, Fragment, useCallback } from "react";
import "./FileUploadComponent.scss";
import UploadPdfModal from "../UploadPdfModal/UploadPdfModal";
import { toast } from "react-toastify";
import { uploadFile } from "../../../api";
import { CloudUploadIcon, CrossIcon, ImageIcon } from "../../../assets/icons";
import PdfImg from "../../../assets/images/pdf_icon.svg";
import SpinnerLoading from "../../SpinnerLoading/SpinnerLoading";
import { useBrowseFile } from "../../../hooks/useBrowseFile";
import { getFileExtension } from "../../../helpers/utils/utils";

const FileUploadComponent = ({
  disabled,
  fileData = [],
  acceptType = ".pdf",
  onChange,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const { handleBrowseFile, isFileDownloading } = useBrowseFile();

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleUpload = useCallback(
    async (ev) => {
      const files = ev.target.files;
      closeModal();
      setIsFileUploading(true);

      if (files.length) {
        Array.from(files).forEach(async (file) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async function () {
            try {
              const uploadedFileId = await uploadFile(file);

              onChange([...fileData, { id: uploadedFileId, name: file.name }]);
            } catch (error) {
              console.error("Error uploading file.", error);
              toast.error("Error uploading file.");
            } finally {
              setIsFileUploading(false);
            }
          };
        });
      }
    },
    [fileData, onChange, closeModal]
  );

  const handleRemoveFile = useCallback(
    (fileId) => {
      onChange(fileData.filter((file) => file.id !== fileId));
    },
    [fileData, onChange]
  );

  return (
    <Fragment>
      <div className="file-upload-container">
        <div className="form-block-header">
          <p className="upload-pdf-header-title">Allegati</p>
          <button
            className="upload-biblografia-btn"
            disabled={disabled}
            onClick={openModal}
          >
            <CloudUploadIcon width={16} height={16} />
            <span>Carica Files</span>
          </button>
        </div>
        <div>
          {(isFileUploading || isFileDownloading) ? (
            <SpinnerLoading isLoading={isFileUploading} />
          ) : null}
          <div className="preveiw-container">
            {fileData?.length
              ? fileData.map((file) => (
                  <div key={file?.id} className="biblografia-pdf-container">
                    <button
                      onClick={() =>
                        handleBrowseFile(file?.id, getFileExtension(file?.name))
                      }
                    >
                      {getFileExtension(file?.name) === "pdf" ? (
                        <img src={PdfImg} alt="pdf" />
                      ) : (
                        <ImageIcon width={"60px"} height={"60px"} />
                      )}
                      <p>{file?.name}</p>
                    </button>
                    <span
                      className={`pdf-close-icon ${disabled ? "disabled" : ""}`}
                      onClick={() => handleRemoveFile(file?.id)}
                    >
                      <CrossIcon width={20} height={20} />
                    </span>
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      <UploadPdfModal
        title={"Upload Files"}
        acceptType={acceptType}
        showModal={showModal}
        handleUpload={handleUpload}
        closeModal={closeModal}
      />
    </Fragment>
  );
};

export default FileUploadComponent;
