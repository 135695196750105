import "./MovimentazioneEventModal.scss";

import React, { useState, useCallback } from "react";
import { CopyIcon } from "../../../../assets/icons";
import CustomModal from "../../../Common/CustomModal";
import FileUploadComponent from "../../../Common/FileUploadComponent/FileUploadComponent";
import { copyToClipboard } from "../../../../helpers/utils/utils";
import {
  newMovimentaznioneEntrata,
  newMovimentaznioneUscita,
} from "../../../../api";
import { toast } from "react-toastify";

const MovimentazioneEventModal = ({
  isModalOpen,
  movimentazioneId,
  eventType,
  disabled,
  closeModal,
  handleSumit,
}) => {
  const [formData, setFormData] = useState({
    allegati: [],
    id: movimentazioneId,
  });

  const handleFieldChange = useCallback(
    (key, value) => {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [key]: value,
      }));
    },
    [setFormData]
  );

  const onSubmit = useCallback(async () => {
    try {
      const submitData = {
        ...formData,
        allegati: formData.allegati.map((item) => item.id),
      };
      if (eventType === "uscita") {
        await newMovimentaznioneUscita(submitData);
        toast.success("Movimentazione Uscita added successfully");
      } else {
        await newMovimentaznioneEntrata(submitData);
        toast.success("Movimentazione Entrata added successfully");
      }
      handleSumit(eventType);
    } catch (error) {
      console.error("===| error: ", error);
      toast.error("Failed to add Movimentazione Event");
    } finally {
      closeModal();
    }
  }, [formData, eventType, closeModal, handleSumit]);

  return (
    <CustomModal
      isVisible={isModalOpen}
      onClose={closeModal}
      title={`${eventType === "entrata" ? "Entrata" : "Uscita"} Event`}
    >
      <div className="uscita-event-modal-container">
        <MovimentazioneIdHeader
          movimentazioneId={movimentazioneId}
          copyToClipboard={copyToClipboard}
        />
        <FileUploadComponent
          fileData={formData?.allegati}
          disabled={disabled}
          acceptType=".pdf, .jpg, .png"
          onChange={(newValue) => handleFieldChange("allegati", newValue)}
        />
      </div>
      <footer className="modal-footer">
        <button className="modal-footer-button" onClick={onSubmit}>
          Submit
        </button>
      </footer>
    </CustomModal>
  );
};

const MovimentazioneIdHeader = ({ movimentazioneId, copyToClipboard }) => (
  <div className="reperto-id-header">
    <p className="reperto-id-text">Movimentazione ID</p>
    <p className="copy-text" onClick={() => copyToClipboard(movimentazioneId)}>
      {movimentazioneId}
      <CopyIcon />
    </p>
  </div>
);

export default MovimentazioneEventModal;
