import React, { useState, useEffect } from "react";
import "../Cassette.scss";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AddIcon,
  CrossIcon,
  EditIcon,
  RightArrowIcon,
} from "../../../assets/icons";
import { SubHeaderMobileOption } from "../../../Components/SubHeaderMobileOption/SubHeaderMobileOption";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getCassetta,
  getReperti,
  updateCassetta,
  createRaccolta,
  updateRaccolta,
  deleteRaccolta,
  getDepositi,
} from "../../../api";
import validateCassetta from "../../../helpers/formTools/validations/cassetta/validateCassetta";
import validateRaccolte from "../../../helpers/formTools/validations/raccolte/validateRaccolte";
import userHasPermission from "../../../helpers/formTools/userHasPermission";
import { useAppContext } from "../../../Components/Context/AppContext";
import CustomModal from "../../../Components/CustomModal";
import useFetchData from "../../../hooks/useFetchData";
import { useBrowseFile } from "../../../hooks/useBrowseFile";
import CassetteRecordDetailForm from "../../../Components/Cassette/EditCassetteRecordDetailForm/CassetteRecordDetailForm";
import Spinner from "../../../Components/ProgressBar/Spinner";

function CassetteRecordDetailsTable() {
  const {
    cassettaId,
    setCassetteFilter,
    setMapViewTarget,
    setRepertiFilter,
    userProfile,
  } = useAppContext();
  const [isMenuActive, setMenuActive] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState([]);
  const [raccolteData, setRaccolteData] = useState([]);
  const [classListComponentKey, setClassListComponentKey] = useState(0);
  const [issues, setIssues] = useState([]);
  const [showIssues, setShowIssues] = useState(false);
  const [numberOfReperti, setNumberOfReperti] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { data: depositiData } = useFetchData(getDepositi);
  const { handleBrowseFile } = useBrowseFile();

  //let cassettaId = localStorage.getItem("cassettaId")

  const handleFormDataChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  // ****************************************************************************************************
  // ALL API CALLS

  const fetchCassetta = async (id) => {
    try {
      let data = await getCassetta(id);
      setFormData({ ...data });
      let raccolte = data.raccolte.map((r) => {
        let r2 = { ...r };
        r2.provenienze = r.provenienze.map((provenienza) => ({
          ...provenienza,
          comuneId: provenienza.comune.id,
        }));
        return r2;
      });
      setRaccolteData(raccolte);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error("Unauthorized. Redirecting you to the login page...");
      } else {
        console.error(`Error fetching "cassetta" record`, error);
      }
    }
  };

  const editCassetta = async (data) => {
    try {
      await updateCassetta(data);
      toast.success("Cassetta aggiornata con successo!");
    } catch (error) {
      if (error.respomse && error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error updating a "cassetta" record`);
      } else {
        toast.error(
          error.response && error.response.detail
            ? error.response.detail
            : `Unknown error updating a "cassetta" record`
        );
        console.error(
          `Authorization error updating a "cassetta" record`,
          error
        );
      }
    }
  };

  const editRaccolta = async (data) => {
    try {
      await updateRaccolta(data);
      // toast.success("Data updated successfully!");  // Reducing the number of toasts
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error updating a "raccolta" record`, data);
      } else {
        toast.error(
          error.response && error.response.data && error.response.data.detail
            ? error.response.data.detail
            : `Unknown error updating a "raccolta" record`
        );
        console.error(`Unknown error updating a "raccolta" record`, error);
      }
    }
  };

  const newRaccolta = async (data) => {
    try {
      await createRaccolta(data);
      // toast.success("Data updated successfully!");  // Reducing the number of toasts
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error saving a new "raccolta"`, data);
      } else {
        toast.error(
          error.response && error.response.data && error.response.data.detail
            ? error.response.data.detail
            : `Unknown error saving a new "raccolta"`
        );
        console.error(`Unknown error saving a new "raccolta"`, error);
      }
    }
  };

  const removeRaccolta = async (id) => {
    try {
      await deleteRaccolta(id);
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error deleting a "raccolta" record`, id);
      } else {
        toast.error(
          error.response && error.response.data && error.response.data.detail
            ? error.response.data.detail
            : `Unknown error deleting a "raccolta" record`
        );
        console.error(`Unknown error deleting a "raccolta" record`, error);
      }
    }
  };

  const fetchNumberOfReperti = async (raccoltaId) => {
    try {
      const filter = [
        { key: "page", value: 1 },
        { key: "pageSize", value: 1 },
        { key: "raccolta.id", value: raccoltaId },
      ];
      const data = await getReperti(filter);
      setNumberOfReperti((prev) => ({
        ...prev,
        [raccoltaId]: data.totalElements,
      }));
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error fetching data from "reperti"`);
      } else {
        console.error(`Unknown error fetching data from "reperti"`, error);
      }
    }
  };

  //* ****************************************************************************************************
  // ALL EFFECTS

  // FETCHING THE SELECTED CASSETTA FROM BE
  useEffect(() => {
    if (cassettaId) fetchCassetta(cassettaId);
  }, [cassettaId]);

  useEffect(() => {
    let issues = validateCassetta(formData);
    issues = issues.concat(validateRaccolte(raccolteData));
    setIssues(issues);
  }, [formData, raccolteData]);

  useEffect(() => {
    raccolteData.forEach((raccolta) => {
      const numberOfReperti = fetchNumberOfReperti(raccolta.id);
    });
  }, [raccolteData]);

  // ****************************************************************************************************
  // ALL HANDLERS

  const handleSave = async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    setIsLoading(true);
    try {
      let cassetta = {
        ...formData,
        comuneId: formData.comune.id,
        groups: formData.groups.map((group) => group.name), // Convert groups to array of ids
      };
      delete cassetta.raccolte;
      delete cassetta.comune;
      await editCassetta(cassetta);
      await Promise.all(
        raccolteData.map(async (raccolta) => {
          if (formData.raccolte.find((r) => r.id === raccolta.id)) {
            console.log("editRaccolta", raccolta);
            const sumbitData = {
              ...raccolta,
              descrizione: Array.isArray(raccolta.descrizione)
                ? raccolta.descrizione.reduce((acc, curr) => {
                    acc[curr.class] = curr.quantity;
                    return acc;
                  }, {})
                : raccolta.descrizione,
            };
            // await editRaccolta({ ...raccolta });
            await editRaccolta(sumbitData);
          } else {
            raccolta.cassettaId = formData.id;
            const sumbitData = {
              ...raccolta,
              descrizione: Array.isArray(raccolta.descrizione)
                ? raccolta.descrizione.reduce((acc, curr) => {
                    acc[curr.class] = curr.quantity;
                    return acc;
                  }, {})
                : raccolta.descrizione,
            };
            await newRaccolta(sumbitData);
          }
        })
      );
      await Promise.all(
        formData.raccolte.map(async (raccolta) => {
          if (!raccolteData.find((r) => r.id === raccolta.id))
            await removeRaccolta(raccolta.id);
        })
      );
      setCassetteFilter([]); // Reset the filter
    } catch (error) {
      console.error("Error saving the data", error);
    } finally {
      setIsLoading(false);
      navigate(-1);
    }
  };

  const jumpToReperti = (raccoltaId) => {
    setRepertiFilter([{ key: "raccolta.id", value: raccoltaId }]);
    navigate("/reperti");
  };

  const handleMapView = (e) => {
    const target = { layer: e.tipo, key: e.chiave, value: e.valore };
    setMapViewTarget(target);
    navigate("/MapView");
  };

  const handleResourceView = (e) => {
    handleBrowseFile(e.id, "pdf");
  };

  // ****************************************************************************************************

  const disabled = pathname !== "/cassette/edit";
  const cassetteRecordDetailsBreadcrumbs = [
    {
      id: 1,
      label: "Dati Archeoligici",
      iconUrl: true,
    },
    {
      id: 2,
      label: "CASSETTE",
      iconUrl: true,
    },
    { id: 3, label: "Modifica inforamzioni cassetta", iconUrl: "" },
  ];

  const subHeaderRightNavOptions = {
    icons: disabled
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {
              navigate(-1);
            },
            label: "Back",
          },
          userHasPermission({
            userProfile,
            resource: "CASSETTA",
            resourceGroups: formData?.groups,
            permission: "WRITE",
          }) && {
            icon: <EditIcon fillColor="#E15615" />,
            onClick: () => {
              navigate("/cassette/edit");
            },
            label: "Edita",
          },
        ]
      : [],
    components: disabled
      ? []
      : [
          <button onClick={() => navigate(-1)} className="cancel-button">
            <CrossIcon />
            <span className="login-text">Cancel</span>
          </button>,
          <button
            onClick={() => {
              handleSave();
            }}
            className="save-button"
          >
            <AddIcon fillColor="#fff" />
            <span className="login-text">Salva</span>
          </button>,
        ],
  };
  return (
    <>
      <div className="container">
        <main className="record-detail-main-content">
          <DataTableSubHeader
            title={"Dettaglio cassetta"}
            breadcrumbsData={cassetteRecordDetailsBreadcrumbs}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <CassetteRecordDetailForm
            classListComponentKey={classListComponentKey}
            data={formData}
            depositiData={depositiData}
            handleMapView={handleMapView}
            issues={issues}
            showIssues={showIssues}
            raccolteData={raccolteData}
            numberOfReperti={numberOfReperti}
            setData={setFormData}
            setRaccolteData={setRaccolteData}
            disabled={disabled}
            onFieldChange={handleFormDataChange}
            setClassListComponentKey={setClassListComponentKey}
            jumpToReperti={jumpToReperti}
            handleResourceView={handleResourceView}
            //handleCallbackListFormData={handleCallbackListFormData}
            //handleCallbackAddToListFormData={handleCallbackAddToListFormData}
          />
        </main>
        {isLoading && <Spinner />}
        {isMenuActive && (
          <SubHeaderMobileOption
            buttons={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
        <CustomModal
          isVisible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
        >
          Risorsa attualmente non disponibile
        </CustomModal>
      </div>
    </>
  );
}

export default CassetteRecordDetailsTable;
