import { useState, useCallback } from "react";
import { CloudUploadIcon, CrossIcon } from "../../../assets/icons";
import UploadBiblografiaModal from "../../Common/UploadPdfModal/UploadPdfModal";
import { uploadFile } from "../../../api";
import { toast } from "react-toastify";
import PdfImg from "../../../assets/images/pdf_icon.svg";
import SpinnerLoading from "../../SpinnerLoading/SpinnerLoading";
import { useBrowseFile } from "../../../hooks/useBrowseFile";
import "./UploadAllegato.scss";

const UploadAllegato = ({ id, allegato, disabled, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);
  const { isFileDownloading, handleBrowseFile } = useBrowseFile();

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleUpload = useCallback(
    async (ev) => {
      const files = ev.target.files;
      closeModal();
      setIsFileUploading(true);

      if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async function () {
          try {
            const uploadedFileId = await uploadFile(file);

            onChange({ id: uploadedFileId, name: file.name }, id);
          } catch (error) {
            console.error("Error uploading file.", error);
            toast.error("Error uploading file.");
          } finally {
            setIsFileUploading(false);
          }
        };

        if (!file.name.endsWith(".pdf")) {
          console.error("Unsupported file format.");
          toast.error("Unsupported file format.");
          setIsFileUploading(false);
        }
      }
    },
    [onChange, id, closeModal]
  );

  const handleRemovePdf = useCallback(() => {
    onChange(null, id);
  }, [onChange, id]);

  return (
    <div className="upload-pdf-container">
      <div className="form-block-header">
        <p className="upload-pdf-header-title">PDF Upload</p>
        <button
          className="upload-biblografia-btn"
          disabled={disabled}
          onClick={openModal}
        >
          <CloudUploadIcon width={16} height={16} />
          <span>Carica PDF</span>
        </button>
      </div>
      <div>
        {isFileUploading || isFileDownloading ? (
          <SpinnerLoading isLoading={isFileUploading || isFileDownloading} />
        ) : null}
        <div className="allegato-preview-container">
          {allegato ? (
            <div className="biblografia-pdf-container">
              <button onClick={() => handleBrowseFile(allegato?.id, "pdf")}>
                <img src={PdfImg} alt="pdf" />
                <p>{allegato?.name}</p>
              </button>
              <span
                className={`pdf-close-icon ${disabled ? "disabled" : ""}`}
                onClick={handleRemovePdf}
              >
                <CrossIcon width={20} height={20} />
              </span>
            </div>
          ) : null}
        </div>
      </div>
      <UploadBiblografiaModal
        title={"Upload PDF"}
        showModal={showModal}
        handleUpload={handleUpload}
        closeModal={closeModal}
      />
    </div>
  );
};

export default UploadAllegato;
