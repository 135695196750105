import { AddIcon } from "../../../assets/icons";
import BlockHeader from "../../Common/BlockHeader/BlockHeader";
import CustomTooltip from "../../CustomTooltip";
import FormField from "../../FormField/FormField";
import UploadAllegato from "../UploadAllegato/UploadAllegato";
import { CrossIcon } from "../../../assets/icons";

const ScaffaliSection = ({
  pianoId,
  scaffali,
  disabled = false,
  handleAddScaffle,
  handleRemoveScaffale,
  handleScaffaleAllegatoChange,
  handleUpdateNome,
}) => {
  const handleAllegatoChange = (val, id) => {
    handleScaffaleAllegatoChange(val, id, pianoId);
  };
  return (
    <div className="scaffali-section">
      {!disabled && (
        <BlockHeader title="Scaffali">
          <CustomTooltip text="Aggiungi Scaffali">
            <button
              className="repertori-button"
              onClick={() => handleAddScaffle(pianoId)}
            >
              <AddIcon fillColor="#e15615" />
            </button>
          </CustomTooltip>
        </BlockHeader>
      )}
      {scaffali &&
        scaffali.map((scaffale, index) => (
          <div key={index} className="scaffali-container">
            {!disabled && (
              <button
                className="scaffale-remove-icon"
                onClick={() => handleRemoveScaffale(scaffale?.id, pianoId)}
              >
                <CrossIcon width={20} height={20} />
              </button>
            )}
            <FormField
              fieldStructure={{
                label: "Nome",
                type: "text",
                name: "name",
                editable: true,
              }}
              disabled={disabled}
              value={scaffale?.nome}
              onChange={(val) => handleUpdateNome(val, scaffale?.id, pianoId)}
            />
            <UploadAllegato
              id={scaffale?.id}
              disabled={disabled}
              allegato={scaffale?.allegato}
              onChange={handleAllegatoChange}
            />
          </div>
        ))}
    </div>
  );
};

export default ScaffaliSection;
