import { useMemo } from "react";
import { CheckedIcon, ExclamationCircleIcon } from "../../../../assets/icons";
import "./ProcessSection.scss";

const ProcessSection = ({ batchData, isProcessing }) => {
  const { successes, errors, total } = batchData || {
    successes: 0,
    errors: 0,
    total: 0,
  };
  const uploadProgress = useMemo(() => {
    if (batchData) {
      const { successes, total } = batchData;
      return (successes / total) * 100;
    }
    return 0;
  }, [batchData]);

  const errorProgress = useMemo(() => {
    if (batchData) {
      const { errors, total } = batchData;
      return (errors / total) * 100;
    }
    return 0;
  }, [batchData]);

  return (
    <section className="process-container">
      <h2>File Upload Progress</h2>
      <div className="progress-wrapper">
        <div className="progress-bar">
          <div
            className="progress success-progress"
            style={{ width: `${uploadProgress}%` }}
          ></div>
          <div
            className="progress error-progress"
            style={{ width: `${errorProgress}%` }}
          ></div>
        </div>
        <p>
          {successes + errors}/{total}
        </p>
        {isProcessing ? <div className="process-spinner"></div> : null}
      </div>
      <div className="process-report-container">
        <div className="process-report-wrapper">
          <CheckedIcon />
          <p>Successes: {successes}</p>
        </div>
        <div className="process-report-wrapper">
          <ExclamationCircleIcon />
          <p>Errors: {errors}</p>
        </div>
      </div>
    </section>
  );
};

export default ProcessSection;
