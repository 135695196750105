import React from "react";
import { CrossIcon, MinusIcon } from "../../../assets/icons";
import "./style.scss";

const CustomModal = ({ title, isVisible, hideTitle, onClose, children }) => {
  if (!isVisible) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <section className="modal-container">
          {!hideTitle && (
            <header className="modal-header">
              <div className="modal-header-content">
                <h1 className="modal-title">{title}</h1>
                <div className="modal-icons">
                  <div className="modal-icon" onClick={onClose}>
                    <MinusIcon />
                  </div>
                  <div className="modal-icon" onClick={onClose}>
                    <CrossIcon />
                  </div>
                </div>
              </div>
            </header>
          )}
          <div className="modal-body">{children}</div>
        </section>
      </div>
    </div>
  );
};

export default CustomModal;
