export const associateCassetaJson = [
  {
    label: "Cassetta",
    attr: "cassetta",
    index: 0,
    value: null,
  },
  {
    label: "Deposito",
    attr: "riserva",
    index: 0,
    value: null,
  },
  {
    label: "Scaffale",
    attr: "scaffale",
    index: 0,
    value: null,
  },
  {
    label: "Piano",
    attr: "piano",
    index: 0,
    value: null,
  },
  {
    label: "Ripiano",
    attr: "ripiano",
    index: 0,
    value: null,
  },
  {
    label: "Note",
    attr: "note",
    index: 0,
    value: null,
  },
  {
    label: "Dati Frontalino",
    attr: "frontalino",
    index: 0,
    value: null,
  },
  {
    label: "Anno di Scavo",
    attr: "annoScavo",
    index: 0,
    value: null,
  },
];

export const tipoJson = [
  { label: "ambiente", value: "ambiente" },
  { label: "area", value: "area" },
  { label: "insula", value: "insula" },
  { label: "quadrato", value: "quadrato" },
  { label: "saggio", value: "saggio" },
  { label: "scavo", value: "scavo" },
  { label: "settore", value: "settore" },
  { label: "strada", value: "strada" }, 
  { label: "strato", value: "strato" },
  { label: "taglio", value: "taglio" },
  { label: "trincea", value: "trincea" }
];
