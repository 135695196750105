import { useState, useCallback } from "react";
import CustomModal from "../../../Common/CustomModal";
import FormField from "../../../FormField/FormField";
import CustomTooltip from "../../../CustomTooltip";
import { v4 as uuidv4 } from "uuid";
import {
  AddIcon,
  DeleteIcon,
  ExclamationTriangleIcon,
} from "../../../../assets/icons";
import { updateMuseo, saveMuseo } from "../../../../api";
import { toast } from "react-toastify";

const NewLocationModal = ({
  show,
  museumId,
  isUpdate,
  handleClose,
  handleNewLocation,
}) => {
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState([]);

  const handleAddLocation = useCallback(() => {
    setFormData((prev) => {
      return [
        ...prev,
        {
          id: uuidv4(),
          location: "",
        },
      ];
    });
  }, []);

  const handleDeleteLocation = useCallback((id) => {
    setFormData((prev) => {
      return prev.filter((location) => location.id !== id);
    });
  }, []);

  const validateForm = useCallback(() => {
    const newErrors = [];
    formData.forEach((location, index) => {
      if (!location.location) {
        newErrors.push(`La posizione ${index + 1} è obbligatoria`);
      }
    });
    setErrors(newErrors);
    return newErrors.length === 0;
  }, [formData]);

  const handleLocationChange = useCallback((value, id) => {
    setFormData((prev) => {
      return prev.map((location) => {
        if (location.id === id) {
          return {
            ...location,
            location: value,
          };
        }
        return location;
      });
    });
  }, []);

  const handleSaveLocation = useCallback(async () => {
    if (!validateForm()) {
      toast.error("Per favore, compila tutti i campi obbligatori");
      return;
    }

    try {
      if (isUpdate) {
        await updateMuseo({
          id: museumId,
          locations: formData.map((location) => ({
            location: location.location,
          })),
        });
      } else {
        await saveMuseo({
          id: museumId,
          locations: formData.map((location) => ({
            location: location.location,
          })),
        });
      }
      handleNewLocation();
      toast.success("Posizione salvata con successo");
    } catch (err) {
      console.error("Error saving location:", err);
      toast.error("Errore durante il salvataggio della posizione");
    } finally {
      setFormData([]);
      handleClose();
    }
  }, [museumId, validateForm, isUpdate, formData, handleClose, handleNewLocation]);

  return (
    <CustomModal title="Nuova Posizione" isVisible={show} onClose={handleClose}>
      <div className="new-location-modal-container">
        <div className="issue-container">
          {errors.length > 0 &&
            errors.map((error, index) => {
              return (
                <div key={index} className="issue">
                  <p className="issue-icon">
                    <ExclamationTriangleIcon />{" "}
                  </p>
                  {error}
                </div>
              );
            })}
        </div>
        <FormField
          fieldStructure={{
            label: "Identificativo",
            type: "text",
            name: "id",
            editable: false,
          }}
          value={museumId}
          disabled={true}
          onChange={(val) => {}}
        />
        <div className="new-location-modal-header">
          <label className="input-select-label">Posizione</label>
          <CustomTooltip text={"Aggiungi posizione"}>
            <button
              className="location-add-btn left-btn"
              onClick={handleAddLocation}
            >
              <AddIcon fillColor="#e15615" width={24} height={24} />
            </button>
          </CustomTooltip>
        </div>
        {formData.length > 0 &&
          formData.map((location, index) => (
            <div className="location-wrapper" key={index}>
              <input
                type="text"
                value={location?.location}
                onChange={(e) =>
                  handleLocationChange(e.target.value, location?.id)
                }
              />
              <button
                className="location-remove-btn"
                onClick={() => handleDeleteLocation(location?.id)}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}
      </div>
      <footer className="modal-footer">
        <button className="modal-footer-button" onClick={handleSaveLocation}>
          Submit
        </button>
      </footer>
    </CustomModal>
  );
};

export default NewLocationModal;
