import { useState, useMemo, useEffect } from "react";
import CustomModal from "../../../../Common/CustomModal";
import { newBibliografia } from "../../../../../api";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import PubblicazioniDetailForm from "../../../../Pubblicazioni/PubblicazioniDetailForm/PubblicazioniDetailForm";
import bibliografiaStructure from "../../../../../constants/dataStructure/biblografia.json";
import validatePubblicazioni from "../../../../../helpers/formTools/validations/pubblicazioni/validatePubblicazioni";
import "./AddNewBibliografia.scss";

const AddNewBibliografia = ({ open, handleClose }) => {
  const [showIissues, setShowIssues] = useState(false);
  const [formData, setFormData] = useState({});
  const issues = useMemo(() => validatePubblicazioni(formData), [formData]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, id: uuidv4() }));
  }, []);

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  const handleFormDataChange = (key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const newPubblicazioni = async (data) => {
    try {
      await newBibliografia(data);
      toast.success("Data updated successfully!");
    } catch (error) {
      toast.error(
        error.response && error.response.detail
          ? error.response.detail
          : `Unknown error saving a new "Publicazione"`
      );
    }
  };

  const handleSubmit = async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }

    try {
      const submitData = {
        ...formData,
        allegati: formData?.allegati?.map((item) => item.id),
      };
      await newPubblicazioni(submitData);
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while saving the data");
    } finally {
      onclose();
    }
  };

  return (
    <CustomModal
      title="Aggiungi Nuovo Bibliografia"
      isVisible={open}
      onClose={handleClose}
    >
      <div className="add-new-bibliografia">
        <PubblicazioniDetailForm
          showIssues={showIissues}
          issues={issues}
          formData={formData}
          bibliografiaStructure={bibliografiaStructure}
          copyToClipboard={copyToClipboard}
          handleFormDataChange={handleFormDataChange}
        />
      </div>
      <footer className="modal-footer">
        <button className="modal-footer-button" onClick={handleSubmit}>
          Submit
        </button>
      </footer>
    </CustomModal>
  );
};

export default AddNewBibliografia;
