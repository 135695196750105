import "./HomePage.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ArchiveIcon, MapIcon } from "../../assets/icons";

function HomePage() {
  const navigate = useNavigate();
  localStorage.removeItem("cbcValue");
  localStorage.removeItem("AREA_SCAVO");
  return (
    <section className="home-page-container ">
      <p className="home-intro">Benvenuti in Archeo</p>
      <p className="home-description">
        Per proseguire selezionare una opzione.
      </p>
      <div className="button-block">
        <button onClick={() => navigate("/MapView")} className="home-button">
          <span className="home-text">WEBGIS SCAVI ARECHEOLOGICI</span>
          <MapIcon height="100px" width="100px" />
        </button>
        <button onClick={() => navigate("/Reperti")} className="home-button">
          <span className="home-text">ARCHIVIO REPERTI E DOCUMENTI</span>
          <ArchiveIcon height="100px" width="100px" />
        </button>
      </div>
    </section>
  );
}

export default HomePage;
