import { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppContext } from "../../../Components/Context/AppContext";
import { AddIcon, CrossIcon, RightArrowIcon, EditIcon } from "../../../assets/icons";
import { getArchivioById, updateArchivio } from "../../../api";
import ArchivioDetailForm from "../../../Components/Archivio/ArchivoDetailForm/ArchivoDetailForm";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader";
import validateArchivio from "../../../helpers/formTools/validations/archivio/validateArchivio";
import archivioStructure from "../../../constants/dataStructure/archivio.json";
import userHasPermission from "../../../helpers/formTools/userHasPermission";

const archivioRecordDetailsBreadcrumbs = [
  {
    id: 1,
    label: "Dati Archeoligici",
    iconUrl: true,
  },
  {
    id: 2,
    label: "ARCHIVIO STORICO",
    iconUrl: true,
  },
  { id: 3, label: "Dettaglio docuemento", iconUrl: "" },
];

const EditArchivio = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { archivioId, userProfile } = useAppContext();

  const [formData, setFormData] = useState({});
  const [showIissues, setShowIssues] = useState(false);
  const [isMenuActive, setMenuActive] = useState(false);

  const disabled = useMemo(() => pathname !== "/archivio/edit", [pathname]);
  const issues = useMemo(() => validateArchivio(formData), [formData]);

  const subHeaderRightNavOptions = {
    icons: disabled
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {
              navigate(-1);
            },
            label: "Back",
          },
          userHasPermission({userProfile, resource: "ARCHIVIOSTORICO", resourceGroups: formData?.groups, permission: "WRITE"})  && {
            icon: <EditIcon fillColor="#E15615" />,
            onClick: () => {
              navigate("/archivio/edit");
            },
            label: "Edita",
          },
        ]
      : [],
    components: disabled
      ? []
      : [
          <button onClick={() => navigate(-1)} className="cancel-button">
            <CrossIcon />
            <span className="login-text">Cancel</span>
          </button>,
          <button
            onClick={() => {
              // navigate(-1);
              handleSave();
            }}
            className="save-button"
          >
            <AddIcon fillColor="#fff" />
            <span className="login-text">Salva</span>
          </button>,
        ],
  };

  const handleSave = useCallback(async () => {
    console.log("formData", formData);
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    navigate(-1);

    try {
      const submitData = {
        ...formData,
        allegati: formData?.allegati?.map((item) => item.id),
      };
      await updateArchivio(submitData);
      toast.success("Data updated successfully");
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while saving the data");
    }
  }, [formData, issues, navigate]);

  const fetchArchivioById = useCallback(async (id) => {
    try {
      const response = await getArchivioById(id);
      const fetchedData = {
        ...response?.archivioStoricoEntityBean,
        allegati: response?.archivioStoricoEntityBean?.allegati?.map((item) => {
          const nameWithoutId = item?.name?.includes(item.id)
            ? item.name.replace(`${item.id}\\`, "")
            : item.name;
          return {
            id: item.id,
            name: nameWithoutId,
          };
        }),
      };
      setFormData(fetchedData);
    } catch (error) {
      console.log("fetch-archivio-by-id-->>", error);
      toast.error("Data fetch failed!");
    }
  }, []);

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  const handleFormDataChange = (key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  useEffect(() => {
    if (archivioId) {
      fetchArchivioById(archivioId);
    }
  }, [archivioId, fetchArchivioById]);

  return (
    <div className="container">
      <main className="record-detail-main-content">
        <DataTableSubHeader
          title={"Dettaglio documento"}
          breadcrumbsData={archivioRecordDetailsBreadcrumbs}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
        <ArchivioDetailForm
          showIssues={showIissues}
          issues={issues}
          disabled={disabled}
          formData={formData}
          archivioStructure={archivioStructure}
          copyToClipboard={copyToClipboard}
          handleFormDataChange={handleFormDataChange}
        />
      </main>
    </div>
  );
};

export default EditArchivio;
