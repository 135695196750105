// MarkdownViewer.jsx
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import '../../pages/UserManual/UserManual.scss';
// import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; 

/*
const MarkdownContainer = styled.div`
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  font-family: Arial, sans-serif;
`;
*/

const MarkdownViewer = ({ content, handlePageChange }) => {
  const navigate = useNavigate();

  const handleLinkClick = (href) => {
    // Handle internal link navigation
    if (href.startsWith('#')) {
      handlePageChange(href.substring(1));
    }
    if (href.startsWith('/')) {
      navigate(href);
    } else {
      window.location.href = href;
    }
  };

  return (
    <div className="markdown-content">
      <ReactMarkdown
        children={content}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || '');
            return !inline && match ? (
              <SyntaxHighlighter
                style={dark}
                language={match[1]}
                PreTag="div"
                {...props}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
          a({ href, children, ...props }) {
            return (
              <a
                href={href}
                onClick={(e) => {
                  e.preventDefault();
                  handleLinkClick(href);
                }}
                {...props}
              >
                {children}
              </a>
            );
          }
        }}
      />
    </div>
  );
};

export default MarkdownViewer;