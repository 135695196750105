import React from "react";
import FormField from "../../../FormField/FormField";
import CustomTooltip from "../../../CustomTooltip";
import { FloorIcon } from "../../../../assets/icons";

const CassettaCollocazione = ({
  disabled,
  repertoData,
  tipoValue,
  handleCassetaNavigate,
  onRaccoltaChange,
}) => {
  return (
    <React.Fragment>
      <FormField
        fieldStructure={{
          label: "Tipo di Collocazione",
          type: "string",
          editable: false,
        }}
        value={tipoValue}
        onChange={() => {}}
        disabled={true}
      />
      <FormField
        fieldStructure={{
          label: "Identificativo Cassetta",
          type: "string",
          editable: true,
        }}
        value={
          repertoData["raccolta"] ? repertoData["raccolta"].cassettaId : "-"
        }
        icon={
          <CustomTooltip text="Visualizza cassetta">
            <div onClick={handleCassetaNavigate}>
              <FloorIcon fillColor="#e15615" width={24} height={24} />
            </div>
          </CustomTooltip>
        }
        onChange={() => {}}
        disabled={true}
      />

      <FormField
        fieldStructure={{
          label: "Identificativo scomparto",
          type: "string",
          editable: true,
        }}
        value={
          repertoData["raccolta"] ? repertoData["raccolta"].raccoltaId : "-"
        }
        onChange={(newValue) => onRaccoltaChange(newValue)}
        disabled={disabled}
      />
    </React.Fragment>
  );
};

export default CassettaCollocazione;
