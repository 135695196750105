import React, { useState, useCallback } from "react";
import "./Tabs.scss";

const Tabs = ({ tabs = [] }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const handleTabClick = useCallback((id) => {
    setActiveTab(id);
  }, []);

  return tabs.length ? (
    <div className="tabs__container">
      <div className="tabs__header">
        {tabs.map((tab) => (
          <button
            key={tab?.id}
            className={`tabs__header__item ${
              activeTab === tab?.id ? "active" : ""
            }`}
            onClick={() => handleTabClick(tab?.id)}
          >
            {tab?.label}
          </button>
        ))}
      </div>
      <div className="tabs__content">
        {tabs.map((tab) =>
          activeTab === tab?.id ? (
            <div key={tab?.id} className="tabs__content-item">
              {tab?.content}
            </div>
          ) : null
        )}
      </div>
    </div>
  ) : null;
};

export default Tabs;
