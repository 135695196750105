import React, { useState, useEffect } from "react";
import "./Raccolta.scss";
import FormField from "../../FormField/FormField";
import Provenienze from "../../Provenienze";
import raccoltaStructure from "../../../constants/dataStructure/raccolta.json";
import ClassSummary from "../ClassSummary";
import ClassList from "../../../assets/json/classi-materiali-flat.json";
import { ArchiveIcon, CopyIcon, DeleteIcon } from "../../../assets/icons";
import CustomTooltip from "../../CustomTooltip";
import { copyToClipboard } from "../../../helpers/utils/utils";

export default function Raccolta({
  raccolta,
  handleFieldChange,
  handleDeleteRaccolta,
  edit,
  numberOfReperti,
  jumpToReperti,
}) {
  const [classListComponentKey, setClassListComponentKey] = useState(0);

  // HANDLE CLASS LIST CANCEL
  const handleClassListCancel = () => {
    setClassListComponentKey((prevKey) => prevKey + 1);
  };

  if (!raccolta) return <></>;

  return (
    <div className="raccolta">
      <div className="raccolta-header">
        <p className="id-text"> ID:</p>
        <p
          className="copy-text"
          onClick={() => copyToClipboard(raccolta["id"])}
        >
          {raccolta["id"]}
          <CopyIcon />
        </p>
        {edit === true && (
          <CustomTooltip text="Elimina raccolta">
            <button
              className="raccolta-icon"
              onClick={() => handleDeleteRaccolta()}
            >
              <DeleteIcon />
            </button>
          </CustomTooltip>
        )}
      </div>
      <FormField
        fieldStructure={raccoltaStructure["nome"]}
        value={raccolta["nome"] ? raccolta["nome"] : ""}
        onChange={(newValue) => handleFieldChange("nome", newValue)}
        disabled={!edit}
      />
      <FormField
        fieldStructure={raccoltaStructure["anno_scavo"]}
        value={raccolta["annoScavo"] ? raccolta["annoScavo"] : ""}
        onChange={(newValue) => handleFieldChange("annoScavo", newValue)}
        disabled={!edit}
      />
      <Provenienze
        provenienze={raccolta["provenienze"]}
        onChange={(newValue) => handleFieldChange("provenienze", newValue)}
        edit={edit}
      />
      <ClassSummary
        key={classListComponentKey}
        classList={ClassList}
        data={raccolta["descrizione"] ? raccolta["descrizione"] : []}
        edit={edit}
        onSave={(newValue) => handleFieldChange("descrizione", newValue)}
        onClose={() => handleClassListCancel()}
      />
      {typeof numberOfReperti === "number" && (
        <div className="reperti-reference">
          <p>
            Numero di reperti collegati: <b>{numberOfReperti}</b>{" "}
          </p>
          <CustomTooltip text="Vai ai reperti">
            {numberOfReperti && numberOfReperti > 0 ? (
              <button className="jump-button" onClick={() => jumpToReperti()}>
                <ArchiveIcon fillColor="#e15615" />
              </button>
            ) : (
              <ArchiveIcon filColor="#ccc" />
            )}
          </CustomTooltip>
        </div>
      )}
    </div>
  );
}
