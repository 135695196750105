import React, { useState, useMemo, useCallback } from "react";
import { useAppContext } from "../../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import CustomTooltip from "../../../CustomTooltip";
import BlockHeader from "../../../Common/BlockHeader/BlockHeader";
import CassettaCollocazione from "./CassettaCollocazione";
import MovimentazioneCollocazione from "./MovimentazioneCollocazione";
import EsposizioneCollocazione from "./EsposizioneCollocazione";
import "./CollocazioneSection.scss";

const CollocazioneSection = ({
  repertoData,
  disabled,
  handleFieldChange,
  onRaccoltaChange,
}) => {
  const [isCassetta, setIsCassetta] = useState(true);

  const navigate = useNavigate();
  const { setCassettaId } = useAppContext();

  const isOut = useMemo(
    () => repertoData.movimentazione !== null,
    [repertoData]
  );
  const isRaccolta = useMemo(
    () => repertoData.raccolta !== null,
    [repertoData]
  );
  const isEsposizione = useMemo(
    () => repertoData.esposizione !== null,
    [repertoData]
  );

  const toggleCollocazione = useCallback(() => {
    setIsCassetta((prev) => !prev);
  }, []);

  const tipoValue = useMemo(() => {
    if (isOut) {
      return "Fuori sede";
    } else {
      return repertoData["raccolta"] && repertoData["raccolta"].cassettaId
        ? "Deposito"
        : "-";
    }
  }, [isOut, repertoData]);

  const handleCassetaNavigate = () => {
    setCassettaId(repertoData["raccolta"].cassettaId);
    navigate("/cassette/view");
  };

  const renderCollocazione = () => {
    if (isOut) {
      return (
        <MovimentazioneCollocazione
          repertoData={repertoData}
          tipoValue={tipoValue}
        />
      );
    }
    if (isEsposizione) {
      return (
        <EsposizioneCollocazione
          repertoData={repertoData}
          disabled={disabled}
          handleFieldChange={handleFieldChange}
        />
      );
    }
    if (isRaccolta || isCassetta) {
      return (
        <CassettaCollocazione
          disabled={disabled}
          tipoValue={tipoValue}
          repertoData={repertoData}
          handleCassetaNavigate={handleCassetaNavigate}
          onRaccoltaChange={onRaccoltaChange}
        />
      );
    }
    return (
      <EsposizioneCollocazione
        repertoData={repertoData}
        disabled={disabled}
        handleFieldChange={handleFieldChange}
      />
    );
  };

  return (
    <React.Fragment>
      <BlockHeader title="COLLOCAZIONE">
        {!disabled && !isOut && !isEsposizione && !isRaccolta && (
          <CustomTooltip text="Modifica collocazione">
            <button
              className="toggle-cassetta-button"
              onClick={toggleCollocazione}
            >
              {isCassetta ? "Cassetta" : "Esposizione"}
            </button>
          </CustomTooltip>
        )}
      </BlockHeader>
      {renderCollocazione()}
    </React.Fragment>
  );
};

export default CollocazioneSection;
