import { OptionCard, Select} from "../../index";
import React, { useEffect, useState } from 'react';
import "./SelectComune.scss";
import { getComuni  } from "../../../api";
import { toast } from "react-toastify";

export const SelectComune = ({ handleOnCloseSocialIcon, selectedComune, onSelectComune}) => {
  const [selectedId, setSelectedId] = useState();
  const [comuni, setComuni] = useState([]);

  const handleSelectChange = (selectedOption) => {
    setSelectedId(selectedOption.id);
    onSelectComune(comuni?.find(comune => comune.id === selectedOption.id));
  };

  //******************************************************************* */
  //    FETCHING THE LIST OF COMUNI
  useEffect(() => {
    const fetchComuni = async () => {
      try {
        const data = await getComuni([
          {key: "pageSize", value: 1000},
          {key: "page", value: 1},
          {key: "sort", value: "nome,asc"}]);
         if(data.length === 0) {
           console.error(`The list of "comuni" is empty`);
         } else {
            setComuni(data.results.filter(comune => comune.nome!=="ITALIA"));
         }
      } catch (error) {
          console.error(`Error fetching municipilaty names: ${error.message}`);
      }
    }
    fetchComuni();
  }, []);

  

  return (
    <OptionCard
      title="Selezione comune o sito"
      className="select-municipality-card-container"
      onCrossClick={handleOnCloseSocialIcon}
    >
      {/* <Select
        className="municipality-select-input"
        options={[
          { value: "Municipality 1" },
          { value: "Municipality 2" },
          { value: "Municipality 3" },
        ]}
        label="Select Municipality"
      /> */}
      <Select
        className="municipality-select-input"
        options={comuni?.map((option) => ({
          value: option.nome,
          id: option.id
        }))}
        label="Seleziona il sito o il comune"
        onSelect={handleSelectChange} 
      />
      
    </OptionCard>
  );
};
