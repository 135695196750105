import { useState } from "react";
import CustomModal from "../../../Common/CustomModal";
import Select from "react-select";
import "./style.css";

const ClassSummaryAddModal = ({
  show,
  classList,
  handleClose,
  handleAddClass,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [quantity, setQuantity] = useState(1);

  const customOptions = classList.map((item) => ({
    value: `${item.description} (${item.value})`,
    label: `${item.description} (${item.value})`,
  }));

  const onSelectedChange = (option) => {
    setSelectedOption(option);
  };

  const onChangeQuantity = (e) => {
    setQuantity(e.target.value);
  };

  const onAddClass = (e) => {
    e.preventDefault();
    handleAddClass(selectedOption, quantity);
    setSelectedOption(null);
    setQuantity(1)
  };

  return (
    <CustomModal title="AGGIUNGI CLASSE" isVisible={show} onClose={handleClose}>
      <form className="class-summary-add-modal" onSubmit={onAddClass}>
        <div className="class-summary-add-modal-content">
          <div className="class-summary-add-modal-form">
            <label>Classe</label>
            <Select
              options={customOptions}
              required
              value={selectedOption}
              placeholder="Seleziona una opzione"
              onChange={onSelectedChange}
            ></Select>
          </div>
          <div className="class-summary-add-modal-form">
            <label htmlFor="quantity">Quantità</label>
            <input
              type="number"
              name="quantity"
              value={quantity}
              id="quantity"
              placeholder="1"
              min={1}
              required
              onChange={onChangeQuantity}
            />
          </div>
        </div>
        <footer className="modal-footer">
          <button className="modal-footer-button" type="submit">
            Add
          </button>
        </footer>
      </form>
    </CustomModal>
  );
};

export default ClassSummaryAddModal;
