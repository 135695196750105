import React, { useEffect, useCallback, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SubHeaderMobileOption } from "../../Components/SubHeaderMobileOption/SubHeaderMobileOption";
import DataTableSubHeader from "../../Components/DataTableSubHeader/DataTableSubHeader";
import ItemsTable from "../../Components/ItemsTable/ItemsTable";
import { toast } from "react-toastify";
import Dropdown from "../../Components/Dropdown/Dropdown.jsx";
import { getUsers, getRoles, getGroups, deleteUser } from "../../api";
import {
  CrossIcon,
  RightArrowIcon,
  AddIcon,
  EditIcon,
  FilterIcon,
} from "../../assets/icons";
import { useCheckRole } from "../../hooks/useCheckRole";
import useTransformNewData from "../../hooks/useTransformNewData";
import AddUserModal from "../../Components/UsersDetailsTable/AddUserModal/AddUserModal";
import EditUserModal from "../../Components/UsersDetailsTable/EditUserModal/EditUserModal";
import DeleteModal from "../../Components/Common/DeleteModal/DeleteModal";
import FilterModal from "../../Components/FilterModal";
import ReactModal from "../../Components/ReactModal/ReactModal";
import { useAppContext } from "../../Components/Context/AppContext";
import "./UsersDetailsTable.scss";

const breadcrumbsData = [
  {
    id: 1,
    label: `Administration ${localStorage.getItem("username")}`,
    iconUrl: true,
  },
  {
    id: 2,
    label: "USERS",
    iconUrl: "",
  },
];

const selectedHeaders = [
  { key: "userName", label: "Nome utente", width: "300px", sortable: true },
  { key: "roles", label: "Ruoli", width: "300px", sortable: false },
  { key: "groups", label: "Gruppi", width: "500px", sortable: false },
  { key: "actions", label: "Azioni", width: "100px", sortable: false },
];

const filterValues = [{ label: "Nome utente", key: "username" }];

const UsersDetailsTable = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [fetchedUserData, setFetchedUserData] = useState([]);
  const [rolesData, setRolesData] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filterValue, setFilterValue] = useState({});
  const [isReset, setIsReset] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc");

  const { userFilter, setUserFilter } = useAppContext();

  const flagForUserEdit = true;

  // Check if the user is authorized to access this page
  useCheckRole("ADMIN");

  const transformNewData = useTransformNewData(
    fetchedUserData,
    isEditing,
    setIsEditModalOpen,
    setIsDeleteModalOpen,
    setSelectedUser
  );

  const tableData = useMemo(() => {
    return transformNewData;
  }, [transformNewData]);

  const editingOptions = (
    <div className="editing-tools-button-wrapper">
      <button
        className="action-button cancel-button"
        onClick={() => setIsEditing(false)}
      >
        <CrossIcon />
        <span>Cancel</span>
      </button>
    </div>
  );

  const fetchUsers = useCallback(async () => {
    try {
      let filterData = {};
      if (Array.isArray(userFilter) && userFilter.length > 0) {
        filterData = {
          ...filterData,
          ...userFilter.reduce((acc, item) => {
            if (item && typeof item === "object") {
              Object.entries(item).forEach(([key, value]) => {
                if (key && value) {
                  acc[key] = value;
                }
              });
            }
            return acc;
          }, {}),
        };
      }

      const { users } = await getUsers(filterData);
      console.log("users--->>>", users);
      if (Array.isArray(users)) {
        setFetchedUserData(users);
      } else {
        toast.error(`Unknown error listing users`);
      }
    } catch (err) {
      toast.error("Error while fetching users");
    }
  }, [userFilter]);

  const fetchRoles = useCallback(async () => {
    try {
      const { roles } = await getRoles();

      const data = roles.map((role) => ({
        id: role.id,
        value: role.authorities,
        label: role.name,
      }));

      setRolesData(data);
    } catch (err) {
      console.error("Error while fetching roles");
    }
  }, []);

  const fetchGroups = useCallback(async () => {
    try {
      const { groups } = await getGroups();

      const data = groups.map((group) => ({
        value: group.name,
        color: group.color,
        label: group.name,
      }));
      setGroupsData(data);
    } catch (err) {
      console.error("Error while fetching groups");
    }
  }, []);

  const handleAddModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleEditModalClose = useCallback(() => {
    setIsEditModalOpen(false);
  }, []);

  const handleDeleteModalClose = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  const handleFilterIconClick = useCallback(() => {
    setIsFilterModalOpen(true);
  }, []);

  const handleUserUpdate = useCallback(async () => {
    handleEditModalClose();
    await fetchUsers();
    setIsEditing(false);
  }, [handleEditModalClose, fetchUsers]);

  const handleUserAdd = useCallback(async () => {
    handleAddModalClose();
    await fetchUsers();
    setIsEditing(false);
  }, [handleAddModalClose, fetchUsers]);

  const handleCallBackFilter = useCallback(
    (data) => {
      const filter = data
        .map((item) => {
          const foundFilter = filterValues.find((f) => f.label === item.label);
          return {
            key: foundFilter ? foundFilter.key : null,
            value: item.value,
          };
        })
        .filter((item) => item.key !== null); // Filter out items with null keys
      setUserFilter(filter);
      return true;
    },
    [setUserFilter]
  );

  const handleReset = useCallback((resetStatus) => {
    // setIsReset(resetStatus);
    // if (resetStatus) {
    //   filterRef.current = false;
    // }
  }, []);

  const handleCallBackSort = useCallback(
    (key) => {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
      setUserFilter([{ key: "sort", value: `${key}, ${sortDirection}` }]);
    },
    [setUserFilter, sortDirection]
  );

  const handleUserDelete = useCallback(
    async (username) => {
      try {
        await deleteUser(username);
        toast.success("User deleted successfully");
      } catch (err) {
        console.log("error--->>>", err);
        toast.error("Error while deleting user");
      } finally {
        handleDeleteModalClose();
        await fetchUsers();
        setIsEditing(false);
      }
    },
    [fetchUsers, handleDeleteModalClose]
  );

  const subHeaderRightNavOptions = {
    icons: isEditing
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => setIsEditing(false),
            label: "Back",
          },
          {
            icon: <AddIcon onClick={() => setIsModalOpen(true)} />,
            onClick: () => {
              setIsModalOpen(true);
            },
            label: "Aggiungi",
          },
        ]
      : [
          {
            icon: <EditIcon fillColor="#E15615" height="28" width="28" />,
            onClick: () => setIsEditing(!isEditing),
            label: "Modifica",
          },
          {
            icon: <FilterIcon />,
            onClick: handleFilterIconClick,
            label: "Filtro",
          },
        ],

    components: isEditing
      ? [editingOptions]
      : [
          <Dropdown
            dropdownTexts={[
              {
                label: "UTENTI",
                onClick: () => {
                  navigate("/users");
                },
              },
              {
                label: "GRUPPI",
                onClick: () => {
                  navigate("/groups");
                },
              },
              {
                label: "RUOLI",
                onClick: () => {
                  navigate("/roles");
                },
              },
            ]}
            defaultSelected="USERS"
          />,
        ],
  };

  // Fetching the users
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    fetchRoles();
    fetchGroups();
  }, [fetchRoles, fetchGroups]);

  return (
    <div className="container">
      <main className="user-detail-main-content">
        <DataTableSubHeader
          title={"USERS"}
          breadcrumbsData={breadcrumbsData}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setIsMenuActive(!isMenuActive)}
        />
        <ItemsTable
          data={tableData}
          headers={selectedHeaders}
          isEditing={isEditing}
          totalRecords={totalRecords}
          currentPage={currentPage}
          recordsPerPage={recordsPerPage}
          callBackSort={handleCallBackSort}
        />
      </main>
      {isMenuActive && (
        <SubHeaderMobileOption
          components={!isEditing ? subHeaderRightNavOptions?.components : []}
          buttons={isEditing ? [editingOptions] : []}
          icons={subHeaderRightNavOptions?.icons}
          onCrossClick={() => setIsMenuActive(false)}
          title="options"
        />
      )}
      {isModalOpen && (
        <AddUserModal
          isOpen={isModalOpen}
          rolesData={rolesData}
          groupsData={groupsData}
          onClose={handleAddModalClose}
          onSave={handleUserAdd}
        />
      )}
      {isDeleteModalOpen && (
        <DeleteModal
          show={isDeleteModalOpen}
          item={selectedUser?.username}
          handleClose={handleDeleteModalClose}
          handleDelete={handleUserDelete}
          itemType="User"
        />
      )}
      {isEditModalOpen && (
        <EditUserModal
          show={isEditModalOpen}
          user={selectedUser}
          rolesData={rolesData}
          groupsData={groupsData}
          handleClose={handleEditModalClose}
          handleEdit={handleUserUpdate}
        />
      )}
      <ReactModal
        isOpen={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
      >
        <FilterModal
          closeModal={() => setIsFilterModalOpen(false)}
          filterValues={filterValues}
          setFilterValue={setFilterValue}
          callBackFilter={handleCallBackFilter}
          onReset={handleReset}
        />
      </ReactModal>
    </div>
  );
};

export default UsersDetailsTable;
