import { AddIcon, CrossIcon } from "../../assets/icons";
import { OptionCard, Select } from "../index";
import "./UploadedFileInfo.scss";

export const UploadedFileInfo = ({
  onCross = () => {},
  onCancel = () => {},
  onAddLayer = () => {},
  fileName = "",
}) => {
  return (
    <OptionCard
      className="upload-file-info-wrapper"
      title="UPLOAD RECORD (ONE AT A TIME)"
      onCrossClick={onCross}
    >
      <div className="file-name-text-wrapper">
        <div className="file-name-label">Uploaded File Name</div>
        <div className="file-name-text">{fileName}</div>
      </div>
      <Select
        label="Seleziona gli attributi"
        className="upload-file-info-select-input"
        options={[
          { value: "Select All", selectAll: true },
          { value: "Layer 1" },
          { value: "Layer 2" },
          { value: "Layer 3" },
        ]}
        overlap
        multiSelect
      />
      <div className="delete-layers-button-wrapper upload-file-info-button-wrapper">
        <button className="action-button cancel-button" onClick={onCancel}>
          <CrossIcon />
          <span>Cancel</span>
        </button>
        <button className="action-button delete-button" onClick={onAddLayer}>
          <AddIcon fillColor="white" />
          <span>Add Layer</span>
        </button>
      </div>
    </OptionCard>
  );
};
