import { useState, useEffect, useCallback } from "react";

const useFetchData = (fetchFunction, filter) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortDirection, setSortDirection] = useState("asc");

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      let filterData = {
        page: currentPage,
        pageSize: recordsPerPage,
      };
      if (Array.isArray(filter) && filter.length > 0) {
        filterData = {
          ...filterData,
          ...filter.reduce((acc, item) => {
            if (item && typeof item === "object") {
              acc[item.key] = item.value;
            }
            return acc;
          }, {}),
        };
      }

      const response = await fetchFunction(filterData);
      setData(response.results);
      setTotalRecords(response.totalElements);
      setTotalPages(response.totalPages);
    } catch (error) {
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchFunction, currentPage, recordsPerPage, filter]);

  const handleCallbackPerPageChange = useCallback((newPerPage, page) => {
    setCurrentPage(page);
    setRecordsPerPage(newPerPage);
  }, []);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handleSort = useCallback((column, sortDirection) => {
    console.log("column", column);
    console.log("sortDirection", sortDirection);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    data,
    isLoading,
    currentPage,
    recordsPerPage,
    totalRecords,
    totalPages,
    sortDirection,
    handleCallbackPerPageChange,
    handlePageChange,
    handleSort,
    setSortDirection,
    setCurrentPage,
    setRecordsPerPage,
    fetchData,
  };
};

export default useFetchData;
