import React from "react";
import {
  AddIcon,
  CloudIcon,
  DownloadIcon,
  FilterIcon,
} from "../../../assets/icons/index.js";
import DataDropdown from "../../DataDropdown";
import jsonToXlsx from "../../../helpers/formTools/transformations/jsonToXlsx";

export const subHeaderRightNavOptions = (
  userCanCreate,
  data,
  handleFilterIconClick,
  navigate
) => ({
  icons: [
    false && {
      icon: <CloudIcon />,
      onClick: () => {
        console.log("Upload");
      },
      label: "Upload",
    },
    userCanCreate && {
      icon: <AddIcon />,
      label: "Aggiungi",
      onClick: () => {
        navigate("/archivio/add");
        return null;
      },
    },
    {
      icon: <FilterIcon />,
      onClick: handleFilterIconClick,
      label: "Filtro",
    },
    {
      icon: <DownloadIcon />,
      label: "Scarica lista",
      onClick: () => {
        const jsonArr = data.map(({ actions, ...rest }) => rest);
        jsonToXlsx(jsonArr, "pubblicazioni.xlsx");
      },
    },
  ],
  components: [<DataDropdown defaultSelected="ARCHIVIO STORICO" />],
});
