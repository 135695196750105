import React from "react";
import Dropdown from "../Dropdown/Dropdown.jsx";
import { useNavigate } from "react-router-dom";

export default function DataDropdown(props) {
  const { defaultSelected } = props;
  const navigate = useNavigate();
  return (
    <Dropdown
      dropdownTexts={[
        {
          label: "REPERTI",
          onClick: () => {
            navigate("/reperti");
          },
        },
        {
          label: "PUBBLICAZIONI",
          onClick: () => {
            navigate("/pubblicazioni");
          },
        },
        {
          label: "ARCHIVIO STORICO",
          onClick: () => {
            navigate("/archivio");
          },
        },
        {
          label: "MOVIMENTAZIONE",
          onClick: () => {
            navigate("/movimentazione");
          },
        },
        {
          label: "DEPOSITO",
          onClick: () => {
            navigate("/deposito");
          },
        },
        {
          label: "CASSETTE",
          onClick: () => {
            navigate("/cassette");
          },
        },
      ]}
      defaultSelected={defaultSelected}
    />
  );
}
