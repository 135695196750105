import React, { useState } from "react";
import CustomModal from "../../Common/CustomModal";
import Select from "react-select";
import "./style.scss";

const AddGroup = ({
  groups = [],
  userGroups = [],
  show,
  handleClose,
  handleAddGroup,
}) => {
  const [selectedGroup, setSelectedGroup] = useState(null);

  /* *************************************************************** */
  // ALL HANDLERS

  const onSelectedGroup = (group) => {
    setSelectedGroup(group);
  };

  const onAddGroup = (e) => {
    e.preventDefault();
    handleAddGroup(userGroups.find((g) => g.name === selectedGroup.label));
    setSelectedGroup(null);
  };

  /* *************************************************************** */

  const possibleNewGroups = userGroups.filter(
    (group) => !groups.find((g) => g.name === group.name)
  );

  return (
    <CustomModal
      title={"Aggiungi un gruppo di lavoro"}
      isVisible={show}
      onClose={handleClose}
    >
      <form onSubmit={onAddGroup}>
        <div className="add-user-modal-body">
          <div className="input-wrapper">
            <Select
              options={possibleNewGroups.map((group) => {
                return { label: group.name, value: group.name };
              })}
              required
              value={selectedGroup ? selectedGroup : ""}
              placeholder="Seleziona una opzione"
              onChange={onSelectedGroup}
            ></Select>
          </div>
        </div>
        <footer className="modal-footer">
          <button type="submit" className="modal-footer-button">
            Aggiungi
          </button>
        </footer>
      </form>
    </CustomModal>
  );
};

export default AddGroup;
