import React from "react";
import "./Pagination.scss";
import { RightIcons } from "../../assets/icons/rightIcon";
import { LeftIcon } from "../../assets/icons";

function Pagination({currentPage, recordsPerPage, totalRecords, onPageChange, onRecordsPerPageChange}) {

  const indexOfLastRecord = currentPage * recordsPerPage;

  const handleNextPage = () => {
    const nextPage = currentPage + 1;
    onPageChange(nextPage);
  };

  const handlePrevPage = () => {
    const prevPage = currentPage - 1;
    onPageChange(prevPage);
  };

  return (
    <>
       <div className="pagination">
        {/* Pagination Info */}
        <div className="pagination__info">
          <div className="pagination__current-page">{currentPage}</div>
          <div className="pagination__separator">-</div>
          <div className="pagination__records-end">
            {indexOfLastRecord > totalRecords
              ? totalRecords
              : indexOfLastRecord}
          </div>
          <div className="pagination__separator">di</div>
          <div className="pagination__total-records">{totalRecords}</div>
          <div className="pagination__label">elementi</div>
          <div className="pagination__per-page">
            <div className="pagination__per-page-label">{recordsPerPage}</div>
            {/* <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/8e2ee675a3552763e8c730db833982dd79ecbdad3a8c6925fc2616c7fb7f5273?apiKey=d03ff6b018f84c75b88104249d2053b6&"
              className="pagination__per-page-icon"
              alt="example1"
            /> */}
          </div>
        </div>
        {/* PAgination Info End*/}
        {/* Pagination Nav */}
        <div className="pagination__nav">
        <div className={`pagination__nav-icon ${currentPage === 1 ? 'disabled' : ''}`}>
          <div
            //className="pagination__nav-icon"
            alt="example2"
            onClick={currentPage !== 1 ? handlePrevPage : undefined}
          ><RightIcons/></div>
        </div>
          <div className="pagination__nav-info">
            <div className="pagination__nav-label">Pagina</div>
            <div className="pagination__nav-page">{currentPage}</div>
          </div>
          <div className={`pagination__nav-icon ${indexOfLastRecord >= totalRecords ? 'disabled' : ''}`}>
          <div
            //className="pagination__nav-icon"
            alt="example3"
            onClick={indexOfLastRecord < totalRecords ? handleNextPage : undefined}
          ><LeftIcon/></div>
          </div>
        </div>
        {/* Pagination Nav End*/}
        {/* <button
          className="pagination__nav-icon"
          onClick={handlePrevPage}
          disabled={currentPage === 1}
        >
        </button>
        <div className="pagination__nav-info">
          <div className="pagination__nav-label">Page</div>
          <div className="pagination__nav-page">{currentPage}</div>
        </div>
        <button
          className="pagination__nav-icon"
          onClick={handleNextPage}
          disabled={indexOfLastRecord >= totalRecords}
        >
        </button> */}
      </div>
    </>
  );
}

export default Pagination;
