import React, { useState, useEffect } from "react";
import "../Reperti.scss";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader.jsx";
import { useNavigate } from "react-router-dom";
import { AddIcon, CrossIcon } from "../../../assets/icons/index.js";
import { SubHeaderMobileOption } from "../../../Components/SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { toast } from "react-toastify";
import { getRaccolta, createReperto } from "../../../api/index.js";
import { validateReperto } from "../../../helpers/formTools/validations/repertorio/index.js";
import { useAppContext } from "../../../Components/Context/AppContext.jsx";
import regex from "../../../helpers/formTools/regex/index.js";
import RepertiRecordDetailForm from "../../../Components/Reperti/AddReperto/RepertiRecordDetailForm/RepertiRecordDetailForm.jsx";

function AddReperto() {
  const { setRepertiFilter, userProfile } = useAppContext();
  const [isMenuActive, setMenuActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [issues, setIssues] = useState([]);
  const [showIssues, setShowIssues] = useState(false);

  const handleFormDataChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  // ****************************************************************************************************
  // ALL API CALLS

  const newReperto = async (data) => {
    try {
      await createReperto(data);
      toast.success("Nuovo reperto aggiunto con successo!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error saving a new "reperto"`);
      } else {
        toast.error(
          error.response && error.response.detail
            ? error.response.detail
            : `Unknown error saving a new "reperto"`
        );
        console.error(`Authorization error saving a new "reperto"`, error);
      }
    }
  };

  const fetchRaccolta = async (id) => {
    try {
      let data = await getRaccolta(id);
      if (data)
        setFormData((prevData) => ({
          ...prevData,
          raccolta: {
            raccoltaId: data.id,
            cassettaId: data.cassettaId,
          },
        }));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error("Unauthorized. Redirecting you to the login page...");
      } else {
        console.error(`Error fetching "raccolta" record`, error);
      }
    }
  };

  //* ****************************************************************************************************
  // ALL EFFECTS

  useEffect(() => {
    if (
      !userProfile.roles ||
      !userProfile.roles.some((r) =>
        r.authorities.some((aut) => aut === "ADMIN" || aut === "REPERTO_WRITE")
      )
    ) {
      toast.error("Serve l'autorizzazione per accedere a questa pagina");
      navigate("/signin");
    }
  }, [userProfile, navigate]);

  useEffect(() => {
    if (!formData) return;
    let issues = validateReperto(formData);
    setIssues(issues);
  }, [formData]);

  // ****************************************************************************************************
  // ALL HANDLERS

  const handleSave = async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    setIsLoading(true);
    try {
      let reperto = {
        ...formData,
        raccoltaId: formData?.raccolta?.raccoltaId,
        groups: formData?.groups?.map((group) => group.name), // Convert groups to array of ids
        codiciInventario: formData?.codiciInventario?.map((c) => ({
          tipo: c.tipo,
          codice: c.codice,
        })),
        provenienze: formData?.provenienze?.map((p) => ({
          comuneId: p.comuneId,
          chiave: p.chiave,
          valore: p.valore,
          tipo: p.tipo,
        })),
      };
      await newReperto(reperto);
      setRepertiFilter([]); // Reset the filter
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsLoading(false);
      navigate(-1);
    }
  };

  const handleRaccoltaChange = (newValue) => {
    if (regex.uuid.test(newValue)) {
      fetchRaccolta(newValue);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        raccolta: { cassettaId: "?", raccoltaId: newValue },
      }));
    }
  };

  // ****************************************************************************************************

  const repertiRecordDetailsBreadcrumbs = [
    {
      id: 1,
      label: "Dati Archeologici",
      iconUrl: true,
    },
    {
      id: 2,
      label: "REPERTI",
      iconUrl: true,
    },
    { id: 3, label: "Aggiungi reperto", iconUrl: "" },
  ];
  const subHeaderRightNavOptions = {
    icons: [],

    components: [
      <button onClick={() => navigate(-1)} className="cancel-button">
        <CrossIcon />
        <span className="login-text">Annulla</span>
      </button>,
      <button onClick={handleSave} disabled={isLoading} className="save-button">
        <AddIcon fillColor="#fff" />
        <span className="login-text">Salva</span>
      </button>,
    ],
  };

  return (
    <>
      <div className="container">
        <main className="record-detail-main-content">
          <DataTableSubHeader
            title={"Dettagli reperto"}
            breadcrumbsData={repertiRecordDetailsBreadcrumbs}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <RepertiRecordDetailForm
            data={formData}
            setData={setFormData}
            onFieldChange={handleFormDataChange}
            onRaccoltaChange={handleRaccoltaChange}
            disabled={false}
            issues={issues}
            showIssues={showIssues}
            // threeDLink={threeDLink}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            buttons={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}

export default AddReperto;
