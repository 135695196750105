const validateDeposito = (data) => {
  let issues = [];
  if (data.id === undefined || data.id === "")
    issues.push("Il campo 'ID' del tuo deposito è mancante o non valido.");
  if (data.piani === undefined || data.piani.length === 0)
    issues.push("Il campo 'Piani' del tuo deposito è mancante o non valido.");
  return issues;
};

export default validateDeposito;
