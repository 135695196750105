import DataTable from "react-data-table-component";
import InnerSpinnerLoading from "../../Common/InnerSpinnerLoading/InnerSpinnerLoading";
import { PdfIcon } from "../../../assets/icons";
import CustomTooltip from "../../CustomTooltip";

const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "#f4f4f4",
    },
  },
};

const MovimentazioneEventTable = ({
  movimentazioneEvents,
  isLoading,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
}) => {
  const columns = [
    // {
    //   selector: (row) => row.id,
    //   cell: (row) => (
    //     <div className="table-actions">
    //       <CustomTooltip text={"Browse Event"}>
    //         <button onClick={() => handleMovimentazioneView(row.id)}>
    //           <PdfIcon />
    //         </button>
    //       </CustomTooltip>
    //     </div>
    //   ),
    //   width: "70px",
    //   style: {
    //     padding: "6px",
    //   },
    // },
    {
      name: "Identificativo",
      selector: (row) => row.id,
      sortable: true,
      width: "280px",
    },
    {
      name: "Tipo",
      selector: (row) => row.tipo,
      sortable: true,
      width: "120px",
    },
    {
      name: "Date",
      selector: (row) => new Date(row.data).toLocaleString(),
    },
  ];
  return (
    <div className="movimentazione-section-table">
      <DataTable
        columns={columns}
        data={movimentazioneEvents}
        progressPending={isLoading}
        progressComponent={<InnerSpinnerLoading isLoading={isLoading} />}
        customStyles={customStyles}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
      />
    </div>
  );
};

export default MovimentazioneEventTable;
