import httpArcheo from "../helpers/http-archeo";
import axios from "axios";
import config from "../config";

export const fileUpload = async (formData) => {
  try {
    const response = await httpArcheo.post(
      "/api/private/v3/file/upload",
      formData
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fileDownloadByFetch = async (id) => {
  try {
    const apiUrl = config.archeoBaseUrl + "/api/private/v3/file/download/";
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        accept: "*/*",
      },
      redirect: "follow",
    };
    const response = await fetch(`${apiUrl}${id}`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const url = response.url;
    const result = await response.text();
    return { url, result, immagineId: id };
  } catch (error) {
    throw error;
  }
};

export const fileDownload = async (id) => {
  try {
    const res = await axios.get(
      `${config.archeoBaseUrl}/api/private/v3/file/download/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
        responseType: "arraybuffer",
      }
    );
    return { arrayBuffer: res.data, id };
  } catch (err) {
    console.log("down-err--->>", err);
  }
};

export const saveRepertoImg = async (data) => {
  try {
    await httpArcheo.post("/api/private/v3/immagine/reperto/save", data);
  } catch (err) {
    console.log(err);
  }
};

export const deleteRepertoImg = async (id) => {
  try {
    await httpArcheo.delete(`/api/private/v3/immagine/reperto/delete/${id}`);
  } catch (err) {
    console.log(err);
  }
};
