import { toast } from "react-toastify";

const isEmpty = (value) => {
  if (value == null) return true;
  if (typeof value === "string" && value.trim() === "") return true;
  if (Array.isArray(value) && value.length === 0) return true;
  if (typeof value === "object" && Object.keys(value).length === 0) return true;
  return false;
};

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const getRandomColor = (str) => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) {
    return true;
  }

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};

const mergeObjectsByProperty = (arr, prop) => {
  const result = [];
  const map = new Map();

  for (const item of arr) {
    if (!map.has(item[prop])) {
      map.set(item[prop], item);
    } else {
      const existingObj = map.get(item[prop]);
      const newObj = {
        ...existingObj,
        quantity: Number(existingObj.quantity) + Number(item.quantity),
      };
      map.set(item[prop], newObj);
    }
  }

  for (const obj of map.values()) {
    result.push(obj);
  }

  return result;
};

const downloadXLS = (binaryString, fileName) => {
  const blob = new Blob([binaryString], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const removeIdFromName = (name, id) => {
  return name.includes(id) ? name.replace(`${id}\\`, "") : name;
};

const copyToClipboard = (value) => {
  try {
    navigator.clipboard.writeText(value);
    toast.success("Copied to clipboard");
  } catch (error) {
    toast.error("Failed to copy to clipboard");
  }
};

const getFileExtension = (fileName) => {
  return fileName.split(".").pop();
};

const determineExtractFileType = async (blob) => {
  const buffer = await blob.arrayBuffer();
  const bytes = new Uint8Array(buffer);

  if (bytes[0] === 0x50 && bytes[1] === 0x4b) {
    return "zip"; // ZIP file signature
  } else if (
    bytes[0] === 0x52 &&
    bytes[1] === 0x61 &&
    bytes[2] === 0x72 &&
    bytes[3] === 0x21
  ) {
    return "rar"; // RAR file signature
  } else {
    return "unknown";
  }
};

const determineFileType = (fileHeader) => {
  // PNG file signature: 89 50 4E 47
  const isPNG =
    fileHeader[0] === 0x89 &&
    fileHeader[1] === 0x50 &&
    fileHeader[2] === 0x4e &&
    fileHeader[3] === 0x47;

  // JPG file signature: FF D8 FF
  const isJPG =
    fileHeader[0] === 0xff && fileHeader[1] === 0xd8 && fileHeader[2] === 0xff;

  let fileType = "unknown";
  if (isPNG || isJPG) {
    fileType = "image";
  } else if (
    fileHeader[0] === 0x4f &&
    fileHeader[1] === 0x62 &&
    fileHeader[2] === 0x6a &&
    fileHeader[3] === 0x20
  ) {
    // Check for OBJ file signature (example, may vary)
    fileType = "obj";
  }
  return { fileType, isPNG, isJPG };
};

const convertObjectToArray = (obj) => {
  return Object.entries(obj).map(([key, value]) => ({
    class: key,
    quantity: typeof value === 'object' ? 0 : value,
  }));
};

export {
  isEmpty,
  mergeObjectsByProperty,
  deepEqual,
  downloadXLS,
  debounce,
  getRandomColor,
  removeIdFromName,
  copyToClipboard,
  getFileExtension,
  determineFileType,
  determineExtractFileType,
  convertObjectToArray,
};
