import React, { useCallback, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { toast } from "react-toastify";
import {
  ExpandIcon,
  MinimizeIcon,
  EyeIcon,
  DeleteIcon,
  LightIcon,
} from "../../assets/icons";

const ThreeJSContainer = ({ glbUrl, disabled, handleDeleteImage, handleEnlarge, index }) => {
  const containerRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const sceneRef = useRef(null);
  const ambientLightRef = useRef(null);
  const pointLight1Ref = useRef(null);
  const pointLight2Ref = useRef(null);
  const directionalLightRef = useRef(null);
  const [isExpand, setIsExpanded] = useState(false);
  const [lightIntensity, setLightIntensity] = useState(20);

  const onWindowResize = useCallback(() => {
    if (cameraRef.current && rendererRef.current && containerRef.current) {
      const container = containerRef.current;
      cameraRef.current.aspect = container.clientWidth / container.clientHeight;
      cameraRef.current.updateProjectionMatrix();
      rendererRef.current.setSize(
        container.clientWidth,
        container.clientHeight
      );
    }
  }, []);

  const init = useCallback(async () => {
    if (!containerRef.current) return;

    const container = containerRef.current;
    const camera = new THREE.PerspectiveCamera(
      70,
      container.clientWidth / container.clientHeight,
      0.01,
      1000
    );
    camera.position.set(0, 0, 5);
    cameraRef.current = camera;

    const scene = new THREE.Scene();
    sceneRef.current = scene;
    scene.background = new THREE.Color(0xffffff);

    // Lights
    const ambientLight = new THREE.AmbientLight(0x404040, 20);
    scene.add(ambientLight);
    ambientLightRef.current = ambientLight;

    /*
    const pointLight1 = new THREE.PointLight(0xffffff, 50);
    pointLight1.position.set(20, 0, 0);
    scene.add(pointLight1);
    pointLight1Ref.current = pointLight1;

    const pointLight2 = new THREE.PointLight(0xffffff, 5);
    pointLight2.position.set(-15, 0, 0);
    scene.add(pointLight2);
    pointLight2Ref.current = pointLight2;
    */

    const directionalLight = new THREE.DirectionalLight(0xffffff, 5);
    directionalLight.position.set(90, 10, 0);
    scene.add(directionalLight);
    directionalLightRef.current = directionalLight;

    // Loaders
    const gltfLoader = new GLTFLoader();

    await new Promise((resolve, reject) => {
      gltfLoader.load(
        glbUrl,
        (gltf) => {
          const object = gltf.scene;
          const box = new THREE.Box3().setFromObject(object);
          const center = box.getCenter(new THREE.Vector3());
          const size = box.getSize(new THREE.Vector3());

          if (
            Number.isNaN(center.x) ||
            Number.isNaN(center.y) ||
            Number.isNaN(center.z)
          ) {
            console.warn(
              `Object at ${glbUrl} has invalid dimensions. Skipping rendering.`
            );
            resolve(); // Resolve promise even if invalid
            return;
          }

          object.position.sub(center);
          const maxAxis = Math.max(size.x, size.y, size.z);
          object.scale.setScalar(5 / maxAxis);
          object.position.y = -2;
          scene.add(object);

          resolve();
        },
        undefined,
        (error) => {
          toast.error("An error occurred while loading GLB:", error);
          reject(error);
        }
      );
    });

    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(container.clientWidth, container.clientHeight);
    rendererRef.current = renderer;
    container.appendChild(renderer.domElement);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.25;
    controls.enableZoom = false;

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };
    animate();

    onWindowResize();
  }, [glbUrl, onWindowResize]);

  const handleToggleSize = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const handleLightIntensityChange = (e) => {
    const intensity = parseFloat(e.target.value);
    setLightIntensity(intensity);
    if (ambientLightRef.current) ambientLightRef.current.intensity = intensity;
    if (pointLight1Ref.current) pointLight1Ref.current.intensity = intensity;
    if (pointLight2Ref.current) pointLight2Ref.current.intensity = intensity;
    if (directionalLightRef.current)
      directionalLightRef.current.intensity = intensity;
  };

  useEffect(() => {
    init();

    return () => {
      // Clean up resources when the component is unmounted
      if (rendererRef.current) {
        rendererRef.current.dispose();
      }
      if (containerRef.current) {
        while (containerRef.current.firstChild) {
          containerRef.current.removeChild(containerRef.current.firstChild);
        }
      }
    };
  }, [init]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.style.width = isExpand ? "500px" : "150px";
      containerRef.current.style.height = isExpand ? "500px" : "150px";
    }
    onWindowResize();
  }, [isExpand, onWindowResize]);

  return (
    <div className="three-container">
      <div ref={containerRef} style={{ width: "100%", height: "100%" }}></div>
      {/* <button onClick={handleToggleSize} className="three-expand-btn toggle">
        {isExpand ? (
          <MinimizeIcon
            color="white"
            fillColor="white"
            width={16}
            height={16}
          />
        ) : (
          <ExpandIcon color="white" fillColor="white" width={16} height={16} />
        )}
      </button> */}
      <button onClick={() => handleEnlarge(index)} className="three-expand-btn toggle">
        <EyeIcon height={16} width={16} fillColor="#fff" />
      </button>
      {!disabled && (
        <div
          className="three-expand-btn delete"
          onClick={() => handleDeleteImage(index)}
        >
          <DeleteIcon fillColor="white" width={16} height={16} />
        </div>
      )}
      <div className="three-light-intensity">
        <input
          type="range"
          min="0"
          max="100"
          step="1"
          value={lightIntensity}
          onChange={handleLightIntensityChange}
          className="light-intensity-slider"
        />
        <LightIcon strokeColor="#e15615" />
      </div>
    </div>
  );
};

export default ThreeJSContainer;
