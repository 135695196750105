import "./IssueModal.scss";
import { ExclamationTriangleIcon } from "../../../assets/icons";
import CustomModal from "../CustomModal";

const IssueModal = ({ isOpen, onClose, issues }) => {
  return (
    <CustomModal title="Warning" isVisible={isOpen} onClose={onClose}>
      <div>
        {issues.length
          ? issues.map((issue, index) => {
              return (
                <div key={index} className="issue">
                  <p className="issue-icon">
                    <ExclamationTriangleIcon />
                  </p>
                  {issue}
                </div>
              );
            })
          : null}
      </div>
      <footer className="modal-footer">
        <button className="modal-footer-button" onClick={onClose}>
          Close
        </button>
      </footer>
    </CustomModal>
  );
};

export default IssueModal;
