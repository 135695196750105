import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BlockHeader from "../../Common/BlockHeader/BlockHeader";
import BibliografiaTable from "./BibliografiaTable/BibliografiaTable";
import AddBibliografiaModal from "./AddBibliografiaModal/AddBibliografiaModal";
import AddNewBibliografia from "./AddBibliografiaModal/AddNewBibliografia/AddNewBibliografia";
import { getBibliografia, deleteBibliografiaReference } from "../../../api";
import { useAppContext } from "../../Context/AppContext";
import CustomTooltip from "../../CustomTooltip";
import { AddIcon } from "../../../assets/icons";
import "./BibliografiaSection.scss";
import { toast } from "react-toastify";

const BibliografiaSection = ({ edit = false, repertoId, repertiNote }) => {
  const [isAddModalShow, setIsAddModalShow] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [bibliografiaData, setBibliografiaData] = useState([]);
  const [results, setResults] = useState([]);
  const navigate = useNavigate();

  const { setBibliografiaId } = useAppContext();

  const fetchBibliografiaData = useCallback(
    async (page, pageSize) => {
      try {
        const data = await getBibliografia({
          page,
          pageSize,
          "reperto.id": repertoId,
        });
        const formattedData = data?.results.map((result) => ({
          id: result.id,
          titolo: result.titolo,
          label: result.titolo,
          value: result.titolo,
          abbreviazione: result.abbreviazione,
          note: result.note,
        }));
        setResults(data?.results);
        setBibliografiaData(formattedData);

        // console.log("bibliografia data", data);
      } catch (error) {
        console.error("Error fetching bibliografia data", error);
      }
    },
    [repertoId]
  );

  const handleUpdateBiliografia = () => {
    // console.log("update bibliografia");
    fetchBibliografiaData(1, 10);
  };

  const handleDeleteBibliografia = useCallback(
    async (id) => {
      try {
        await deleteBibliografiaReference({repertoId, bibliografiaId: id });
        toast.success("Successfully deleted!");
        fetchBibliografiaData(1, 10);
      } catch (err) {
        console.log("update-error-->>", err);
        toast.error("Delete failed");
      }
    },
    [results, repertoId, fetchBibliografiaData]
  );

  const handleBibliografiaView = useCallback(
    (id) => {
      setBibliografiaId(id);
      navigate("/pubblicazioni/view");
    },
    [navigate, setBibliografiaId]
  );

  useEffect(() => {
    fetchBibliografiaData(1, 10);
  }, [fetchBibliografiaData]);

  return (
    <div>
      <BlockHeader title={"Bibliografia"}>
        {edit && (
          <CustomTooltip text={"Aggiungi Bibliografia"}>
            <button
              className="repertori-button"
              onClick={() => {
                setIsAddModalShow(true);
              }}
            >
              <AddIcon fillColor="#e15615" />
            </button>
          </CustomTooltip>
        )}
      </BlockHeader>
      <BibliografiaTable
        edit={edit}
        bibliografiaData={bibliografiaData}
        handleDeleteBibliografia={handleDeleteBibliografia}
        handleBibliografiaView={handleBibliografiaView}
      />
      {isAddModalShow && (
        <AddBibliografiaModal
          open={isAddModalShow}
          repertoId={repertoId}
          repertiNote={repertiNote}
          selectedData={bibliografiaData}
          handleUpdateBiliografia={handleUpdateBiliografia}
          handleOpenNew={() => setIsAddModal(true)}
          handleClose={() => setIsAddModalShow(false)}
        />
      )}
      {isAddModal && (
        <AddNewBibliografia
          open={isAddModal}
          handleClose={() => setIsAddModal(false)}
        />
      )}
    </div>
  );
};

export default BibliografiaSection;
