import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import { CloudUploadIcon, EyeIcon, DeleteIcon } from "../../assets/icons";
import { fileUpload, fileDownload, deleteRepertoImg } from "../../api";
import Spinner from "../ProgressBar/Spinner";
import { determineFileType } from "../../helpers/utils/utils";
import ThreeScene from "../ThreeScene/ThreeScene";
import ImageView from "./ImageView";
import "./ImageGallery.scss";

export default function ImageGallery({
  disabled,
  immagini = [],
  handleImageChange,
}) {
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [imageIds, setImageIds] = useState([]);
  const [images, setImages] = useState([]);
  const [nonImageUrls, setNonImageUrls] = useState([]);
  const [enlargedImage, setEnlargedImage] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleDeleteImage = useCallback(
    async (id, immagineId) => {
      try {
        await deleteRepertoImg(id);
        setImages((prev) => prev.filter((item) => item.imageId !== immagineId));
        const updatedImmagini = immagini.filter(
          (item) => item.immagineId !== immagineId
        );
        handleImageChange(updatedImmagini);
      } catch (err) {
        console.error("Error deleting image:", err);
        toast.error("Error deleting image:", err);
      }
    },
    [immagini, handleImageChange]
  );

  const handleEnlargeImage = useCallback((idx) => {
    setEnlargedImage(idx);
    setModalVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setModalVisible(false);
  }, []);

  const handleFileUpload = useCallback(
    async (files) => {
      const formData = new FormData();
      Array.from(files).forEach((file) => {
        formData.append("file", file);
      });
      formData.append("idCassetta", 0);

      setIsImageLoading(true);
      try {
        const response = await fileUpload(formData);
        const updatedImmagini = [
          ...immagini,
          // { id: uuidv4(), immagineId: response.id },
          { id: response.id, immagineId: response.id },
        ];
        handleImageChange(updatedImmagini);
        toast.success("Image uploaded successfully");
      } catch (error) {
        console.error(error);
        toast.error("Image upload failed");
      } finally {
        setIsImageLoading(false);
      }
    },
    [immagini, handleImageChange]
  );

  const fetchImages = useCallback(async () => {
    setImages([]);
    setIsImageLoading(true);
    try {
      const responses = await Promise.all(
        imageIds.map((image) => fileDownload(image.immagineId))
      );
      responses.forEach(async (response) => {
        const blob = new Blob([response.arrayBuffer]);
        const fileHeader = new Uint8Array(await blob.slice(0, 4).arrayBuffer());
        const { fileType, isJPG, isPNG } = determineFileType(fileHeader);
        const matchingMetadata = imageIds.find(
          (image) => image.immagineId === response.id
        );
        if (isPNG || isJPG) {
          if (matchingMetadata) {
            const imageUrl = URL.createObjectURL(blob);
            setImages((prev) => {
              if (
                prev.find(
                  (item) => item.immagineId === matchingMetadata.immagineId
                )
              ) {
                return prev;
              } else {
                return [
                  ...prev,
                  {
                    url: imageUrl,
                    imageId: matchingMetadata.id,
                    immagineId: matchingMetadata.immagineId,
                    fileType: fileType,
                  },
                ];
              }
            });
          }
        } else {
          if (matchingMetadata) {
            const url = URL.createObjectURL(blob);
            setNonImageUrls((prev) => {
              if (
                prev.find(
                  (item) => item.immagineId === matchingMetadata.immagineId
                )
              ) {
                return prev;
              } else {
                return [
                  ...prev,
                  {
                    url,
                    imageId: matchingMetadata.id,
                    immagineId: matchingMetadata.immagineId,
                    fileType: fileType,
                  },
                ];
              }
            });
          } else {
            console.log(
              "No matching metadata found for non-image file, skipping."
            );
          }
        }
      });
    } catch (error) {
      console.error("Error fetching images:", error);
      toast.error("Error fetching images:", error);
    } finally {
      setIsImageLoading(false);
    }
  }, [imageIds]);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
      const files = e.dataTransfer.files;
      handleFileUpload(files);
    },
    [handleFileUpload]
  );

  const handleFileChange = useCallback(
    (e) => {
      const files = e.target.files;
      handleFileUpload(files);
    },
    [handleFileUpload]
  );

  useEffect(() => {
    if (Array.isArray(immagini)) {
      const ids = immagini.map((item) => ({
        id: item.id,
        immagineId: item.immagineId,
        materialId: item.immagineId,
      }));
      setImageIds(ids);
    }
  }, [immagini]);

  useEffect(() => {
    if (imageIds) {
      fetchImages();
    }
  }, [imageIds, fetchImages]);

  return (
    <React.Fragment>
      <section className="image-gallery-main-section">
        {!disabled && (
          <div
            className={`file-upload-landing ${isDragging ? "dragging" : ""}`}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <div className="upload-icon">
              <CloudUploadIcon width={50} height={50} />
            </div>
            <p className="instruction-text">
              Max File Size: 3MB, Accepted Formats: *.png, *.jpg, *.zip
            </p>
            <p className="upload-text">Drag & Drop your files here or</p>
            <label className="upload-button" htmlFor="file-upload-input">
              Browse Files
              <input
                id="file-upload-input"
                hidden
                type="file"
                onChange={handleFileChange}
                accept="image/*,.glb"
                multiple
              />
            </label>
          </div>
        )}

        <div className="images-container">
          {images.map((item, idx) => (
            <div key={idx} className="image-set">
              <img
                src={item.url}
                className="main-image"
                alt={`img- ${item.imageId}`}
              />
              <div className="image-set-overlay">
                <button
                  onClick={() => handleEnlargeImage(idx)}
                  className="overlay-icon"
                >
                  <EyeIcon height="24px" width="24px" />
                </button>
                {!disabled && (
                  <button
                    onClick={() =>
                      handleDeleteImage(item.imageId, item.immagineId)
                    }
                    className="overlay-icon"
                  >
                    <DeleteIcon
                      fillColor="#e15615"
                      height="24px"
                      width="24px"
                    />
                  </button>
                )}
              </div>
            </div>
          ))}
          <ThreeScene
            nonImageUrls={nonImageUrls}
            disabled={disabled}
            immagini={immagini}
            handleDeleteFile={handleDeleteImage}
          />
        </div>
      </section>
      <ImageView
        show={modalVisible}
        handleClose={handleClose}
        image={images[enlargedImage]}
      />
      {isImageLoading && <Spinner />}
    </React.Fragment>
  );
}
