import DataTable from "react-data-table-component";
import "./MovimentazioneSectionTable.scss";
import InnerSpinnerLoading from "../../../Common/InnerSpinnerLoading/InnerSpinnerLoading";
import { EyeIcon, DeleteIcon } from "../../../../assets/icons";

const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#000",
      backgroundColor: "#f4f4f4",
    },
  },
};

const MovimentazioneSectionTable = ({
  repertoData,
  movimentazioneData,
  isLoading,
  disabled,
  totalRows,
  handlePerRowsChange,
  handlePageChange,
  handleSort,
  handleMovimentazioneView,
  handleMovimantazioneDelete,
  userCanDeleteMovimentazione
}) => {
  const columns = [
    {
      selector: (row) => row.id,
      cell: (row) => (
        <div className="table-actions">
          <button onClick={() => handleMovimentazioneView(row.id)}>
            <EyeIcon />
          </button>
          { disabled && userCanDeleteMovimentazione && (
            <button onClick={() => handleMovimantazioneDelete(row.id)}>
              <DeleteIcon />
            </button>
          )}
        </div>
      ),
      width: "70px",
      style: {
        padding: "6px",
      },
    },
    {
      name: "Identificativo",
      selector: (row) => row.id,
      sortable: true,
      width: "250px",
    },
    {
      name: "Tipo",
      selector: (row) => row.tipo,
      sortable: true,
    },
    {
      name: "Inizio",
      selector: (row) => row.inizio,
      cell: (row) => new Date(row.inizio).toLocaleString(),
      width: "200px",
    },
    {
      name: "termine",
      selector: (row) => row.termine,
      cell: (row) => new Date(row.termine).toLocaleString(),
      width: "200px",
    },
    {
      name: "Destinazione",
      selector: (row) => row.destinazione,
    },
    {
      name: "Note",
      selector: (row) => row.note,
      sortable: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.id === repertoData?.movimentazione?.id,
      style: {
        backgroundColor: "#e15615",
      },
    }
  ]
  return (
    <div className="movimentazione-section-table">
      <DataTable
        columns={columns}
        data={movimentazioneData}
        progressPending={isLoading}
        progressComponent={<InnerSpinnerLoading isLoading={isLoading} />}
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        onSort={handleSort}
      />
    </div>
  );
};

export default MovimentazioneSectionTable;
