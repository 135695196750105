import httpArcheo from "../helpers/http-archeo";
import axios from "axios";

export const getMuseo = async (data) => {
  try {
    const response = await httpArcheo.get("/api/private/v3/museo/get", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMuseum = async ({
  page,
  pageSize,
  ordering,
  orderDir,
  search,
}) => {
  try {
    const response = await axios.get(
      `https://api.museiitaliani.it/cms-ms/api/public/cached/museum/?page=${page}&pageSize=${pageSize}&ordering=${ordering}&orderDir=${orderDir}&search=${search}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMuseumDetails = async (id) => {
  try {
    const response = await axios.get(
      `https://api.museiitaliani.it/cms-ms/api/public/cached/museum/${id}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveMuseo = async (data) => {
  try {
    const response = await httpArcheo.post("/api/private/v3/museo/save", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMuseo = async (data) => {
  try {
    const response = await httpArcheo.put("/api/private/v3/museo/update", data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
