import React from "react";
import { ExclamationTriangleIcon } from "../../../../assets/icons";
import IccdEditor from "../../IccdEditor";
import iccdManifest from "../../../../assets/json/ICCD-4.00-manifest-v0.2.json";
import Tabs from "../../../Common/Tabs/Tabs";
import RepertiRecordDetails from "./RepertiRecordDetails/RepertiRecordDetails";

export default function RepertiRecordDetailForm({
  data,
  immagini,
  onChangeImmagini,
  setData,
  onRaccoltaChange,
  disabled,
  send,
  issues,
  showIssues,
}) {
  const handleFieldChange = (key, newValue) => {
    setData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  const tabs = [
    {
      id: "generalità",
      label: "GENERALITÀ",
      content: (
        <RepertiRecordDetails
          data={data}
          immagini={immagini}
          setData={setData}
          onRaccoltaChange={onRaccoltaChange}
          disabled={disabled}
          handleImageChange={onChangeImmagini}
        />
      ),
    },
    {
      id: "iccd",
      label: "SCHEDA ICCD",
      content: (
        <React.Fragment>
          <IccdEditor
            manifest={iccdManifest}
            data={data?.iccd}
            edit={true}
            visibility={1}
            knownData={{}}
            onSave={(value) => handleFieldChange("iccd", value)}
          />
        </React.Fragment>
      ),
    },
  ];

  if (data && Object.keys(data).length > 0) {
    return (
      <React.Fragment>
        <main className="reperti-main-container">
          <div className="content-wrapper">
            <div className="issue-container">
              {!disabled &&
                showIssues &&
                issues.map((issue, index) => {
                  return (
                    <div key={index} className="issue">
                      <p className="issue-icon">
                        <ExclamationTriangleIcon />
                      </p>
                      {issue}
                    </div>
                  );
                })}
            </div>
            <Tabs tabs={tabs} />
          </div>
        </main>
      </React.Fragment>
    );
  }
}
