import React, { useState, useCallback, useMemo } from "react";
import FormField from "../../FormField/FormField";
import CustomSelect from "../../Common/CustomSelect/CustomSelect";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import { MapIcon, FloorIcon, RackIcon } from "../../../assets/icons";
import CustomWarningModal from "../../CustomWarningModal";
import "./Posizione.scss";

const Posizione = ({
  formData,
  depositiData = [],
  disabled,
  cassettaStructure,
  handleFormDataChange,
  handleMapView,
  handleResourceView,
}) => {
  const [pianoKey, setPianoKey] = useState(0);
  const [scaffaleKey, setScaffaleKey] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const getDepositoById = useCallback(
    (id) => depositiData.find((deposito) => deposito.id === id),
    [depositiData]
  );
  const getPianoByName = useCallback(
    (deposito, name) => deposito.piani.find((piano) => piano.nome === name),
    []
  );

  const depositiOptions = useMemo(
    () =>
      depositiData.map((deposito) => ({
        value: deposito.id,
        label: deposito.id,
      })),
    [depositiData]
  );

  const pianoOptions = useMemo(() => {
    const selectedDeposito = getDepositoById(formData["deposito"]);
    return selectedDeposito
      ? selectedDeposito.piani.map((piano) => ({
          value: piano.nome,
          label: piano.nome,
        }))
      : [];
  }, [formData, getDepositoById]);

  const scaffaleOptions = useMemo(() => {
    const selectedDeposito = getDepositoById(formData["deposito"]);
    const selectedPiano = selectedDeposito
      ? getPianoByName(selectedDeposito, formData["piano"])
      : null;
    return selectedPiano
      ? selectedPiano.scaffali.map((scaffale) => ({
          value: scaffale.nome,
          label: scaffale.nome,
        }))
      : [];
  }, [formData, getDepositoById, getPianoByName]);

  const handleSelectChange = useCallback(
    (name, option) => {
      if (name === "deposito") {
        handleFormDataChange("piano", "");
        handleFormDataChange("scaffale", "");
        setPianoKey((prevKey) => prevKey + 1);
        setScaffaleKey((prevKey) => prevKey + 1);
      } else if (name === "piano") {
        handleFormDataChange("scaffale", "");
        setScaffaleKey((prevKey) => prevKey + 1);
      }
      handleFormDataChange(name, option.value);
    },
    [handleFormDataChange]
  );

  const handleResource = useCallback(
    (resource) => {
      if (!formData[resource]) return;
      const selectedDeposito = getDepositoById(formData["deposito"]);
      if (!selectedDeposito) return;
      if (resource === "piano") {
        const selectedPiano = getPianoByName(
          selectedDeposito,
          formData["piano"]
        );
        if (selectedPiano) handleResourceView(selectedPiano.allegato);
      } else if (resource === "scaffale") {
        const selectedPiano = getPianoByName(
          selectedDeposito,
          formData["piano"]
        );
        if (!selectedPiano) return;
        const selectedScaffale = selectedPiano.scaffali.find(
          (scaffale) => scaffale.nome === formData["scaffale"]
        );
        if (selectedScaffale) handleResourceView(selectedScaffale.allegato);
      }
    },
    [handleResourceView, formData, getDepositoById, getPianoByName]
  );

  return (
    <div>
      <p className="section-title">POSIZIONE</p>
      <div className="posizione-wrapper">
        <CustomSelect
          options={depositiOptions}
          initValue={{
            label: formData["deposito"],
            value: formData["deposito"],
          }}
          name="deposito"
          label={"Deposito"}
          disabled={disabled}
          placeholder="Seleziona deposito"
          onChange={handleSelectChange}
        />
        {disabled && (
          <div className="posizione-row-icon">
            <CustomTooltip text="Visualizza su mappa">
              <button
                className="provenienze-icons"
                onClick={() => setShowModal(true)}
              >
                <MapIcon fillColor="#e15615" />
              </button>
            </CustomTooltip>
          </div>
        )}
      </div>

      <div className="posizione-wrapper">
        <CustomSelect
          key={`piano-${pianoKey}`}
          options={pianoOptions}
          initValue={{ label: formData["piano"], value: formData["piano"] }}
          name="piano"
          label={"Piano"}
          disabled={disabled}
          placeholder="Seleziona piano"
          onChange={handleSelectChange}
        />
        {disabled && formData["piano"] && (
          <div className="posizione-row-icon">
            <CustomTooltip text="Visualizza pianta">
              <button
                className="provenienze-icons"
                onClick={() => handleResource("piano")}
              >
                <FloorIcon fillColor="#e15615" />
              </button>
            </CustomTooltip>
          </div>
        )}
      </div>

      <div className="posizione-wrapper">
        <CustomSelect
          key={`scaffale-${scaffaleKey}`}
          options={scaffaleOptions}
          initValue={{
            label: formData["scaffale"],
            value: formData["scaffale"],
          }}
          name="scaffale"
          label={"Scaffale"}
          disabled={disabled}
          placeholder="Seleziona scaffale"
          onChange={handleSelectChange}
        />
        {false && formData["scaffale"] && (
          <div className="posizione-row-icon">
            <CustomTooltip text="Visualizza disposizione">
              <button
                className="provenienze-icons"
                onClick={() => handleResource("scaffale")}
              >
                <RackIcon fillColor="#e15615" />
              </button>
            </CustomTooltip>
          </div>
        )}
      </div>

      <FormField
        fieldStructure={cassettaStructure["ripiano"]}
        value={formData["ripiano"]}
        onChange={(newValue) => handleFormDataChange("ripiano", newValue)}
        disabled={disabled}
      />
      <CustomWarningModal
        isOpen={showModal}
        text={"Risorsa attualmente non disponibile."}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default React.memo(Posizione);
