// ThreeScene.js
import React, { useState, useCallback, useMemo } from "react";
import ThreeJSContainer from "./ThreeJSContainer";
import ThreeImageView from "../ThreeImageView/ThreeImageView";

const ThreeScene = ({ nonImageUrls, immagini, disabled, handleDeleteFile }) => {
  const [enlargedImage, setEnlargedImage] = useState(null);

  const handleDeleteImage = useCallback(
    (index) => {
      // Get the imageId associated with the index
      const urlToDelete = nonImageUrls[index];
      if (urlToDelete) {
        const immagineIdToDelete = urlToDelete.imageId;
        const imageId = immagini.find(
          (item) => item.immagineId === immagineIdToDelete
        ).id;
        if (imageId) {
          handleDeleteFile(imageId);
        }
      }
    },
    [nonImageUrls, immagini, handleDeleteFile]
  );

  const handleEnlarge = useCallback(
    (index) => {
      const urlToEnlarge = nonImageUrls[index];
      setEnlargedImage(urlToEnlarge.url);
    },
    [nonImageUrls]
  );

  const objUrls = useMemo(() => {
    return nonImageUrls.map((obj) => obj.url);
  }, [nonImageUrls]);

  return (
    <div className="three-scene-container">
      {objUrls.map((objUrl, index) => (
        <ThreeJSContainer
          key={`${objUrl}-${index}`}
          disabled={disabled}
          glbUrl={objUrl}
          handleDeleteImage={handleDeleteImage}
          handleEnlarge={handleEnlarge}
          index={index}
        />
      ))}
      {enlargedImage && (
        <ThreeImageView
          show={enlargedImage}
          glbUrl={enlargedImage}
          handleClose={() => setEnlargedImage(null)}
        />
      )}
    </div>
  );
};

export default ThreeScene;
