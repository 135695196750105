import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./AddMovimentazione.scss";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader";
import { useAppContext } from "../../../Components/Context/AppContext";
import { useCheckRole } from "../../../hooks/useCheckRole";
import { CrossIcon, AddIcon } from "../../../assets/icons";
import { newMovimentaznione } from "../../../api";

const movimentazioneRecordDetailsBreadcrumbs = [
  {
    id: 1,
    label: "Dati Archeoligici",
    iconUrl: true,
  },
  {
    id: 2,
    label: "MOVIMENTAZIONE",
    iconUrl: true,
  },
  { id: 3, label: "Aggiungi nuova movimentazione", iconUrl: "" },
];

const AddMovimentazione = () => {
  const navigate = useNavigate();

  const { setMovimentazioneFilter } = useAppContext();

  const [isMenuActive, setMenuActive] = useState(false);
  const [formData, setFormData] = useState({});

  useCheckRole("MOVIMENTAZIONE_WRITE");

  const subHeaderRightNavOptions = {
    icons: [],
    components: [
      <button onClick={() => navigate(-1)} className="cancel-button">
        <CrossIcon />
        <span className="login-text">Cancel</span>
      </button>,
      <button
        onClick={() => {
          handleSave();
        }}
        className="save-button"
      >
        <AddIcon fillColor="#fff" />
        <span className="login-text">Save</span>
      </button>,
    ],
  };

  const saveMovimentazione = async (data) => {
    try {
      await newMovimentaznione(data);
      toast.success("Data updated successfully!");
    } catch (error) {
      toast.error(
        error.response && error.response.detail
          ? error.response.detail
          : `Unknown error saving a new "Publicazione"`
      );
    }
  };

  const handleSave = useCallback(async () => {
    navigate(-1);

    try {
      const submitData = {
        ...formData,
        allegati: formData?.allegati?.map((item) => item.id),
      };
      await saveMovimentazione(submitData);
      setMovimentazioneFilter([]);
    } catch (err) {
      console.error(err);
      toast.error("An error occurred while saving the data");
    }
  }, [formData, setMovimentazioneFilter, navigate]);

  const copyToClipboard = (text) => {
    try {
      navigator.clipboard.writeText(text);
      toast.success("Copied to clipboard");
    } catch (error) {
      toast.error("Failed to copy to clipboard");
    }
  };

  const handleFormDataChange = (key, newValue) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  return (
    <div className="container">
      <main className="record-detail-main-content">
        <DataTableSubHeader
          title={"Dettagli movimentazione"}
          breadcrumbsData={movimentazioneRecordDetailsBreadcrumbs}
          subHeaderRightNavOptions={subHeaderRightNavOptions}
          isMenuActive={isMenuActive}
          setMenuActive={() => setMenuActive(!isMenuActive)}
        />
      </main>
    </div>
  );
};

export default AddMovimentazione;
