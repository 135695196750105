import { useState, useCallback, useMemo } from "react";
import {
  ExclamationTriangleIcon,
  CopyIcon,
  CalendarIcon,
  CloudUploadIcon,
  InputCircleIcon,
  OutputCircleIcon,
} from "../../assets/icons";
import PdfImg from "../../assets/images/pdf_icon.svg";
import FormField from "../FormField/FormField";
import { movimentazioneReferenti } from "../../constants/movimentazioneReferenti";
import DatePickerModal from "../Common/DatePickerModal/DatePickerModal";
import IssueModal from "../Common/IssueModal/IssueModal";
import BlockHeader from "../Common/BlockHeader/BlockHeader";
import MovimentazioneEventModal from "../Reperti/MovimentazioneSection/MovimentazioneEventModal/MovimentazioneEventModal";
import MovimentazioneEventTable from "./MovimentazioneEventTable/MovimentazioneEventTable";
import CustomTooltip from "../CustomTooltip";
import { useBrowseFile } from "../../hooks/useBrowseFile";
import { copyToClipboard } from "../../helpers/utils/utils";
import FileUploadComponent from "../Common/FileUploadComponent/FileUploadComponent";
import "./MovimentazioneDetailForm.scss";

const MovimentazioneDetailForm = ({
  issues,
  showIssues,
  disabled,
  formData,
  totalRecords,
  isLoading,
  movimentazioneEvents = [],
  datastructure,
  handleFormDataChange,
  handleReferentiChange,
  handleAuthUpload,
  handleEventSubmit,
  handleCallbackPerPageChange,
  handlePageChange,
  handleSort,
}) => {
  const [isIssueModal, setIsIssueModal] = useState(false);
  const [isInizioDatePickerShow, setIsInizioDatePickerShow] = useState(false);
  const [isTermineDatePickerShow, setIsTermineDatePickerShow] = useState(false);
  const [isEventModalShow, setIsEventModalShow] = useState(false);
  const [eventType, setEventType] = useState("entrata");
  const { isFileDownloading, handleBrowseFile } = useBrowseFile();

  const handleCloseIssueModal = useCallback(() => {
    setIsIssueModal(false);
  }, []);

  const isEnableUscita = useMemo(
    () => movimentazioneEvents.length % 2 === 0,
    [movimentazioneEvents]
  );

  return (
    <div className="content-wrapper">
      <div className="issue-container">
        {showIssues &&
          issues.map((issue, index) => {
            return (
              <div key={index} className="issue">
                <p className="issue-icon">
                  <ExclamationTriangleIcon />
                </p>
                {issue}
              </div>
            );
          })}
      </div>
      <div className="blocks-container">
        <div className="left-column" style={{ width: "100%" }}>
          <div className="form-block">
            <p className="section-title">GENERALITÀ</p>
            <div className="reperto-id-header">
              <p className="reperto-id-text">Reperto ID</p>
              <p
                className="copy-text"
                onClick={() => copyToClipboard(formData?.repertoId)}
              >
                {formData?.repertoId}
                <CopyIcon />
              </p>
              {Object.keys(datastructure).map((key) => (
                <FormField
                  key={key}
                  fieldStructure={datastructure[key]}
                  value={formData[key]}
                  onChange={(newValue) => handleFormDataChange(key, newValue)}
                  disabled={disabled}
                />
              ))}
            </div>
          </div>
          <div className="form-block">
            <p className="section-title">Referenti</p>
            {formData?.referenti?.map((referente, index) => {
              return (
                <FormField
                  key={index}
                  fieldStructure={{
                    label: movimentazioneReferenti.find(
                      (item) => item.value === referente.tipo
                    )?.label,
                    type: "text",
                    editable: true,
                  }}
                  value={referente.valore}
                  onChange={(newValue) =>
                    handleReferentiChange(referente.tipo, newValue)
                  }
                  disabled={disabled}
                />
              );
            })}
          </div>
          <div className="form-block">
            <DateTimePicker
              label="Inizio"
              date={formData?.inizio}
              disabled={disabled}
              onClick={() => setIsInizioDatePickerShow(true)}
            />
            <DateTimePicker
              label="Termine"
              date={formData?.termine}
              disabled={disabled}
              onClick={() => setIsTermineDatePickerShow(true)}
            />
          </div>
        </div>
        <div className="right-column" style={{ width: "100%" }}>
          <div className="form-block">
            <p className="section-title">Allegati</p>
            <FileUploadComponent
              fileData={formData?.allegati}
              acceptType=".pdf, .jpg, .png"
              disabled={disabled}
              onChange={(newValue) =>
                handleFormDataChange("allegati", newValue)
              }
            />
          </div>
          <div className="form-block">
            <BlockHeader title="Allega autorizzazione">
              {!disabled && (
                <CustomTooltip text={"Aggiungi Posizione"}>
                  <label
                    className="autorizzazione-icon-wrapper"
                    htmlFor="autorizzazione-file"
                  >
                    <CloudUploadIcon />
                    <input
                      id="autorizzazione-file"
                      hidden
                      type="file"
                      onChange={handleAuthUpload}
                    />
                  </label>
                </CustomTooltip>
              )}
            </BlockHeader>
            <div className="bibliografia-pdf-container">
              <button
                disabled={isFileDownloading}
                onClick={() =>
                  handleBrowseFile(formData?.autorizzazione?.id, "pdf")
                }
              >
                <img src={PdfImg} alt="pdf" />
                <p>{formData?.autorizzazione?.name}</p>
              </button>
              {/* <span
                className={`pdf-close-icon ${disabled ? "disabled" : ""}`}
                onClick={() => handleRemovePdf(pdf?.id)}
              >
                <CrossIcon width={20} height={20} />
              </span> */}
            </div>
          </div>
          <div className="form-block">
            <BlockHeader title="Evento di Movimento">
              {!disabled && (
                <div className="block-header-buttons">
                  {isEnableUscita ? (
                    <CustomTooltip text={"Uscita Event"}>
                      <button
                        className="repertori-button"
                        onClick={() => {
                          setIsEventModalShow(true);
                          setEventType("uscita");
                        }}
                      >
                        <OutputCircleIcon fillColor="#e15615" />
                      </button>
                    </CustomTooltip>
                  ) : (
                    <CustomTooltip text={"Entrata Event"}>
                      <button
                        className="repertori-button"
                        onClick={() => {
                          setIsEventModalShow(true);
                          setEventType("entrata");
                        }}
                      >
                        <InputCircleIcon fillColor="#e15615" />
                      </button>
                    </CustomTooltip>
                  )}
                </div>
              )}
            </BlockHeader>
            <MovimentazioneEventTable
              movimentazioneEvents={movimentazioneEvents}
              totalRows={totalRecords}
              isLoading={isLoading}
              handlePerRowsChange={handleCallbackPerPageChange}
              handlePageChange={handlePageChange}
              handleSort={handleSort}
            />
          </div>
        </div>
      </div>
      {isEventModalShow && (
        <MovimentazioneEventModal
          isModalOpen={isEventModalShow}
          movimentazioneId={formData?.id}
          eventType={eventType}
          disabled={disabled}
          closeModal={() => setIsEventModalShow(false)}
          handleSumit={handleEventSubmit}
        />
      )}
      {isInizioDatePickerShow && (
        <DatePickerModal
          show={isInizioDatePickerShow}
          handleSubmit={(selectedDate) => {
            handleFormDataChange("inizio", selectedDate);
          }}
          handleClose={() => setIsInizioDatePickerShow(false)}
        />
      )}
      {isTermineDatePickerShow && (
        <DatePickerModal
          show={isTermineDatePickerShow}
          handleSubmit={(selectedDate) => {
            handleFormDataChange("termine", selectedDate);
          }}
          handleClose={() => setIsTermineDatePickerShow(false)}
        />
      )}
      {isIssueModal && (
        <IssueModal
          isOpen={isIssueModal}
          onClose={handleCloseIssueModal}
          issues={issues}
        />
      )}
    </div>
  );
};

const DateTimePicker = ({ label, date, disabled, onClick }) => (
  <div className="movimentazione-date-time-picker">
    <div className="movimentazione-date-time-picker-header">
      <p>{label}</p>
      {!disabled && (
        <button className="date-time-picker-icon" onClick={onClick}>
          <CalendarIcon />
        </button>
      )}
    </div>
    <p>{new Date(date)?.toLocaleString()}</p>
  </div>
);

export default MovimentazioneDetailForm;
