import { deepEqual } from "../../../../helpers/utils/utils";

export const findRefData = (refData, acronym) => {
  let result,
    isFound = false;
  if (!refData) return;

  const findData = (data) => {
    if (isFound) return;

    if (data.acronym === acronym) {
      result = data;
      isFound = true;
      return;
    } else if (data.children) {
      data.children.forEach((child) => {
        findData(child);
      });
    }
  };

  refData.forEach((field) => {
    findData(field);
  });

  return result;
};

export const compareRefData = (refData, data) => {
  let hasError = false;
  if (!refData) return;

  const formKeys = Object.keys(data);
  const refKeys = Object.keys(refData);

  refKeys.forEach((key) => {
    if (!formKeys.includes(key)) {
      hasError = true;
      return;
    }

    if (key !== "children") {
      if (!deepEqual(refData[key], data[key])) {
        hasError = true;
        return;
      }
    }
  });

  return hasError;
};
