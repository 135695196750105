import CustomReactSelect from "../../../CustomReactSelect/CustomReactSelect";
import InputAndSelect from "../InputAndSelect/InputAndSelect";
import { DeleteIcon } from "../../../../assets/icons";
import "./InventoryCodeSection.scss";

const InventoryCodeSection = ({
  inventoryCodeNo,
  inventoryCode,
  tipoJson,
  headers,
  handleTipoChange,
  handleSelectChange,
  handleInputChange,
  handleDeleteInventory,
}) => {
  const onSelectChange = (option, label) => {
    handleSelectChange("inventoryCode", option, label, inventoryCode?.id);
  };

  const onInputChange = (e, label) => {
    handleInputChange(e, "inventoryCode", label, inventoryCode?.id);
  };

  const onTipoChange = (option) => {
    handleTipoChange("inventoryCode", option, inventoryCode?.id);
  };

  const onDeleteInventory = () => {
    handleDeleteInventory(inventoryCode?.id);
  };

  return (
    <div className="modal-upload-section-wrapper">
      <div className="origin-delete-wrapper">
        <button onClick={onDeleteInventory}>
          <DeleteIcon />
        </button>
      </div>
      <CustomReactSelect
        options={tipoJson}
        value={inventoryCode?.selectedTipo}
        displayAttr={"value"}
        label="Tipo"
        placeholder="Seleziona il tipo"
        onChange={onTipoChange}
      />
      <InputAndSelect
        options={headers}
        initSelect={inventoryCode?.selectedCodice}
        initInput={inventoryCode?.codice?.value}
        displayAttr="value"
        label="Codice"
        attr={"codice"}
        placeholder="Seleziona il codice"
        onChange={onSelectChange}
        onInputChange={onInputChange}
      />
    </div>
  );
};

export default InventoryCodeSection;
