import { useMemo } from "react";
import { ExclamationTriangleIcon, AddIcon } from "../../../assets/icons";
import FormField from "../../FormField/FormField";
import GroupsFormField from "../../GroupsFormField";
import Raccolta from "../Raccolta/Raccolta";
import cassettaStructure from "../../../constants/dataStructure/cassetta.json";
import Posizione from "../Posizione/Posizione";
import Tabs from "../../Common/Tabs/Tabs";

export default function CassetteRecordDetailForm({
  formData,
  raccolteData,
  depositiData,
  handleAddRaccolta,
  handleDeleteRaccolta,
  handleFormDataChange,
  handleRaccoltaChange,
  disabled,
  issues,
  showIssues,
}) {
  const tabs = useMemo(() => {
    return raccolteData.map((raccolta, index) => ({
      id: raccolta.id,
      label: raccolta.nome || `Tab${index + 1}`,
      content: (
        <Raccolta
          raccolta={raccolta}
          handleFieldChange={(key, newValue) =>
            handleRaccoltaChange(raccolta.id, key, newValue)
          }
          handleDeleteRaccolta={() => handleDeleteRaccolta(raccolta)}
          edit={true}
        />
      ),
    }));
  }, [raccolteData, handleRaccoltaChange, handleDeleteRaccolta]);

  return (
    <>
      <main className="cassette-main-container">
        <div className="content-wrapper">
          <div className="issue-container">
            {showIssues &&
              issues.map((issue, index) => {
                return (
                  <div key={index} className="issue">
                    <p className="issue-icon">
                      <ExclamationTriangleIcon />
                    </p>
                    {issue}
                  </div>
                );
              })}
          </div>
          <div className="blocks-container">
            <div className="left-column" style={{ width: "100%" }}>
              <div className="form-block">
                <p className="section-title"> GENERALITÀ</p>
                <FormField
                  fieldStructure={cassettaStructure["id"]}
                  value={formData["id"]}
                  onChange={(newValue) => handleFormDataChange("id", newValue)}
                  disabled={false}
                />
                <GroupsFormField
                  groups={formData["groups"]}
                  onChange={(newValue) =>
                    handleFormDataChange("groups", newValue)
                  }
                  edit={true}
                />
                <FormField
                  fieldStructure={cassettaStructure["frontalino"]}
                  value={formData["frontalino"]}
                  onChange={(newValue) =>
                    handleFormDataChange("frontalino", newValue)
                  }
                  disabled={false}
                />
              </div>
              <div className="form-block">
                <Posizione
                  formData={formData}
                  depositiData={depositiData}
                  cassettaStructure={cassettaStructure}
                  handleFormDataChange={handleFormDataChange}
                />
              </div>
            </div>
            <div className="right-column" style={{ width: "100%" }}>
              <div className="form-block">
                <div className="form-block-header">
                  <p className="form-block-header-title"> SCOMPARTI</p>
                  <button
                    className="form-block-button"
                    onClick={handleAddRaccolta}
                  >
                    <AddIcon fillColor="white" />
                    <span>Aggiungi scomparto</span>
                  </button>
                </div>
                <Tabs tabs={tabs} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
