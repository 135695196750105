import React from "react";
import ImageModal from "../Common/ImageModal/ImageModal";

const ImageView = ({ image, show, handleClose }) => {
  if (!image) return null;

  return (
    <ImageModal title="Image View" isVisible={show} onClose={handleClose}>
      <div className="image-view-container">
        <img
          src={image.url}
          style={{
            width: "100%",
            height: "auto",
          }}
          className="main-image"
          alt={`img- ${image}`}
        />
      </div>
      <style jsx="true">
        {`
          .image-view-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 80vw;
            padding: 1rem;
          }
          .icon {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            background: transparent;
            border: none;
          }
        `}
      </style>
    </ImageModal>
  );
};

export default ImageView;
