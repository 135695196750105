import { useState } from "react";
import { ExclamationTriangleIcon } from "../../../../assets/icons/index.js";
import ReactModal from "../../../ReactModal/ReactModal.jsx";
import FormField from "../../../FormField/FormField.js";
import IccdEditor from "../../IccdEditor";
import Provenienze from "../../../Provenienze";
import Repertori from "../../../Repertori";
import repertoStructure from "../../../../constants/dataStructure/reperto.json";
import GroupsFormField from "../../../GroupsFormField";
import iccdManifest from "../../../../assets/json/ICCD-4.00-manifest-v0.2.json";

export default function RepertiRecordDetailForm({
  data,
  setData,
  onRaccoltaChange,
  disabled,
  issues,
  showIssues,
}) {
  //const [cassettaId, setCassettaId] = useState("");
  //const navigate = useNavigate(); // Use useNavigate hook
  //const location = useLocation();
  const [iccdOpen, setIccdOpen] = useState(false);

  const handleFieldChange = (key, newValue) => {
    setData((prevData) => ({
      ...prevData,
      [key]: newValue,
    }));
  };

  // console.log("RepertiRecordDetailForm", disabled, issues, showIssues);
  return (
    <>
      <main className="reperti-main-container">
        <div className="content-wrapper">
          <div className="issue-container">
            {!disabled &&
              showIssues &&
              issues.map((issue, index) => {
                return (
                  <div key={index} className="issue">
                    <p className="issue-icon">
                      <ExclamationTriangleIcon />
                    </p>
                    {issue}
                  </div>
                );
              })}
          </div>
          <div className="blocks-container">
            <div className="form-block" id="generalità">
              <p className="section-title"> GENERALITÀ</p>
              <GroupsFormField
                groups={data["groups"]}
                onChange={(newValue) => handleFieldChange("groups", newValue)}
                edit={true}
              />
              <Repertori
                repertori={data["codiciInventario"]}
                onChange={(newValue) =>
                  handleFieldChange("codiciInventario", newValue)
                }
                edit={true}
              />
            </div>
            <div className="form-block" id="scheda">
              <p className="section-title"> SCHEDA ICCD</p>
              <div className="iccd-button-container">
                <button
                  className="class-iccd-btn btn"
                  onClick={() => setIccdOpen(true)}
                >
                  APRI SCHEDA
                </button>
              </div>
            </div>
            <div className="form-block" id="provenienza">
              <p className="section-title"> PROVENIENZA</p>
              <FormField
                fieldStructure={repertoStructure["anno"]}
                value={data["anno"]}
                // value={data["anno"] ? data["anno"].value : ""}
                onChange={(newValue) => handleFieldChange("anno", newValue)}
                disabled={false}
              />
              <Provenienze
                provenienze={data["provenienze"]}
                onChange={(newValue) =>
                  handleFieldChange("provenienze", newValue)
                }
                edit={true}
              />
            </div>
            <div className="form-block" id="caratteristiche">
              <div className="blockHeader">
                <p className="section-title"> CARATTERISTICHE</p>
              </div>
              <FormField
                fieldStructure={repertoStructure["materia"]}
                //value={data["materiale"] ? data["materiale"].value : ""}
                value={data["materiale"]}
                onChange={(newValue) =>
                  handleFieldChange("materiale", newValue)
                }
                disabled={false}
              />
              <FormField
                fieldStructure={repertoStructure["classe"]}
                value={data["classe"]}
                onChange={(newValue) => handleFieldChange("classe", newValue)}
                disabled={false}
              />
              <FormField
                fieldStructure={repertoStructure["forma"]}
                //value={data["forma"] ? data["forma"].value : ""}
                value={data["forma"]}
                onChange={(newValue) => handleFieldChange("forma", newValue)}
                disabled={false}
              />
              <FormField
                fieldStructure={repertoStructure["tipo"]}
                //value={data["tipo"] ? data["tipo"].value : ""}
                value={data["tipo"]}
                onChange={(newValue) => handleFieldChange("tipo", newValue)}
                disabled={false}
              />
              <FormField
                fieldStructure={repertoStructure["cronologia"]}
                //value={data["cronologia"] ? data["cronologia"].value : ""}
                value={data["cronologia"]}
                onChange={(newValue) =>
                  handleFieldChange("cronologia", newValue)
                }
                disabled={false}
              />
            </div>
            <div className="form-block" id="collocazione">
              <div className="blockHeader">
                <p className="section-title">COLLOCAZIONE</p>
              </div>
              <FormField
                fieldStructure={{
                  label: "Tipo di Collocazione",
                  type: "string",
                  editable: false,
                }}
                value={
                  data["raccolta"] && data["raccolta"].cassettaId
                    ? "Deposito"
                    : "-"
                }
                onChange={() => {}}
                disabled={true}
              />
              <FormField
                fieldStructure={{
                  label: "Identificativo Cassetta",
                  type: "string",
                  editable: true,
                }}
                value={data["raccolta"] ? data["raccolta"].cassettaId : "-"}
                onChange={() => {}}
                disabled={true}
              />
              <FormField
                fieldStructure={{
                  label: "Identificativo scomparto",
                  type: "string",
                  editable: true,
                }}
                value={data["raccolta"] ? data["raccolta"].raccoltaId : "-"}
                onChange={(newValue) => onRaccoltaChange(newValue)}
                disabled={false}
              />
            </div>
          </div>
        </div>
        <ReactModal isOpen={iccdOpen} onClose={() => setIccdOpen(false)}>
          <IccdEditor
            manifest={iccdManifest}
            data={data["iccd"]}
            edit={true}
            visibility={1}
            knownData={{}}
            onSave={(value) => handleFieldChange("iccd", value)}
            onClose={() => setIccdOpen(false)}
          />
        </ReactModal>
      </main>
    </>
  );
}
