import { useState, useMemo, useRef, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import "./style.css";

import CustomToggleButton from "../../../CustomToggleButton";
import { isEmpty } from "../../../../helpers/utils/utils";
import { badgeColor } from "../helper";

const FormField = ({
  isView,
  fieldType = "",
  dictionary = {},
  use,
  id,
  placeholder = "",
  minLen,
  maxLen,
  status,
  duplicated = false,
  storeValue,
  initStatus,
  storeSelectedOption,
  handleSelectChange,
  handleInputChange,
  onToggle,
}) => {
  const [value, setValue] = useState(storeValue);
  const [selectedId, setSelectedId] = useState(id);
  const [selectedOption, setSelectedOption] = useState(storeSelectedOption);
  const [options, setOptions] = useState([]);
  const divRef = useRef(null);

  const {
    type = "json",
    url = "",
    value: dropdownValue = "",
    description = "",
    filter = {},
  } = dictionary;

  useEffect(() => {
    setValue(storeValue);
    setSelectedOption(storeSelectedOption);
    setSelectedId(id);
  }, [storeSelectedOption, storeValue, id]);

  useEffect(() => {
    if (type === "json" && url.includes("http") && url.includes("json")) {
      axios
        .get(url)
        .then((response) => {
          const arr = response.data.map((item) => ({
            value: item[dropdownValue],
            description: item[description],
          }));
          setOptions(arr);
        })
        .catch((err) => {
          console.log("error==>>", err);
        });
    }
  }, [url, type, dropdownValue, description]);

  const isOver = useMemo(() => value.length > maxLen, [value, maxLen]);
  const isLess = useMemo(() => value.length < minLen, [value, minLen]);

  const handleInput = (e) => {
    setValue(e.target.value);
    divRef.current.style.height = "auto";
    divRef.current.style.height = `${divRef.current.scrollHeight}px`;
    handleInputChange(e.target.value, selectedId);
  };

  const onSelectedChange = (option) => {
    setSelectedOption(option);
    handleSelectChange(option, selectedId);
  };

  return (
    <div className="field-wrapper">
      {fieldType === "drop-down" || fieldType === "text-field" ? (
        fieldType === "drop-down" ? (
          options.length ? (
            <div className="form-child-field">
              {isView ? (
                <p>
                  {selectedOption
                    ? `${selectedOption?.value} | ${selectedOption?.description}`
                    : ""}
                </p>
              ) : (
                <Select
                  options={options}
                  formatOptionLabel={(option) => (
                    <p>
                      {isEmpty(option?.description)
                        ? option.value
                        : `${option.value} | ${option.description}`}
                    </p>
                  )}
                  value={selectedOption}
                  placeholder="Selezione una opzione"
                  onChange={onSelectedChange}
                />
              )}
            </div>
          ) : null
        ) : isView ? (
          <p>{value ? value : "_ _ _"}</p>
        ) : (
          <div className="form-child-field input">
            <div className="form-child-input-wrapper">
              {/* <div
                ref={divRef}
                className="custom-grow-input"
                contentEditable={true}
                placeholder={placeholder ? placeholder : "Type here..."}
                onInput={handleInput}
              ></div> */}
              <textarea
                ref={divRef}
                className="grow-textarea"
                value={value}
                maxLength={maxLen}
                onChange={handleInput}
                placeholder={placeholder ? placeholder : "Type here..."}
              />
            </div>
            <div className="min-max">
              {isOver ? (
                <p className="input-warning-msg">
                  Il numero di caratteri deve essere inferiore a {maxLen}
                </p>
              ) : isLess ? (
                <p className="input-warning-msg">
                  Il testo deve contenere almeno {minLen} caratteri
                </p>
              ) : (
                <p>Numero corrente di caratteri: {value.length}</p>
              )}
              <p>
                {minLen} - {maxLen}
              </p>
            </div>
          </div>
        )
      ) : use === "optional" && !duplicated ? (
        <div className="field-sub-wrapper">
          <p className="element-header-status">
            <span className = "element-header-status-description">LIVELLO DI COMPLETAMENTO: </span>
            <span className="element-header-status-badge" style={{backgroundColor: badgeColor(status), borderColor:badgeColor(status) }}>{status}</span>
          </p>
          <CustomToggleButton initialState={initStatus} onToggle={onToggle} />
        </div>
      ) : (
        <div className="field-sub-wrapper">
          <p className="element-header-status">
            <span className="element-header-status-description">LIVELLO DI COMPLETAMENTO: </span>
            <span className="element-header-status-badge" style={{backgroundColor: badgeColor(status), borderColor:badgeColor(status) }}>{status}</span>
          </p>
          <p style={{width: "4rem"}}></p>
        </div>
      )}
    </div>
  );
};

export default FormField;
