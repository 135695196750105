import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LogOutIcon,
  PasswordIcon,
  ProfileIcon,
} from "../../../assets/icons/index.js";
import useOnOutsideClick from "../../../hooks/useOutsideClick";
import { SubHeaderMobileOption } from "../../SubHeaderMobileOption/SubHeaderMobileOption";
import { DownIcons } from "../../../assets/icons";
import GroupsFormField from "../../GroupsFormField";
import { useAppContext } from "../../Context/AppContext";
import "./ProfileCard.scss";

function Avatar({ name, image }) {
  return (
    <div className="avatar">
      {name?.length && <div className="avatar-initials">{name[0]}</div>}
    </div>
  );
}

function userRole(user = {}) {
  let role = "User";
  if (user.roles)
    user.roles.find((r) => r.name === "Amministratore")
      ? (role = "Amministratore")
      : (role = user.roles[0].name);
  return role;
}

function UserInfo({ userName, appVersion, image }) {
  return (
    <div className="user-info">
      <div className="user-details">
        <span className="user-name">{userName}</span>
        <DownIcons />
      </div>
      <div className="app-version">{appVersion}</div>
    </div>
  );
}

function ProfileCard({ user }) {
  const { appVersion } = useAppContext();
  const navigate = useNavigate();
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const divRef = useRef(null);
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    navigate("/");
  };

  // console.log("===| user profile card", user);
  useOnOutsideClick(divRef, () => setTooltipOpen(false));
  if (!user || !user.roles) return <> </>;

  let toolTipIcons = [
    {
      icon: <PasswordIcon />,
      onClick: () => {
        navigate("/passwordchange");
      },
      label: "Modifica password",
    },
    {
      icon: <LogOutIcon />,
      onClick: () => {
        handleLogout();
        navigate("/");
      },
      label: "LogOut",
    },
  ];

  if (user.roles.find((r) => r.authorities.find((a) => a === "ADMIN"))) {
    toolTipIcons = [
      {
        icon: <ProfileIcon />,
        onClick: () => navigate("/users"),
        label: "Ammministrazione",
      },
      ...toolTipIcons,
    ];
  }

  return (
    <>
      <div
        className="profile-card"
        onClick={(e) => {
          e.stopPropagation();
          setTooltipOpen(!isTooltipOpen);
        }}
      >
        <Avatar name={user.name} image={user.image} />
        <UserInfo
          userName={user.username}
          appVersion={`Archeo ${appVersion}`}
          image={user.image}
        />
        {isTooltipOpen ? (
          <div className="profile-tooltip-options" ref={divRef}>
            {/*<p className="archeo-version">Archeo v {appVersion}</p> */}
            <GroupsFormField
              title="I miei ruoli"
              edit={false}
              groups={user.roles}
              onChange={() => {
                /* DO NOTHING */
              }}
            />
            <GroupsFormField
              title="I miei gruppi"
              edit={false}
              groups={user.groups}
              onChange={() => {
                /* DO NOTHING */
              }}
            />
            {toolTipIcons?.map(({ icon, label, onClick = () => {} }, index) => (
              <div
                key={index}
                className={`tooltip-option ${
                  index === 0 ? "administration-option" : ""
                } ${index === 1 ? "logout-option" : ""}`}
                onClick={onClick}
              >
                {icon}
                {label}
              </div>
            ))}
          </div>
        ) : null}
      </div>
      {isTooltipOpen ? (
        <SubHeaderMobileOption
          title="User Menu options"
          icons={toolTipIcons}
          onCrossClick={() => setTooltipOpen(false)}
        />
      ) : null}
    </>
  );
}

export default ProfileCard;
