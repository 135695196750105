import React, { useCallback, useMemo, useState } from "react";
import "./Movimentazione.scss";
import { useNavigate } from "react-router-dom";
import DataTableSubHeader from "../../Components/DataTableSubHeader/DataTableSubHeader.jsx";
import DataDropdown from "../../Components/DataDropdown";
import ItemsTable from "../../Components/ItemsTable/ItemsTable.jsx";
import ReactModal from "../../Components/ReactModal/ReactModal.jsx";
import FilterModal from "../../Components/FilterModal/FilterModal.jsx";
import Spinner from "../../Components/ProgressBar/Spinner.js";
import {
  DeleteIcon,
  DownloadIcon,
  EyeIcon,
  FilterIcon,
} from "../../assets/icons/index.js";
import { SubHeaderMobileOption } from "../../Components/SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { getMovimentazione, deleteMovimentazione } from "../../api";
import { toast } from "react-toastify";
import datastructure from "../../constants/dataStructure/movimentazione.json";
import useFetchData from "../../hooks/useFetchData.js";
import userHasPermission from "../../helpers/formTools/userHasPermission/index.js";
import jsonToXlsx from "../../helpers/formTools/transformations/jsonToXlsx/jsonToXlsx.js";
import { useAppContext } from "../../Components/Context/AppContext.jsx";

const breadcrumbsData = [
  { id: 1, label: "Dati Archeologici", iconUrl: true },
  { id: 2, label: "Movimentazione", iconUtl: true },
];

const headers = [
  { key: "actions", label: "", width: "100px", sortable: false },
  { key: "id", label: "Identificativo", width: "180px", sortable: false },
  { key: "tipo", label: "Tipo", width: "100px", sortable: false },
  {
    key: "destinazione",
    label: "Destinazione",
    width: "100px",
    sortable: false,
  },
  { key: "inizio", label: "Inizio", width: "120px", sortable: false },
  { key: "termine", label: "Termine", width: "120px", sortable: false },
  { key: "repertoId", label: "Reperto ID", width: "150px", sortable: false },
  { key: "note", label: "Note", width: "200px", sortable: false },
];

const filterValues = [
  { label: "id", key: "id" },
  { label: "tipo", key: "tipo" },
  { label: "destinazione", key: "destinazione" },
  { label: "inizio", key: "inizio" },
  { label: "termine", key: "termine" },
  { label: "repertoId", key: "repertoId" },
  { label: "note", key: "note" },
];

function Movimentazione() {
  const navigate = useNavigate();
  const [isMenuActive, setMenuActive] = useState(false);
  const [recordFilterModalOpen, setRecordFilterModalOpen] = useState(false);

  const {
    movimentazioneFilter,
    setMovimentazioneFilter,
    setMovimentazioneId,
    userProfile,
  } = useAppContext();

  const customFilter = useMemo(
    () => [...movimentazioneFilter, { key: "sort", value: "inizio, desc" }],
    [movimentazioneFilter]
  );

  const {
    data,
    isLoading,
    currentPage,
    recordsPerPage,
    totalRecords,
    totalPages,
    sortDirection,
    setCurrentPage,
    setRecordsPerPage,
    setSortDirection,
  } = useFetchData(getMovimentazione, customFilter);

  const removeMovimentazione = useCallback(async (id) => {
    try {
      await deleteMovimentazione(id);
      toast.success("Data deleted successfully!");
      setTimeout(() => {
        window.location.reload();
      }, 20);
    } catch (error) {
      toast.error("Error deleting data");
    }
  }, []);

  const handlePageChange = useCallback(
    (newPage) => {
      setCurrentPage(newPage);
    },
    [setCurrentPage]
  );

  const handleRecordsPerPageChange = useCallback(
    (newRecordsPerPage) => {
      setRecordsPerPage(newRecordsPerPage);
      setCurrentPage(1);
    },
    [setCurrentPage, setRecordsPerPage]
  );

  const handleCallBackSort = useCallback(
    (key) => {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
      setMovimentazioneFilter([
        { key: "sort", value: `${key},${sortDirection}` },
      ]);
    },
    [setMovimentazioneFilter, sortDirection, setSortDirection]
  );

  const closeRecordFilterModal = useCallback(() => {
    setRecordFilterModalOpen(false);
  }, []);

  const handleSetMovimentazioneFilter = useCallback((filter) => {
    console.log("==| handleSetMovimentazioneFilter", filter);
  }, []);

  const handleCallBackFilter = useCallback(
    (data) => {
      const filter = data.map((item) => {
        return { key: item.label, value: item.value };
      });
      setMovimentazioneFilter(filter);
      return true;
    },
    [setMovimentazioneFilter]
  );

  const handleReset = (resetStatus) => {
    // setIsReset(resetStatus);
    // if (resetStatus) {
    //   // Perform additional actions if needed when reset is true
    //   filterRef.current = false;
    // }
  };

  const downloadCSVData = useCallback(
    (e) => {
      const jsonArr = data.map(({ actions, ...rest }) => rest);
      jsonToXlsx(jsonArr, "movimentazione.xlsx");
    },
    [data]
  );

  const handleFilterIconClick = useCallback((e) => {
    setRecordFilterModalOpen(true);
  }, []);

  const convertedData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    let mappedData = []; // Initialize the array to store the converted data
    data.forEach((result) => {
      let jsonObject = { id: { value: result.id }, actions: { value: [] } }; // Initialize the row object

      // Convert row attributes in the right format base on a key and a value to be displayed
      for (const key in result) {
        if (result.hasOwnProperty(key)) {
          let value;
          if (datastructure[key] && datastructure[key].type === "object") {
            value = result[key][datastructure[key].objectName];
          } else {
            value =
              result[key] !== undefined && result[key] !== null
                ? result[key].toString()
                : "";
          }
          jsonObject[key] = {
            value,
          };
        }
      }

      // Identifies the ID of the record
      const Id = jsonObject.id.value.replace(/^"|"$/g, "");

      // Add the actions to the row
      jsonObject.actions = {
        value: [],
        component: (
          <>
            <div
              onClick={() => {
                navigate("/movimentazione/view");
                setMovimentazioneId(Id);
              }}
              className="row-icon"
              style={{ cursor: "pointer" }}
            >
              <EyeIcon />
            </div>
            {userHasPermission({
              userProfile,
              resource: "MOVIMENTAZIONE",
              resourceGroups: result.groups,
              permission: "DELETE",
            }) && (
              <div
                onClick={() => removeMovimentazione(Id)}
                style={{ cursor: "pointer" }}
              >
                <DeleteIcon />
              </div>
            )}
          </>
        ),
      };
      mappedData.push(jsonObject);
    });
    return mappedData;
  }, [data, navigate, setMovimentazioneId, userProfile, removeMovimentazione]);

  const subHeaderRightNavOptions = {
    icons: [
      {
        icon: <FilterIcon />,
        onClick: handleFilterIconClick,
        label: "Filtro",
      },
      {
        icon: <DownloadIcon />,
        label: "Download",
        onClick: downloadCSVData,
      },
    ],
    components: [<DataDropdown defaultSelected="MOVIMENTAZIONE" />],
  };

  return (
    <>
      <div className="container">
        <main className="record-main-content">
          <DataTableSubHeader
            title={"MOVIMENTAZIONI"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
        </main>
        <ReactModal
          isOpen={recordFilterModalOpen}
          onClose={closeRecordFilterModal}
        >
          <FilterModal
            closeModal={closeRecordFilterModal}
            filterValues={filterValues}
            setFilterValue={handleSetMovimentazioneFilter}
            callBackFilter={handleCallBackFilter}
            onReset={handleReset}
          />
        </ReactModal>
        {isLoading ? (
          <Spinner />
        ) : (
          <ItemsTable
            data={convertedData}
            headers={headers}
            filterValues={filterValues}
            currentPage={currentPage}
            recordsPerPage={recordsPerPage}
            totalRecords={totalRecords}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            onRecordsPerPageChange={handleRecordsPerPageChange}
            callBackSort={handleCallBackSort}
          />
        )}
        {isMenuActive && (
          <SubHeaderMobileOption
            components={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}

export default Movimentazione;
