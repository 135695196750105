import React from "react";
import { useNavigate } from "react-router-dom";
import { CrossIcon, AddIcon } from "../assets/icons";

const useAddSubHeaderRightNavOptions = (handleSave) => {
  const navigate = useNavigate();

  const subHeaderRightNavOptions = {
    icons: [],
    components: [
      <button onClick={() => navigate(-1)} className="cancel-button">
        <CrossIcon />
        <span className="login-text">Cancel</span>
      </button>,
      <button onClick={handleSave} className="save-button">
        <AddIcon fillColor="#fff" />
        <span className="login-text">Save</span>
      </button>,
    ],
  };

  return { subHeaderRightNavOptions };
};

export default useAddSubHeaderRightNavOptions;
