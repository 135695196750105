import React, { useEffect, useRef, useState } from "react";
import "./Cassette.scss";
import ItemsTable from "../../Components/ItemsTable/ItemsTable.jsx";
import DataTableSubHeader from "../../Components/DataTableSubHeader/DataTableSubHeader.jsx";
import { useNavigate } from "react-router-dom";
import DataDropdown from "../../Components/DataDropdown/index.jsx";
import CassetteRecordFileUploadModal from "../../Components/Cassette/CassetteRecordFileUploadModal/CassetteRecordFileUploadModal.jsx";
import ReactModal from "../../Components/ReactModal/ReactModal.jsx";
import FilterModal from "../../Components/FilterModal/index.jsx";
import {
  AddIcon,
  CloudIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  FilterIcon,
} from "../../assets/icons/index.js";
import { SubHeaderMobileOption } from "../../Components/SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import datastructure from "../../constants/dataStructure/cassetta.json";
import { getCassette, getComuni, deleteCassetta } from "../../api/index.js";
import { useAppContext } from "../../Components/Context/AppContext.jsx";
import jsonToXlsx from "../../helpers/formTools/transformations/jsonToXlsx/index.js";
import userHasPermission from "../../helpers/formTools/userHasPermission/index.js";
import Spinner from "../../Components/ProgressBar/Spinner.js";

function Cassette() {
  const navigate = useNavigate();
  const {
    cassetteFilter,
    comuni,
    setCassettaId,
    setCassetteFilter,
    setComuni,
    userProfile,
  } = useAppContext();
  const [recordFileUploadModalOpen, setRecordFileUploadModalOpen] =
    useState(false);
  const [isMenuActive, setMenuActive] = useState(false);
  const [recordFilterModalOpen, setRecordFilterModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [convertedData, setConvertedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const filterRef = useRef(false);
  const [isReset, setIsReset] = useState(false);
  const [sortDirection, setSortDirection] = useState("asc");
  const [isLoadingCassette, setIsLoadingCassette] = useState(false);
  const userCanCreate = userHasPermission({
    userProfile,
    resource: "CASSETTA",
    permission: "CREATE",
  });

  /* ************************************************************ */
  // ALL API CALLS

  const listComuni = async () => {
    try {
      const data = await getComuni([
        { key: "pageSize", value: 1000 },
        { key: "page", value: 1 },
        { key: "sort", value: "nome,asc" },
      ]);
      if (Array.isArray(data.results)) {
        setComuni(data.results.filter((comune) => comune.nome !== "ITALIA"));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error(`Authorization error fetching "comuni"`);
      } else {
        console.error(`Error fetching "comuni"`, error);
      }
    }
  };

  async function listCassette() {
    const initialFilter = [
      { key: "page", value: currentPage },
      { key: "pageSize", value: recordsPerPage },
    ];
    const filter = [...initialFilter, ...cassetteFilter];
    setIsLoadingCassette(true);
    try {
      const cassette = await getCassette(filter);
      setData(cassette.results);
      setTotalRecords(cassette.totalElements);
      setTotalPages(cassette.totalPages);
      return true;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error(`Errore di autorizzazione su "cassette"`);
      } else {
        console.error(`Error listing "cassette`, error);
        toast.error(`Errore sconosciuto ("cassette")`);
      }
    } finally {
      setIsLoadingCassette(false);
    }
  }

  async function removeCassetta(id) {
    try {
      const response = await deleteCassetta(id);
      toast.success("Cassetta cancellata con successo");
      setTimeout(() => {
        window.location.reload();
      }, 20); // 2000 milliseconds = 2 seconds
      return response;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        toast.error(`Errore di autenticazione nella rimozione di una cassetta`);
      } else {
        console.error(`Error deleting "cassetta" record`, error);
        toast.error(`Errore sconosciuto nella rimozione di una cassetta`);
      }
    }
  }

  /* ********************************************************* */
  // ALL EFFECTS

  // GET THE UPDATED LIST OF MUNICIPALITIES
  useEffect(() => {
    listComuni();
    return () => {};
  }, []);

  // HERE YOU FETCH UPDATED DATA FROM CASSETTE
  useEffect(() => {
    listCassette();
    return () => {};
  }, [cassetteFilter, currentPage, recordsPerPage, sortDirection]);

  // HERE YOU CONVERT THE DATA FOR THE TABLE
  useEffect(() => {
    if (!Array.isArray(data)) return;
    let mappedData = []; // Initialize the array to store the converted data
    if (Array.isArray(data))
      data.forEach((result, index) => {
        let jsonObject = {}; // Initialize the row object
        jsonObject = { id: { value: result.id }, actions: { value: [] } }; // Initialize the row object

        // Convert row attributes in the right format base on a key and a value to be displayed
        for (const key in result) {
          if (result.hasOwnProperty(key)) {
            let value;
            if (datastructure[key] && datastructure[key].type === "object") {
              value = result[key][datastructure[key].objectName];
            } else {
              value =
                result[key] !== undefined && result[key] !== null
                  ? result[key].toString()
                  : "";
            }
            jsonObject[key] = {
              value,
            };

            // SPECIAL CASE FOR RACCOLTE
            if (key === "raccolte") {
              jsonObject["sito"] = {
                value: result[key]
                  .map((raccolta) => {
                    const scavo = raccolta.provenienze?.find(
                      (o) => o.tipo === "scavo"
                    );
                    return scavo ? scavo.valore : null;
                  })
                  .filter((o) => o !== null)
                  .join(", "),
              };
              jsonObject["anno"] = {
                value: result[key]
                  .map((raccolta) => {
                    return raccolta.annoScavo;
                  })
                  .filter((o) => o !== null)
                  .join(", "),
              };
            }
          }
        }

        // Identifies the ID of the record
        const Id = jsonObject.id.value.replace(/^"|"$/g, "");

        // Add the actions to the row
        jsonObject.actions = {
          value: [],
          component: (
            <>
              <div
                onClick={() => {
                  navigate("/cassette/view");
                  setCassettaId(Id);
                }}
                className="row-icon"
                style={{ cursor: "pointer" }}
              >
                <EyeIcon />
              </div>
              {userHasPermission({
                userProfile,
                resource: "CASSETTA",
                resourceGroups: result.groups,
                permission: "DELETE",
              }) && (
                <div
                  onClick={() => removeCassetta(Id)}
                  style={{ cursor: "pointer" }}
                >
                  <DeleteIcon />
                </div>
              )}
            </>
          ),
        };
        mappedData.push(jsonObject);
      });
    setConvertedData(mappedData);
    return () => {};
  }, [data]);

  /* ********************************************************** */
  // ALL HANDLERS

  const handleCallBackFilter = (data) => {
    const filter = data.map((item) => {
      return { key: item.label, value: item.value };
    });
    setCassetteFilter(filter); // Update filterValue state
    return true;
  };

  const handleReset = (resetStatus) => {
    setIsReset(resetStatus);
    if (resetStatus) {
      // Perform additional actions if needed when reset is true
      filterRef.current = false;
    }
  };

  const downloadCSVData = (e) => {
    const jsonArr = data.map(({ actions, ...rest }) => rest);
    jsonToXlsx(jsonArr, "cassette.xlsx");
  };

  async function handleCallBackSort(key) {
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    setCassetteFilter([{ key: "sort", value: `${key},${sortDirection}` }]);
  }

  const handleFileUploadIconClick = (e) => {
    setRecordFileUploadModalOpen(true);
  };

  const handleFilterIconClick = (e) => {
    setRecordFilterModalOpen(true);
  };

  const closeRecordFileUploadModal = () => {
    setRecordFileUploadModalOpen(false);
  };

  const closeRecordFilterModal = () => {
    setRecordFilterModalOpen(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRecordsPerPageChange = (newRecordsPerPage) => {
    setRecordsPerPage(newRecordsPerPage);
    setCurrentPage(1); // Reset to first page when records per page changes
  };

  const handleSetCassetteFilter = (filter) => {
    console.log("==| handleSetCassetteFilter", filter);
  };

  /* ********************************************************** */
  // NAVIGATION
  const breadcrumbsData = [
    { id: 1, label: "Dati Archeologici", iconUrl: true },
    { id: 2, label: "Cassette" },
  ];

  const subHeaderRightNavOptions = {
    icons: [
      userCanCreate && {
        icon: <CloudIcon />,
        onClick: handleFileUploadIconClick,
        label: "Upload",
      },
      userCanCreate && {
        icon: <AddIcon />,
        label: "Aggiungi cassetta",
        onClick: () => {
          navigate("/cassette/add");
          return null;
        },
      },
      { icon: <FilterIcon />, onClick: handleFilterIconClick, label: "Filtro" },
      {
        icon: <DownloadIcon />,
        label: "Scarica lista",
        onClick: () => {
          downloadCSVData();
        },
      },
    ],
    components: [<DataDropdown defaultSelected="CASSETTE" />],
  };

  /* *********************************************************** */
  // POSSIBLE FILTERS
  const filterValues = [
    { label: "id", key: "id" },
    { label: "frontalino", key: "frontalino" },
    { label: "deposito", key: "deposito" },
    { label: "piano", key: "piano" },
    { label: "scaffale", key: "scaffale" },
    { label: "ripiano", key: "ripiano" },
  ];

  /* ********************************************************** */
  // TABLE HEADERS
  const headers = [
    { key: "actions", label: "", width: "100px", sortable: false },
    { key: "id", label: "Identificativo", width: "180px", sortable: false },
    {
      key: "frontalino",
      label: "Dati da frontalino",
      width: "400px",
      sortable: false,
    },
    { key: "deposito", label: "Deposito", width: "100px", sortable: false },
    { key: "piano", label: "Piano", width: "100px", sortable: false },
    { key: "ripiano", label: "Ripiano", width: "100px", sortable: false },
    { key: "scaffale", label: "Scaffale", width: "100px", sortable: false },
    { key: "anno", label: "Anno di scavo", width: "100px", sortable: false },
    { key: "sito", label: "Area di scavo", width: "100px", sortable: false },
  ];

  /* ********************************************************** */

  return (
    <>
      <div className="container">
        <main className="record-main-content">
          <DataTableSubHeader
            title={"CASSETTE"}
            breadcrumbsData={breadcrumbsData}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <ReactModal
            isOpen={recordFileUploadModalOpen}
            onClose={closeRecordFileUploadModal}
          >
            <CassetteRecordFileUploadModal
              closeModal={closeRecordFileUploadModal}
            />
          </ReactModal>
          <ReactModal
            isOpen={recordFilterModalOpen}
            onClose={closeRecordFilterModal}
          >
            <FilterModal
              closeModal={closeRecordFilterModal}
              filterValues={filterValues}
              setFilterValue={handleSetCassetteFilter}
              callBackFilter={handleCallBackFilter}
              onReset={handleReset}
            />
          </ReactModal>
          {isLoadingCassette ? (
            <Spinner />
          ) : (
            <ItemsTable
              data={convertedData}
              headers={headers}
              filterValues={filterValues}
              currentPage={currentPage}
              recordsPerPage={recordsPerPage}
              totalRecords={totalRecords}
              totalPages={totalPages}
              onPageChange={handlePageChange}
              onRecordsPerPageChange={handleRecordsPerPageChange}
              callBackSort={handleCallBackSort}
            />
          )}
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            components={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
      </div>
    </>
  );
}

export default Cassette;
