import React, { useState, useEffect, useCallback } from "react";
import "../Reperti.scss";
import DataTableSubHeader from "../../../Components/DataTableSubHeader/DataTableSubHeader.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AddIcon,
  CrossIcon,
  EditIcon,
  RightArrowIcon,
} from "../../../assets/icons/index.js";
import { SubHeaderMobileOption } from "../../../Components/SubHeaderMobileOption/SubHeaderMobileOption.jsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getRaccolta,
  getReperto,
  updateReperto,
  saveRepertoImg,
  updateRepertoEsposizione,
} from "../../../api/index.js";
import { validateReperto } from "../../../helpers/formTools/validations/repertorio/index.js";
import { useAppContext } from "../../../Components/Context/AppContext.jsx";
import regex from "../../../helpers/formTools/regex/index.js";
import RepertiRecordDetailForm from "../../../Components/Reperti/EditReperto/RepertiRecordDetailForm/RepertiRecordDetailForm.jsx";
import userHasPermission from "../../../helpers/formTools/userHasPermission/index.js";
import Spinner from "../../../Components/ProgressBar/Spinner.js";

function EditReperto() {
  const { repertoId, setRepertiFilter, userProfile } = useAppContext();
  const [isMenuActive, setMenuActive] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState();
  const [issues, setIssues] = useState([]);
  const [showIssues, setShowIssues] = useState(false);
  const [immagini, setImmagini] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleFormDataChange = (key, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: value,
    }));
  };

  // ****************************************************************************************************
  // ALL API CALLS

  const fetchReperto = async (id) => {
    try {
      let data = await getReperto(id);
      data.provenienze = data.provenienze.map((provenienza) => ({
        ...provenienza,
        comuneId: provenienza.comune.id,
      }));
      setFormData({ ...data });
      setImmagini(data.immagini);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error("Unauthorized. Redirecting you to the login page...");
      } else {
        console.error(`Error fetching "reperto" record`, error);
      }
    }
  };

  const fetchRaccolta = async (id) => {
    try {
      let data = await getRaccolta(id);
      if (data)
        setFormData((prevData) => ({
          ...prevData,
          raccolta: {
            raccoltaId: data.id,
            cassettaId: data.cassettaId,
          },
        }));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate("/");
        console.error("Unauthorized. Redirecting you to the login page...");
      } else {
        console.error(`Error fetching "raccolta" record`, error);
      }
    }
  };

  const editReperto = async (data) => {
    try {
      await updateReperto(data);
      toast.success("Reperto aggiornato con successo!");
    } catch (error) {
      if (error.respomse && error.response.status === 401) {
        navigate("/");
        console.error(`Authorization error updating a "reperto" record`);
      } else {
        toast.error(
          error.response && error.response.detail
            ? error.response.detail
            : `Unknown error updating a "cassetta" record`
        );
        console.error(`Authorization error updating a "reperto" record`, error);
      }
    }
  };

  //* ****************************************************************************************************
  // ALL EFFECTS

  // FETCHINT THE SELECTED REPERTO FROM BE
  useEffect(() => {
    if (repertoId) fetchReperto(repertoId);
  }, [repertoId]);

  useEffect(() => {
    if (!formData) return;
    let issues = validateReperto(formData);
    setIssues(issues);
  }, [formData]);

  // ****************************************************************************************************
  // ALL HANDLERS

  const handleChangeImmagini = useCallback((val) => {
    setImmagini(val);
  }, []);

  const handleSaveImageRef = async () => {
    try {
      if (!immagini || immagini.length === 0) return;
      const submitData = immagini
        .filter(
          (img) =>
            !formData.immagini
              .map((item) => item.immagineId)
              .includes(img.immagineId)
        )
        .map((img) => ({
          immagineId: img.immagineId,
          repertoId: formData.id,
        }));

      console.log(
        "===| Saving immagini...",
        submitData,
        "--->>>",
        formData.immagini
      );

      await Promise.all(submitData.map((img) => saveRepertoImg(img)));
      toast.success("Immagini salvate con successo!");
    } catch (err) {
      console.log(err);
      toast.error("Errore nel salvataggio delle immagini");
    }
  };

  const handleSave = async () => {
    if (issues.length > 0) {
      setShowIssues(true);
      return;
    }
    setIsLoading(true);
    try {
      let reperto = {
        ...formData,
        groups: formData.groups.map((group) => group.name), // Convert groups to array of ids
        codiciInventario: formData.codiciInventario.map((c) => ({
          tipo: c.tipo,
          codice: c.codice,
        })),
        raccoltaId: formData.raccolta ? formData.raccolta.raccoltaId : null,
      };
      delete reperto.immagini;
      if (reperto.esposizione)
        await updateRepertoEsposizione(reperto.esposizione);
      await handleSaveImageRef();
      await editReperto(reperto);
      // console.log("===| Reperto to be saved: ", formData);
    } catch (err) {
      console.error("Error saving reperto", err);
      toast.error("Errore nel salvataggio del reperto");
    } finally {
      setRepertiFilter([]); // Reset the filter
      setIsLoading(false);
      navigate(-1);
    }
  };

  const handleRaccoltaChange = (newValue) => {
    if (regex.uuid.test(newValue)) {
      fetchRaccolta(newValue);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        raccolta: { cassettaId: "?", raccoltaId: newValue },
      }));
    }
  };

  // ****************************************************************************************************

  const disabled = pathname !== "/reperti/edit";
  const repertiRecordDetailsBreadcrumbs = [
    {
      id: 1,
      label: "Dati Archeologici",
      iconUrl: true,
    },
    {
      id: 2,
      label: "REPERTI",
      iconUrl: true,
    },
    { id: 3, label: "Dettaglio reperto", iconUrl: "" },
  ];
  const subHeaderRightNavOptions = {
    icons: disabled
      ? [
          {
            icon: <RightArrowIcon className="reverted-edit-icon" />,
            onClick: () => {
              navigate(-1);
            },
            label: "Indietro",
          },
          userHasPermission({
            userProfile,
            resource: "REPERTO",
            resourceGroups: formData?.groups,
            permission: "WRITE",
          }) && {
            icon: <EditIcon fillColor="#E15615" />,
            onClick: () => {
              navigate("/reperti/edit");
            },
            label: "Edita",
          },
        ]
      : [],

    components: disabled
      ? []
      : [
          <button onClick={() => navigate(-1)} className="cancel-button">
            <div className="login-icon">
              <CrossIcon />
            </div>
            <span className="login-text">Cancel</span>
          </button>,
          <button onClick={handleSave} className="save-button">
            <AddIcon fillColor="#fff" />
            <span className="login-text">Salva</span>
          </button>,
        ],
  };
  return (
    <>
      <div className="container">
        <main className="record-detail-main-content">
          <DataTableSubHeader
            title={"Dettagli reperto"}
            breadcrumbsData={repertiRecordDetailsBreadcrumbs}
            subHeaderRightNavOptions={subHeaderRightNavOptions}
            isMenuActive={isMenuActive}
            setMenuActive={() => setMenuActive(!isMenuActive)}
          />
          <RepertiRecordDetailForm
            data={formData}
            immagini={immagini}
            onChangeImmagini={handleChangeImmagini}
            setData={setFormData}
            onFieldChange={handleFormDataChange}
            onRaccoltaChange={handleRaccoltaChange}
            disabled={disabled}
            issues={issues}
            showIssues={showIssues}
          />
        </main>
        {isMenuActive && (
          <SubHeaderMobileOption
            buttons={subHeaderRightNavOptions?.components}
            icons={subHeaderRightNavOptions?.icons}
            onCrossClick={() => setMenuActive(false)}
            title="options"
          />
        )}
        {isLoading && <Spinner />}
      </div>
    </>
  );
}

export default EditReperto;
