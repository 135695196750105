import { AddIcon } from "../../../assets/icons";
import BlockHeader from "../../Common/BlockHeader/BlockHeader";
import CustomTooltip from "../../CustomTooltip";
import FormField from "../../FormField/FormField";
import UploadAllegato from "../UploadAllegato/UploadAllegato";
import ScaffaliSection from "../ScaffaliSection/ScaffaliSection";
import { CrossIcon } from "../../../assets/icons";
import "./PianiSection.scss";

const PianiSection = ({
  piani,
  disabled = false,
  handleAddPiano,
  onChange,
  handleAllegatoChange,
  handleAddScaffle,
  handleRemoveScaffale,
  handleUpdateScaffaleNome,
  handleRemovePiano,
  handleScaffaleAllegatoChange,
}) => {
  return (
    <div className="piani-section">
      <BlockHeader title="Piani">
        {!disabled && (
          <CustomTooltip text="Aggiungi Piano">
            <button className="repertori-button" onClick={handleAddPiano}>
              <AddIcon fillColor="#e15615" />
            </button>
          </CustomTooltip>
        )}
      </BlockHeader>
      {piani.map((piano, index) => (
        <div key={index} className="piani-container">
          {!disabled && (
            <button
              className="piano-remove-icon"
              onClick={() => handleRemovePiano(piano?.id)}
            >
              <CrossIcon width={20} height={20} />
            </button>
          )}
          <FormField
            fieldStructure={{
              label: "Nome",
              type: "text",
              name: "nome",
              editable: true,
            }}
            disabled={disabled}
            value={piano?.nome}
            onChange={(val) => onChange(val, "nome", piano?.id)}
          />
          <UploadAllegato
            id={piano?.id}
            disabled={disabled}
            allegato={piano?.allegato}
            onChange={handleAllegatoChange}
          />
          <ScaffaliSection
            pianoId={piano?.id}
            scaffali={piano?.scaffali}
            disabled={disabled}
            handleAddScaffle={handleAddScaffle}
            handleScaffaleAllegatoChange={handleScaffaleAllegatoChange}
            handleRemoveScaffale={handleRemoveScaffale}
            handleUpdateNome={handleUpdateScaffaleNome}
          />
        </div>
      ))}
    </div>
  );
};

export default PianiSection;
