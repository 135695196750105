import React from "react";
import FormField from "../../../FormField/FormField";

const MovimentazioneCollocazione = ({ repertoData, tipoValue }) => {
  return (
    <React.Fragment>
      <FormField
        fieldStructure={{
          label: "Tipo di Collocazione",
          type: "string",
          editable: false,
        }}
        value={tipoValue}
        onChange={() => {}}
        disabled={true}
      />
      <FormField
        fieldStructure={{
          label: "Identificativo Movimentazione",
          type: "string",
          editable: true,
        }}
        value={repertoData.movimentazione ? repertoData.movimentazione.id : "-"}
        onChange={() => {}}
        disabled={true}
      />
      <FormField
        fieldStructure={{
          label: "Destinazione",
          type: "string",
          editable: true,
        }}
        value={
          repertoData.movimentazione
            ? repertoData.movimentazione.destinazione
            : "-"
        }
        onChange={() => {}}
        disabled={true}
      />
      <FormField
        fieldStructure={{
          label: "Data uscita",
          type: "string",
          editable: true,
        }}
        value={
          repertoData.movimentazione
            ? new Date(repertoData.movimentazione.termine).toLocaleString()
            : "-"
        }
        onChange={() => {}}
        disabled={true}
      />
    </React.Fragment>
  );
};

export default MovimentazioneCollocazione;
