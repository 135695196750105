import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomModal from "../../Common/CustomModal";
import Select from "react-select";
import "./style.css";
import { useAppContext } from "../../Context/AppContext";
import { getComuneLayers } from "../../../api";
import { ExclamationTriangleIcon } from "../../../assets/icons";
import { inventoryCodes } from "../../../constants";
import { validateRepertorio } from "../../../helpers/formTools/validations/repertorio";

const RepertoriAddModal = ({ show, handleClose, handleAddRepertorio }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [issues, setIssues] = useState([]);

  /* ALL HANDLERS */

  const onSelectedType = (type) => {
    setSelectedType(type.label);
  };

  const onSelectedValue = (e) => {
    setSelectedValue(e.target.value);
  };
  /* TRANSFORMATIONS */

  const codiciOptions = inventoryCodes.map((item) => ({
    value: `${item.value}`,
    label: `${item.label}`,
  }));

  const onAddRepertorio = (e) => {
    e.preventDefault();
    const repertorio = {
      tipo: selectedType,
      codice: selectedValue,
    };
    const issues = validateRepertorio(repertorio);
    if (issues.length > 0) {
      setIssues(issues);
      return;
    }
    handleAddRepertorio(repertorio);
  };

  return (
    <CustomModal
      title={"AGGIUNGI REPERTORIO"}
      isVisible={show}
      onClose={handleClose}
    >
      <div className="issue-container">
        {issues.length > 0 &&
          issues.map((issue, index) => {
            return (
              <div key={index} className="issue">
                <p className="issue-icon">
                  <ExclamationTriangleIcon />{" "}
                </p>
                {issue}
              </div>
            );
          })}
      </div>
      <form onSubmit={onAddRepertorio}>
        <div className="add-user-modal-body">
          <div className="input-wrapper">
            <label>Tipo repetorio</label>
            <Select
              options={codiciOptions}
              required
              value={selectedType ? selectedType.tipo : ""}
              placeholder="Seleziona una opzione"
              onChange={onSelectedType}
            ></Select>
          </div>
          <div className="input-wrapper">
            <label htmlFor="value">Codice repertorio</label>
            <input
              type="string"
              name="value"
              value={selectedValue}
              id="value"
              placeholder=""
              required
              onChange={onSelectedValue}
            />
          </div>
        </div>
        <footer className="modal-footer">
          <button type="submit" className="modal-footer-button">
            Submit
          </button>
        </footer>
      </form>
    </CustomModal>
  );
};

export default RepertoriAddModal;
